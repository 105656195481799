import { useEffect, useState, useRef } from "react";
import PortfolioLayout from "../../../components/Layout/Portfolio";
import { FaLongArrowAltUp, FaDownload } from "react-icons/fa";
import { BsLink45Deg } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import Table from "react-bootstrap/Table";
import { ReactComponent as PiggybankIcon } from "../../../Assets/Images/piggybank.svg";
import { ReactComponent as DownloadIcon } from "../../../Assets/Images/download-reports.svg";
import { ReactComponent as DownArrow } from "../../../Assets/Images/down-arr-98.svg";
import FintooDropdown from "../../../components/HTML/FintooDropdown";
import Calender from "../../../Assets/Images/CommonDashboard/calendar-323.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchEncryptData,
  getUserId,
  indianRupeeFormat,
  loginRedirectGuest,
  getProfilePercentage,
  fetchUserData,
  getItemLocal,
  fetchData,
  getPublicMediaURL,
  getDownActivityStateFromLS,
  getParentFpLogId,
  apiCall,
} from "../../../common_utilities";
import {
  DMF_GETUSERDETAILS_API_URL,
  GET_PORTFOLIO_SUMMARY_URL,
  DMF_PAR_REPORT_URL,
  SUPPORT_EMAIL,
  IS_DIRECT,
} from "../../../constants";
import ExploreStock from "../../../components/HTML/ExploreStock";
import { useDispatch } from "react-redux";
import moment from "moment";
import * as constClass from "../../../constants";
import FintooLoader from "../../../components/FintooLoader";
import style from "./style.module.css";
import Swal from "sweetalert2";
import ProgressStats from "./ProgressStats/ProgressStats";
import ReactTooltip from "react-tooltip";
import MfFilterSidePanel from "./filters/MfFilterSidePanel";
import { HiSortAscending } from "react-icons/hi";
import StocksFilterSidePanel from "./filters/StocksFilterSidePanel";
import { getSumOfDataListProp } from "../../../Utils/ListDataUtils/DataListOperations";
import { openDialog } from "../CommonDashboard/CommonDashboardComponents/ConfirmationDialog/ConfirmHandler";
import Modal from "react-responsive-modal";
import { Modal as ReactModal } from "react-responsive-modal";
import SelectMemberModal from "../../../components/SelectMemberModal";
// import { Modal as ReactModal } from "react-responsive-modal";

const AssetName = ({ title, icon }) => {
  return (
    <div className={`d-flex ${style.flexBxAssetName}`}>
      <div className="pe-3">
        <img
          class={`d-none d-md-block ${style.tblIcons}`}
          src={
            icon ??
            process.env.REACT_APP_STATIC_URL_PYTHON +
              "/assets/img/insurance/insurance_insurance_form.svg"
          }
        />
      </div>
      <div>{title}</div>
    </div>
  );
};
const PortfolioDashboard = (props) => {
  const successMessage = [
    "Fd_bond data deleted successfully.",
    "Po data deleted successfully.",
    "Real_estate data deleted successfully.",
    "Insurance data deleted successfully.",
    "Liquid data deleted successfully.",
  ];
  const errorMessage = [
    "No active Fd_bond data found!",
    "No active Po data found!",
    "No active Real_estate data found!",
    "No active Liquid data found!",
    "No active Gold data found!",
    "Please Provide Valid inv_type",
  ];
  const repdata = {
    fileP: "",
  };
  const [returnsType, setReturnsType] = useState({
    header: "xirr",
    insideTable: "xirr",
  });
  const [selectedTab, setSelectedTab] = useState(1);
  const [mainData, setMainData] = useState([]);
  const [mfListDataCopy, setMfListDataCopy] = useState([]);
  const [text, setpopuptext] = useState("");
  const [Open, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const [resetFilterTriggerState, setResetFilterTriggerState] = useState({
    triggerReset: false,
    showResetTriggerUi: false,
    filtersActive: false,
  });
  const [stocksListCopy, setStocksListCopy] = useState([]);
  const [resetStocksFilterTriggerState, setResetStocksFilterTriggerState] =
    useState({
      triggerReset: false,
      showResetTriggerUi: false,
      filtersActive: false,
    });
  const [progressBarValues, setProgressBarValues] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(repdata);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useRef({});
  const [percent, setPercent] = useState(0);
  const btnDownloadRef = useRef();
  // const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState({
    dashboardData: true,
    mfData: true,
    otherInvestmentData: true,
  });
  const [otherInvestmentData, setOtherInvestmentData] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const [isFilterPanelActive, setIsFilterPanelActive] = useState(false);
  const [isStocksFilterPanelActive, setIsStocksFilterPanelActive] =
    useState(false);

  const [userExternalFundData, setUserExternalFundData] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const openModal1 = () => {
    setIsOpen1(true);
  };
  const closeModal1 = () => {
    setIsOpen1(false);
  };

  useEffect(() => {
    if (searchParams.get("realestate") == 1) {
      searchParams.delete("realestate");
      setSearchParams(searchParams);
      setSelectedTab(5);
    }
    if (searchParams.get("fdbonds") == 3) {
      searchParams.delete("fdbonds");
      setSearchParams(searchParams);
      setSelectedTab(3);
    }
    if (searchParams.get("liquidasset") == 1) {
      searchParams.delete("liquidasset");
      setSearchParams(searchParams);
      setSelectedTab(8);
    }
    if (searchParams.get("insurance") == 2) {
      searchParams.delete("insurance");
      setSearchParams(searchParams);
      setSelectedTab(2);
    }
    if (
      Boolean(Number(getDownActivityStateFromLS("maintenance")?.active) ?? 0)
    ) {
      Swal.fire(getDownActivityStateFromLS("maintenance")?.value);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("assetTabNumber")) {
      setSelectedTab(searchParams.get("assetTabNumber"));
    }
  }, [searchParams]);

  useEffect(() => {
    fetchFundsData();
    fetchInsuranceData();
    userProfileState();
    getDashboardData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isLoading]);

  const getDashboardData = async () => {
    // setIsDataLoading(true);
    setIsDataLoading((prev) => ({ ...prev, dashboardData: true }));
    let data = {};
    if (getItemLocal("family")) {
      var new_array_ids = [];
      var new_array_pans = [];
      var new_data = getItemLocal("member");
      new_data.forEach((element) => {
        if (element.id !== null) {
          new_array_ids.push(element.id.toString());
        }
        if (element.pan !== null) {
          new_array_pans.push(element.pan);
        }
      });
      data = { user_id: new_array_ids, pan: new_array_pans };
    } else {
      data = { user_id: getUserId() };
    }
    var payload = {
      url: constClass.DMF_GET_DASHBOARD_DATA,
      data: data,
      method: "post",
    };
    var res = await fetchEncryptData(payload);
    // setIsDataLoading(false);
    setIsDataLoading((prev) => ({ ...prev, dashboardData: false }));
    if (res.error_code == "100") {
      setDashboardData(res.data);
      GraphData(res.data);
    }
  };

  const deleteFdBond = async (fd_id, type, fplid, message) => {
    console.log("del: ", fd_id);
    // console.log(type,"sss")
    const result = await openDialog("Delete Confirmation", message);
    if (!result) return;

    try {
      let payload;
      let url;

      if (type == "insurance") {
        payload = {
          url: constClass.ADVISORY_REMOVE_INSURANCE_DATA,
          data: {
            user_id: getUserId(),
            fp_log_id: getParentFpLogId(),
            insurance_id: fd_id,
          },
          method: "post",
        };
      } else {
        payload = {
          url: constClass.ADVISORY_DELETE_ASSETS_API,
          data: {
            user_id: getUserId(),
            id: [fd_id],
            fp_log_id: getParentFpLogId(),
          },
          method: "post",
        };
      }

      // Now you can use the payload object as needed

      let deleteassetData = await fetchEncryptData(payload);
      if (deleteassetData["error_code"] == "100") {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Data deleted successfully!",
            type: "success",
          },
        });
        fetchInsuranceData();
        getDashboardData();
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Error!", type: "error" },
        });
      }
    } catch (err) {
      console.log(err, "----->");
    }
  };

  const deleteAsset = async (inv_id, type, fplid, message) => {
    const result = await openDialog("Delete Confirmation", message);
    if (!result) return;

    try {
      setIsLoading(true);
      if (type == "insurance") {
        let payload = {
          url: constClass.ADVISORY_REMOVE_INSURANCE_DATA,
          data: {
            user_id: getUserId(),
            fp_log_id: getParentFpLogId(),
            insurance_id: inv_id,
          },
          method: "post",
        };
        let res = await fetchEncryptData(payload);
        if (res.error_code == "100") {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Data deleted successfully.", type: "success" },
          });
          fetchInsuranceData();
          getDashboardData();
          return;
        }
      }
      let payload = {
        url: constClass.DELETE_OTHER_INVESTMENTS,
        data: {
          user_id: getUserId(),
          inv_type: type,
          inv_id: [inv_id],
          is_direct: IS_DIRECT,
        },
        method: "post",
      };
      let res = await fetchEncryptData(payload);
      setIsLoading(false);
      if (successMessage.indexOf(res.message) > -1) {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: res.message, type: "success" },
        });
        fetchInsuranceData();
        getDashboardData();
        return;
      } else if (errorMessage.indexOf(res.message) > -1) {
        setIsLoading(true);
        let payload1 = {
          url: constClass.DELETE_FP_USER_ASSET,
          data: {
            user_id: getUserId(),
            id: [inv_id],
            fp_log_id: getParentFpLogId(),
          },
          method: "post",
        };
        console.log("delete data ", payload1);
        let res2 = await fetchEncryptData(payload1);
        setIsLoading(false);
        if (res2.error_code == "100") {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Data deleted successfully!",
              type: "success",
            },
          });
          fetchInsuranceData();
          getDashboardData();
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Error!", type: "error" },
          });
        }
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Something went wrong. Try again later.",
            type: "error",
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // returns an array of all member's ids or pans depending on the value of typeOfArray
  const familyArray = (typeOfArray) => {
    let new_array = [];
    var new_data = getItemLocal("member");
    switch (typeOfArray) {
      case "pan":
        new_data.forEach((element) => {
          if (element.pan !== null) {
            new_array.push(element.pan);
          }
        });
        break;
      case "user_id":
        new_data.forEach((element) => {
          if (element.id !== null) {
            new_array.push(element.id.toString());
          }
        });
        break;
    }
    return new_array;
  };

  const fetchInsuranceData = async () => {
    try {
      let new_array = [];
      if (getItemLocal("family")) {
        new_array = familyArray("user_id");
      }
      setIsLoading(true);
      // setIsDataLoading(true);
      setIsDataLoading((prev) => ({ ...prev, otherInvestmentData: true }));
      let payload_data = getItemLocal("family")
        ? {
            user_id: new_array,
            family: "1",
            inv_type: "all",
            is_direct: IS_DIRECT,
          }
        : {
            user_id: getUserId(),
            fp_user_details_id: getItemLocal("member").find(
              (u) => u.id == getUserId() * 1
            ).fp_user_details_id,
            inv_type: "all",
            is_direct: IS_DIRECT,
          };

      let payload = {
        url: constClass.GET_OTHER_INVESTMENTS,
        data: payload_data,
        method: "post",
      };
      let res = await fetchEncryptData(payload);

      setIsLoading(false);
      if (res.error_code == "100") {
        setIsDataLoading((prev) => ({ ...prev, otherInvestmentData: false }));
        setOtherInvestmentData(res.data);
        setStocksListCopy(res.data.stocks_data.stocks_details);
      } else {
        setOtherInvestmentData([]);
        setStocksListCopy([]);
        console.error(e);
      }
    } catch (e) {
      // setIsDataLoading(false);
      setIsDataLoading((prev) => ({ ...prev, otherInvestmentData: false }));
      setIsLoading(false);
      console.error(e);
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(value);

  const fetchFundsData = async () => {
    try {
      let new_array = [];
      if (getItemLocal("family")) {
        new_array = familyArray("pan");
      }
      if (getUserId() == null) {
        loginRedirectGuest();
        return;
      }
      var payload = {
        url: DMF_GETUSERDETAILS_API_URL,
        method: "post",
        data: { user_id: "" + getUserId(), is_direct: IS_DIRECT },
      };

      var res = await fetchEncryptData(payload);
      if (Boolean(res.data.pan) == false) {
        throw "PAN not found";
      }
      userDetails.current = res.data;
      var payload = {
        url: GET_PORTFOLIO_SUMMARY_URL,
        data: {
          // pan: "AUFPV0822N",
          pan: getItemLocal("family") ? new_array : res.data.pan,
          is_direct: IS_DIRECT,
        },
        method: "post",
      };
      setIsDataLoading((prev) => ({ ...prev, mfData: true }));
      var res = await fetchEncryptData(payload);
      if (res.error_code == "100") {
        setMainData(res.data);
        setMfListDataCopy(res.data.fund_details);
        setIsDataLoading((prev) => ({ ...prev, mfData: false }));
      } else {
        setIsDataLoading((prev) => ({ ...prev, mfData: false }));
      }
    } catch (e) {
      setIsDataLoading((prev) => ({ ...prev, mfData: false }));
      console.error(e);
    }
  };

  const GraphData = (data) => {
    let graph_data = [];

    Object.keys(data?.investment?.inv_data).forEach((key) => {
      const percValue = Math.round(
        (data?.investment?.inv_data[key]?.perc * 100) / 100
      );
      graph_data.push({
        title: key,
        value: percValue,
        color: "",
      });
    });
    setProgressBarValues(graph_data);
  };

  const userProfileState = async () => {
    // setIsLoading1(true);
    try {
      const userdata = await fetchUserData(true);
      const p = await getProfilePercentage(userdata);
      setPercent(p);
    } catch (e) {
      // console.log("natu err");
      // console.error("natu err", e);
    }
  };

  useEffect(() => {
    if (file.fileP) {
      document.querySelector("#pa-download").click();
      // setFileP("");
    }
  }, [file.fileP]);

  const parApi = async () => {
    const parRep = {
      user_id: "" + getUserId(),
      pan: userDetails.current.pan,
    };
    var payload_par = {
      url: DMF_PAR_REPORT_URL,
      method: "POST",
      data: parRep,
      headers: {
        gatewayauthtoken:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJnYXRld2F5bmFtZSI6Imh0dHBzOi8vc3RnLm1pbnR5LmNvLmluLyIsImV4cCI6MTY4ODM4MjU2OX0.x2_gelbtpUBq6sSVajq-nhBwM7COXgnuaPir-IQyIRM",
        "Content-Type": "text/plain",
      },
    };
    var resp = await fetchEncryptData(payload_par);
    // setParApiresp(resp)
    return resp;
  };

  const parData = async () => {
    try {
      if (getUserId() == null) {
        loginRedirectGuest();
        return;
      }
      if (file.fileP) {
        document.querySelector("#pa-download").click();
        return;
      }
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Please Wait...",
          type: "info",
        },
      });
      setIsLoading(true);
      btnDownloadRef.current.setAttribute("disabled", true);
      let resp = await parApi();
      setIsLoading(false);
      const response = await fetch(resp.pdf_report_link);
      const blob = await response.blob();
      // setFile.fileP(URL.createObjectURL(blob));
      setFile((prev) => ({ ...prev, fileP: URL.createObjectURL(blob) }));
      btnDownloadRef.current.removeAttribute("disabled");
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMailPar = async () => {
    setIsLoading(true);
    let resp = await parApi();
    if (resp.pdf_report_link) {
      var urlmail = {
        userdata: {
          to: userDetails.current.email,
        },
        subject: "Fintoo - Par Report",
        template: "transactions_dmf.html",
        contextvar: {
          name: userDetails.current.name,
          SUPPORT_EMAIL: SUPPORT_EMAIL,
          report_link: resp.pdf_report_link,
        },
      };

      // var data = commonEncode.encrypt(JSON.stringify(urlmail));
      let config = {
        method: "post",
        url: constClass.DMF_SENDMAIL_API_URL,
        data: urlmail,
      };

      var res = await fetchEncryptData(config);
      setIsLoading(false);
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: res.message, type: "success" },
      });
    } else {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Email not sent, Something went wrong...",
          type: "error",
        },
      });
    }
  };

  const handlesort = (v) => {
    var myarray = mainData.fund_details;
    var numDescending;
    var data;
    switch (v) {
      case "Current Value":
        numDescending = myarray.sort((a, b) => b.curr_val - a.curr_val);
        data = { ...mainData, fund_details: numDescending };
        setMainData(data);
        break;
      case "Invested Amount":
        numDescending = myarray.sort((a, b) => b.inv - a.inv);
        data = { ...mainData, fund_details: numDescending };
        setMainData(data);
        break;
      case "Gain Percentage":
        numDescending = myarray.sort(
          (a, b) => b.xirr_percentage - a.xirr_percentage
        );
        data = { ...mainData, fund_details: numDescending };
        setMainData(data);
        break;
      case "Gain Value":
        numDescending = myarray.sort((a, b) => b.gain_loss - a.gain_loss);
        data = { ...mainData, fund_details: numDescending };
        setMainData(data);
        break;
    }
  };

  const stockSort = (v) => {
    var myarray = otherInvestmentData.stocks_data.stocks_details;
    var numDescending;
    switch (v) {
      case "Current Value":
        numDescending = [...myarray].sort((a, b) => b.cr_val - a.cr_val);
        var stocksData = {
          ...otherInvestmentData.stocks_data,
          stocks_details: numDescending,
        };
        var updatedOtherInvestmentData = {
          ...otherInvestmentData,
          stocks_data: stocksData,
        };
        setOtherInvestmentData(updatedOtherInvestmentData);
        break;
      case "Invested Amount":
        numDescending = [...myarray].sort((a, b) => b.inv_val - a.inv_val);
        var stocksData = {
          ...otherInvestmentData.stocks_data,
          stocks_details: numDescending,
        };
        var updatedOtherInvestmentData = {
          ...otherInvestmentData,
          stocks_data: stocksData,
        };
        setOtherInvestmentData(updatedOtherInvestmentData);
        break;
      case "Today Return":
        numDescending = [...myarray].sort((a, b) => b.today_rtn - a.today_rtn);
        var stocksData = {
          ...otherInvestmentData.stocks_data,
          stocks_details: numDescending,
        };
        var updatedOtherInvestmentData = {
          ...otherInvestmentData,
          stocks_data: stocksData,
        };
        setOtherInvestmentData(updatedOtherInvestmentData);
        break;
      case "Total Return":
        numDescending = [...myarray].sort((a, b) => b.gain_val - a.gain_val);
        var stocksData = {
          ...otherInvestmentData.stocks_data,
          stocks_details: numDescending,
        };
        var updatedOtherInvestmentData = {
          ...otherInvestmentData,
          stocks_data: stocksData,
        };
        setOtherInvestmentData(updatedOtherInvestmentData);
        break;
    }
  };

  const detailsPage = (v) => {
    var detailsData = JSON.stringify({
      pan: v.pan,
      is_direct: IS_DIRECT,
      fund_registrar: v.fund_registrar,
      prod_code: v.prod_code,
      folio_no: v.folio_no,
      amc_code: v.amc_code,
    });

    var name_user = v.name.toString();

    localStorage.setItem("detailsData", detailsData);
    // added for temporary name change

    dispatch({ type: "SET_TEMP_NAME", payload: name_user });

    navigate(
      process.env.PUBLIC_URL +
        "/" +
        process.env.REACT_APP_FOLDER_NAME +
        "/portfolio/dashboard/fund"
    );
  };

  const checkIfPanExists = async () => {
    try {
      var reqData = {
        method: "post",
        url: constClass.DMF_CHECKIFPANEXISTS_API_URL,
        data: {
          pan: userDetails.current.pan,
          fp_user_id: getUserId(),
        },
      };
      let checkPan = await fetchData(reqData);
      if (checkPan.error_code == "100") {
        navigate(
          process.env.PUBLIC_URL +
            "/" +
            process.env.REACT_APP_FOLDER_NAME +
            "/portfolio/link-your-holdings"
        );
      } else if (checkPan.error_code == "101") {
        setpopuptext(
          "Please complete your profile to access all features and enjoy a tailored experience"
        );
        openModal();
        // dispatch({
        //   type: "RENDER_TOAST",
        //   payload: { message: checkPan.message, type: "error" },
        // });
      } else if (checkPan.error_code == "102") {
        setpopuptext(
          "Please complete your profile to access all features and enjoy a tailored experience"
        );
        openModal();
      }
    } catch (e) {
      console.error(e);
    }
  };

  function getFrequencyName(frequency) {
    switch (frequency) {
      case 1:
        return "Monthly";
      case 2:
        return "Quarterly";
      case 3:
        return "Half Yearly";
      case 4:
        return "Yearly";
      default:
        return "-";
    }
  }
  const handleSubmit = () => {
    navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/profile/");
    // if (status === "N" && profilepercent == 100) {
    //   console.log("!=100")
    //   navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/profile/dashboard/nominee")
    // }
  };

  function insuraceType(type) {
    switch (type) {
      case 45:
        return "Endowment";
      case 47:
        return "General Insurance";
      case 46:
        return "Guaranteed Income Plan";
      case 48:
        return "Mediclaim";
      case 49:
        return "Pension Plan";
      case 43:
        return "Term Plan";
      case 44:
        return "ULIP";
      case 50:
        return "Others";
      default:
        return "-";
    }
  }

  const checkHoldingStatus = async () => {
    try {
      const payload = {
        method: "post",
        url: constClass.RMF_GET_SC_CHECK_STATUS,
        data: {
          pan: userDetails.current.pan,
          is_direct: constClass.IS_DIRECT,
        },
      };
      const res = await fetchData(payload);
      console.log("res---->", res);
      if (res.error_code == 100) {
        setUserExternalFundData(res.data[0] ?? {});
      } else {
        throw "";
      }
    } catch (e) {
      console.error("res---->", e);
    }
  };
  useEffect(() => {
    if (userDetails?.current?.pan) {
      checkHoldingStatus();
    }
  }, [userDetails?.current?.pan]);

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  return (
    <PortfolioLayout>
      <ReactModal
        classNames={{
          modal: "ModalpopupContentWidth",
        }}
        open={isOpen1}
        showCloseIcon={false}
        center
        animationDuration={0}
        closeOnOverlayClick={false}
        large
      >
        <div className="text-center">
          <h3 className="HeaderText">Attention !</h3>
          <div className="">
            <div
              className="PopupImg"
              style={{ width: "40%", margin: "0 auto" }}
            >
              <img
                style={{ width: "100%" }}
                src={
                  process.env.PUBLIC_URL + "/static/media/DMF/SelectingTeam.svg"
                }
              />
            </div>
            <div className="p-2">
              <p
                className="PopupContent"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "normal",
                  padding: "0 1rem",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                Please select member from the dropdown to proceed.
              </p>
            </div>
            <div
              className="ButtonBx aadharPopUpFooter"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="ReNew"
                onClick={() => {
                  closeModal1();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <Modal
        classNames={{
          modal: "ModalpopupContentWidth",
        }}
        open={Open}
        showCloseIcon={false}
        center
        animationDuration={0}
        closeOnOverlayClick={false}
        large
      >
        <div className="text-center">
          <h3 className="HeaderText">Attention !</h3>
          <div className="">
            <div
              className="PopupImg"
              style={{ width: "40%", margin: "0 auto" }}
            >
              <img
                style={{ width: "100%" }}
                src={
                  process.env.PUBLIC_URL + "/static/media/DMF/SelectingTeam.svg"
                }
              />
            </div>
            <div className="p-2">
              <p
                className="PopupContent"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "normal",
                  padding: "0 1rem",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                {text}
              </p>
            </div>
            <div
              className="ButtonBx aadharPopUpFooter"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="ReNew"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <FintooLoader isLoading={isLoading} />
      {!isLoading && (
        <>
          <>
            {/* <p style={{ height: "2rem" }}></p> */}
            <div className="row">
              <div className="col-12">
                <div className="mybox mt-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-none d-md-flex justify-content-between">
                        <div></div>
                        <div className="topOptions">
                          {/* {percent === 100 ? (
                            <>
                              <div className="topOptionsButton hover-dropdown">
                                <DownloadIcon width={"17px"} height={"17px"} />
                                <span>Download Report</span>
                                <div className="download-report-box hover-dropdown-box">
                                  <div className="download-report-bx2 hover-dropdown-content">
                                    <div className="download-report-1">
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <div className="drp-1">
                                                Par Report
                                              </div>
                                            </td>
                                            <td>
                                              <div className="drp-2 d-flex">
                                                <button onClick={fetchMailPar}>
                                                  <MdOutlineEmail />
                                                </button>
                                                <button
                                                  type="button"
                                                  ref={btnDownloadRef}
                                                  onClick={parData}
                                                >
                                                  <FaDownload />
                                                </button>
                                                <div
                                                  style={{ display: "none" }}
                                                >
                                                  <a
                                                    id="pa-download"
                                                    href={file.fileP}
                                                    style={{
                                                      textDecoration: "none",
                                                    }}
                                                    download={
                                                      "Par_Report_" +
                                                      getUserId()
                                                    }
                                                  ></a>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                            </>
                          ) : (
                            <></>
                          )} */}

                          {/* {getItemLocal("family") ? (
                            <div className="topOptionsButton pointer disabled">
                              <PiggybankIcon width={"17px"} height={"17px"} />
                              <span>Add Funds</span>
                            </div>
                          ) : ( */}
                          <div
                            className="topOptionsButton pointer"
                            onClick={() =>
                              navigate(
                                process.env.PUBLIC_URL +
                                  "/" +
                                  process.env.REACT_APP_FOLDER_NAME +
                                  "/funds/all"
                              )
                            }
                          >
                            <PiggybankIcon width={"17px"} height={"17px"} />
                            <span>Add Funds</span>
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="myboxInner">
                        {/* {isDataLoading == true && ( */}
                        {/* {!progressBarValues && !dashboardData?.investment && (
                          <div className="row">
                            <div
                              className="shine"
                              style={{ width: "100%", height: "2rem" }}
                            ></div>
                          </div>
                        )} */}
                        {/* {isDataLoading == false && ( */}
                        {!isDataLoading.dashboardData ? (
                          <div className={`${style.statsContainer} row`}>
                            <div className={`${style.portfolioValue} col-4`}>
                              <p className={`smallCalendar mb-0 pt-1`}></p>
                              <p className="mb-0 pb-2">
                                <strong>Your Portfolio Value</strong>
                              </p>
                              <div className="valueBox">
                                <p className="valueBoxPortolio">
                                  <>
                                    <span className="valueBoxSymbol">₹</span>
                                    <span className="valueBoxAmount">
                                      {dashboardData?.investment &&
                                      "t_curr_val" in dashboardData.investment
                                        ? numberFormat(
                                            dashboardData.investment
                                              .t_curr_val * 1
                                          )
                                        : 0}
                                    </span>
                                  </>
                                </p>
                              </div>
                              {/* <div className="PotfolioDate">
                                <span>
                                  {"cur_nav_date" in mainData ? (
                                    <span >
                                      <strong>As on  {moment(mainData.cur_nav_date).format(
                                        "DD-MM-YYYY"
                                      )}</strong>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </div> */}
                            </div>
                            <div className={`${style.statsData} col-8`}>
                              <ProgressStats
                                selectedTab={selectedTab}
                                data={progressBarValues}
                                onSelect={(_number) => {
                                  setSelectedTab(_number);
                                  setSearchParams({
                                    ["assetTabNumber"]: _number,
                                  });
                                  console.log("nil77", _number);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div
                              className="shine"
                              style={{ width: "100%", height: "2rem" }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p style={{ height: "1rem" }}></p>
            <div className="row">
              <div className="col-12">
                <div className="col-12">
                  <div
                    className={`insideTabContent px-2 px-md-4 ${
                      selectedTab == 1 ? "" : "d-none"
                    }`}
                  >
                    {isDataLoading.mfData ? (
                      <Table
                        responsive
                        className={`ptTable fixedTable ${style.mfTable} mb-0 ${style.dataTable}`}
                      >
                        <tbody>
                          <tr>
                            <td className="pt-1">
                              <div className="shine"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-1">
                              <div className="shine"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-1">
                              <div className="shine"></div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    ) : (
                      <div>
                        {Boolean(
                          "fund_details" in mainData &&
                            mainData.fund_details.length
                        ) && (
                          <div className="row pb-3">
                            <div className="col-12 col-md-8">
                              <div className="d-none d-md-block">
                                <div className="cntResults">
                                  <div className="cntRItems">
                                    <div className="borderSpace">
                                      Total Funds
                                    </div>
                                    <div
                                      className={`borderSpace borderText pointer`}
                                    >
                                      {/* {"tfunds" in mainData && mainData.tfunds}{" "} */}
                                      {"tfunds" in mainData &&
                                        mainData.fund_details.length}{" "}
                                      {/* <span className="totalFundsTxt">
                                  SIP&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span>
                                    {"active_sips" in mainData &&
                                      mainData.active_sips}
                                  </span>
                                </span> */}
                                    </div>
                                  </div>
                                  <div className="cntRItems">
                                    <div className="borderSpace">Invested</div>
                                    <div className={`borderSpace borderText`}>
                                      {"tinvested_value" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(
                                            mainData.tinvested_value
                                          )
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "inv"
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div className="cntRItems">
                                    <div className="borderSpace">
                                      Total Fund Value
                                    </div>
                                    <div className={`borderSpace borderText`}>
                                      {"tcurr_value" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(
                                            mainData.tcurr_value
                                          )
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "curr_val"
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div className="cntRItems">
                                    <div className="borderSpace">Returns</div>
                                    <div className={`borderSpace borderText`}>
                                      {"tgain_loss" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(mainData.tgain_loss)
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "gain_loss"
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div
                                    className="cntRItems pointer"
                                    onClick={() =>
                                      setReturnsType((prev) => ({
                                        ...prev,
                                        header:
                                          prev.header == "xirr"
                                            ? "absolute"
                                            : "xirr",
                                      }))
                                    }
                                  >
                                    <div className="borderSpace triangle-ct">
                                      {returnsType.header == "xirr"
                                        ? "XIRR"
                                        : "Absolute"}
                                      &nbsp;%
                                    </div>
                                    {"tabs_return_percentage" in mainData &&
                                      "txirr_percentage" in mainData && (
                                        <div
                                          className={`borderSpace borderText `}
                                        >
                                          {returnsType.header == "xirr" && (
                                            <p
                                              className={`valueBoxPercentage ${
                                                mainData.txirr_percentage * 1 <
                                                0
                                                  ? "red"
                                                  : "green"
                                              }`}
                                            >
                                              <span>
                                                {mainData.txirr_percentage > 0
                                                  ? +mainData.txirr_percentage
                                                  : mainData.txirr_percentage}
                                                %
                                              </span>
                                              <FaLongArrowAltUp />
                                            </p>
                                          )}
                                          {returnsType.header == "absolute" && (
                                            <p
                                              className={`valueBoxPercentage ${
                                                mainData.tabs_return_percentage *
                                                  1 <
                                                0
                                                  ? "red"
                                                  : "green"
                                              }`}
                                            >
                                              <span>
                                                {mainData.tabs_return_percentage >
                                                0
                                                  ? +mainData.tabs_return_percentage
                                                  : mainData.tabs_return_percentage}
                                                %
                                              </span>
                                              <FaLongArrowAltUp />
                                            </p>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-block d-md-none mobile-portfolio-view">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-6 py-2">
                                    <div>Total Funds</div>
                                    <div className="text-bold">
                                      {mainData?.fund_details.length ?? ""}
                                    </div>
                                  </div>
                                  <div className="col-6 py-2">
                                    <div>Total Fund Value</div>
                                    <div className="text-bold">
                                      {"tcurr_value" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(
                                            mainData.tcurr_value
                                          )
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "curr_val"
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div className="col-6 py-2">
                                    <div>Invested</div>
                                    <div className="text-bold">
                                      {"tinvested_value" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(
                                            mainData.tinvested_value
                                          )
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "inv"
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <div className="col-6 py-2">
                                    <div>Returns</div>
                                    <div className="text-bold">
                                      {"tgain_loss" in mainData &&
                                      !resetFilterTriggerState.filtersActive
                                        ? indianRupeeFormat(mainData.tgain_loss)
                                        : indianRupeeFormat(
                                            getSumOfDataListProp(
                                              mainData.fund_details,
                                              "gain_loss"
                                            )
                                          )}
                                    </div>
                                  </div>

                                  <div
                                    className="col-6 py-2"
                                    onClick={() =>
                                      setReturnsType((prev) => ({
                                        ...prev,
                                        header:
                                          prev.header == "xirr"
                                            ? "absolute"
                                            : "xirr",
                                      }))
                                    }
                                  >
                                    <div className="borderSpace align-items-center d-flex">
                                      <div className="pe-2 returns-txt">
                                        {returnsType.header == "xirr"
                                          ? "XIRR"
                                          : "Absolute"}
                                        &nbsp;%
                                      </div>
                                      <DownArrow
                                        width={"12px"}
                                        height={"12px"}
                                      />
                                    </div>
                                    <div className={`borderSpace borderText`}>
                                      {returnsType.header == "xirr" && (
                                        <p
                                          className={`valueBoxPercentage ${
                                            mainData.txirr_percentage * 1 < 0
                                              ? "red"
                                              : "green"
                                          }`}
                                        >
                                          <span>
                                            {mainData.txirr_percentage > 0
                                              ? +mainData.txirr_percentage
                                              : mainData.txirr_percentage}
                                            %
                                          </span>
                                          <FaLongArrowAltUp />
                                        </p>
                                      )}
                                      {returnsType.header == "absolute" && (
                                        <p
                                          className={`valueBoxPercentage ${
                                            mainData.tabs_return_percentage *
                                              1 <
                                            0
                                              ? "red"
                                              : "green"
                                          }`}
                                        >
                                          <span>
                                            {mainData.tabs_return_percentage > 0
                                              ? +mainData.tabs_return_percentage
                                              : mainData.tabs_return_percentage}
                                            %
                                          </span>
                                          <FaLongArrowAltUp />
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-4 ">
                              <div className="pt-4">
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <div className=" ">
                                      <div className="resultOptions">
                                        <div>
                                          <div
                                            className={`${
                                              getItemLocal("family")
                                                ? "enable"
                                                : ""
                                            } resultOptionsBtn position-relative hover-dropdown pointer`}
                                            onClick={() => {
                                              navigate(
                                                process.env.PUBLIC_URL +
                                                  "/direct-mutual-fund/portfolio/link-your-holdings"
                                              );
                                            }}
                                          >
                                            {userExternalFundData?.Updated_Datetime ? (
                                              <span>Refresh</span>
                                            ) : (
                                              <span>Link your holdings</span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="small-para mb-0 pt-2">
                                      {Boolean(
                                        userExternalFundData?.Updated_Datetime
                                      ) &&
                                        "Last Updated on " +
                                          moment(
                                            userExternalFundData.Updated_Datetime
                                          ).format("DD-MM-YYYY")}
                                    </p>
                                  </div>

                                  <div className=" ">
                                    <div className="resultOptions">
                                      <div>
                                        <div
                                          className="resultOptionsBtn position-relative hover-dropdown pointer"
                                          onClick={() =>
                                            setIsFilterPanelActive(
                                              (prev) => !prev
                                            )
                                          }
                                        >
                                          <HiSortAscending
                                            fontSize={"1.2rem"}
                                          />
                                          <span>Sort & Filter</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="fixedHeaders">
                          <div className="table-responsive">
                            <div className="ptTableBx">
                              {Boolean(
                                "fund_details" in mainData &&
                                  mainData.fund_details.length
                              ) && (
                                <Table
                                  className={`ptTable ${style.mfTable} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                >
                                  <thead>
                                    <tr>
                                      <td scope="col">Funds</td>
                                      <td scope="col">Invested</td>
                                      <td scope="col">Current Value</td>
                                      <td scope="col">Units</td>
                                      <td scope="col">Gain | Loss</td>
                                      <td scope="col">
                                        <div
                                          className="d-flex align-items-center pointer"
                                          onClick={() =>
                                            setReturnsType((prev) => ({
                                              ...prev,
                                              insideTable:
                                                prev.insideTable == "xirr"
                                                  ? "absolute"
                                                  : "xirr",
                                            }))
                                          }
                                        >
                                          <span className="pe-2">
                                            {returnsType.insideTable == "xirr"
                                              ? "XIRR"
                                              : "Absolute"}{" "}
                                            %
                                          </span>
                                          <DownArrow
                                            width={"12px"}
                                            height={"12px"}
                                          />
                                        </div>
                                      </td>
                                      <td scope="col"></td>
                                    </tr>
                                  </thead>
                                </Table>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="  ">
                          <Table
                            responsive
                            className={`ptTable fixedTable ${style.mfTable} mb-0 ${style.dataTable}`}
                          >
                            <tbody>
                              {"fund_details" in mainData &&
                                mainData.fund_details.map((v, index) => (
                                  <tr key={index}>
                                    <td
                                      scope="row"
                                      className="fundNameTd"
                                      data-label="Funds"
                                    >
                                      <div className="fundName9">
                                        <div>
                                          <img
                                            src={getPublicMediaURL(
                                              `/static/media/companyicons/${v.amc_code}.png`
                                            )}
                                            onError={(e) => {
                                              e.target.src = `${process.env.REACT_APP_STATIC_URL}/media/companyicons/amc_icon.png`;
                                              e.onError = null;
                                            }}
                                          />
                                        </div>

                                        <div className="fundNameCon">
                                          <div
                                            className="fnc-yy"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <strong
                                              onClick={() => detailsPage(v)}
                                            >
                                              {v.scheme}
                                            </strong>
                                          </div>
                                          <div className="d-flex fn-inner-summary pt-1">
                                            {/* {v.fund_registrar != "ecas" ? (
                                          Boolean(v.inv_type == "SIP" && v.sip_status == "active") ? (
                                            <div className="d-flex"><span title="Active SIP" className={style['fund-tick-active']}></span>{v.inv_type}</div>
                                          ) : (
                                            <div className="d-flex"><span title="Inactive SIP" className={style['fund-tick-inactive']}></span>{v.inv_type}</div>
                                          )
                                        ) : (
                                        <div className="d-flex" title="Invested on other platform">External</div>
                                        )} */}

                                            {v.fund_registrar != "ecas" ? (
                                              v.inv_type == "SIP" ? (
                                                v.sip_status == "active" ? (
                                                  <div className="d-flex">
                                                    <span
                                                      title="Active SIP"
                                                      className={
                                                        style[
                                                          "fund-tick-active"
                                                        ]
                                                      }
                                                    ></span>
                                                    {v.inv_type}
                                                  </div>
                                                ) : (
                                                  <div className="d-flex">
                                                    <span
                                                      title="Inactive SIP"
                                                      className={
                                                        style[
                                                          "fund-tick-inactive"
                                                        ]
                                                      }
                                                    ></span>
                                                    {v.inv_type}
                                                  </div>
                                                )
                                              ) : (
                                                <span>{v.inv_type}</span>
                                              )
                                            ) : (
                                              <div
                                                className="d-flex"
                                                title="Invested on other platform"
                                              >
                                                External
                                              </div>
                                            )}
                                            <div>Folio No.: {v.folio_no}</div>
                                            <div>NAV: {v.curr_nav}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      scope="row"
                                      data-label="Invested"
                                      className=""
                                    >
                                      {" "}
                                      <div>
                                        <strong>
                                          {indianRupeeFormat(v.inv)}
                                        </strong>
                                      </div>{" "}
                                    </td>
                                    <td
                                      scope="row"
                                      data-label="Current Value"
                                      className=""
                                    >
                                      <div>
                                        <strong>
                                          {" "}
                                          {indianRupeeFormat(v.curr_val)}
                                        </strong>
                                      </div>
                                    </td>
                                    <td
                                      scope="row"
                                      data-label="Units"
                                      className=""
                                    >
                                      <div>
                                        <strong>{v.units}</strong>
                                      </div>
                                    </td>
                                    <td
                                      scope="row"
                                      data-label="Gain | Loss"
                                      className=""
                                    >
                                      <div>
                                        <strong
                                          className={`xrr-returns ${
                                            v.gain_loss * 1 < 0
                                              ? "red"
                                              : "green"
                                          }`}
                                        >
                                          {indianRupeeFormat(v.gain_loss)}
                                        </strong>
                                      </div>
                                    </td>
                                    <td
                                      scope="row"
                                      data-label="XIRR %"
                                      className=""
                                    >
                                      <div>
                                        <strong
                                          className={`xrr-returns ${
                                            v.xirr_percentage < 0
                                              ? "red"
                                              : "green"
                                          }`}
                                        >
                                          {returnsType.insideTable == "xirr"
                                            ? v.xirr_percentage != "Error"
                                              ? v.xirr_percentage
                                              : 0
                                            : v.abs_return_percentage}
                                        </strong>
                                      </div>
                                    </td>
                                    <td className="">
                                      <p
                                        onClick={() => detailsPage(v)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <ExploreStock />
                                      </p>
                                    </td>
                                  </tr>
                                ))}
                              {Boolean(
                                "fund_details" in mainData &&
                                  mainData.fund_details.length
                              ) === false && (
                                <tr>
                                  <div className="d-flex justify-content-end">
                                    <div style={{ width: "12rem" }}>
                                      {resetFilterTriggerState.filtersActive && (
                                        <div>
                                          <div
                                            className="resultOptionsBtn position-relative hover-dropdown pointer"
                                            onClick={() =>
                                              setIsFilterPanelActive(
                                                (prev) => !prev
                                              )
                                            }
                                          >
                                            <HiSortAscending
                                              fontSize={"1.2rem"}
                                            />
                                            <span>Sort & Filter</span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-50 m-auto p-5">
                                    <BulletPoint
                                      heading={
                                        "Track and manage your mutual fund"
                                      }
                                      text={`Across multiple brokers at one place. Always stay on top of your
          holdings`}
                                    />
                                    <BulletPoint
                                      heading={`Real time analysis of your mutual fund performance`}
                                      text={`Powerful and in-depth analysis on all your holdings with actionable insights`}
                                    />
                                    <BulletPoint
                                      heading={`Get advisory on your mutual fund portfolio`}
                                      text={`Real time investment advisory, super-charge your portfolio's performance!`}
                                    />

                                    <div className="pt-3">
                                      <ActionButton
                                        label={"Add mutual fund now"}
                                        onClick={() => {
                                          navigate(
                                            process.env.PUBLIC_URL +
                                              "/direct-mutual-fund/funds/all"
                                          );
                                        }}
                                        
                                      />
                                      <ActionButton
                                        label={"Fetch your external holdings"}
                                        onClick={() => {
                                          navigate(
                                            process.env.PUBLIC_URL +
                                              "/direct-mutual-fund/portfolio/link-your-holdings"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* ================================================================= MF table end ================================================================= */}
                <div>
                  {/* {!otherInvestmentData ? ( */}
                  {/* {Object.keys(otherInvestmentData).length === 0 && searchParams.get("assetTabNumber") != 1 ? ( */}
                  {isDataLoading.otherInvestmentData &&
                  searchParams.get("assetTabNumber") != 1 ? (
                    <div className={`insideTabContent px-2 px-md-4`}>
                      <Table
                        responsive
                        className={`ptTable fixedTable ${style.mfTable} mb-0 ${style.dataTable}`}
                      >
                        <tbody>
                          <tr>
                            <td className="pt-1">
                              <div className="shine"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-1">
                              <div className="shine"></div>
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-1">
                              <div className="shine"></div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 2 ? "" : "d-none"
                        }`}
                      >
                        {"insurance_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.insurance_data)
                              .length
                          ) && (
                            <div className="row pb-3">
                              <div className="col-12 col-md-9">
                                <div className="d-none d-md-block">
                                  <div className="cntResults">
                                    <div className={style.ecntRItems}>
                                      <div className={style.borderSpace}>
                                        No. Of Policies
                                      </div>
                                      <div
                                        className={`borderSpace borderText pointer`}
                                      >
                                        {otherInvestmentData?.insurance_data
                                          ?.no_of_policies != undefined
                                          ? otherInvestmentData.insurance_data
                                              .no_of_policies
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className={style.ecntRItems}>
                                      <div
                                        className={`${style.extraSpace} ${style.borderSpace}`}
                                      >
                                        Premium Amount
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {otherInvestmentData?.insurance_data
                                          ?.total_premium_amount
                                          ? indianRupeeFormat(
                                              otherInvestmentData.insurance_data
                                                .total_premium_amount
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                    <div
                                      className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                    >
                                      <div
                                        className={`${style.extraSpace} ${style.borderSpace}`}
                                      >
                                        Sum Assured
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {otherInvestmentData?.insurance_data
                                          ?.total_risk_coverage
                                          ? indianRupeeFormat(
                                              otherInvestmentData.insurance_data
                                                .total_risk_coverage
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`col-12 col-md-3 ${style.addBtnContainer}`}
                              >
                                {getItemLocal("family") ? (
                                  <>
                                    <div
                                      className={`${style.addBtn}`}
                                      data-tip
                                      data-for="DisabledButtonInlineAddInsurance"
                                      data-event-off
                                      data-title=""
                                    >
                                      <Link
                                        className={`disabled anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-insurance"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Insurance</span>
                                      </Link>
                                      <ReactTooltip
                                        id="DisabledButtonInlineAddInsurance"
                                        place="top"
                                        effect="solid"
                                        style={{
                                          height: "200px !important",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            marginBottom: "0",
                                          }}
                                        >
                                          {" "}
                                          To initiate this action, please choose
                                          a member.{" "}
                                        </p>
                                      </ReactTooltip>
                                    </div>
                                  </>
                                ) : (
                                  <div className={`${style.addBtn}`}>
                                    <Link
                                      className={`anchor-primary ${style.linkStyle}`}
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/commondashboard/investment/new-insurance"
                                      }
                                    >
                                      <span>
                                        <i className="fa-solid fa-plus"></i>
                                      </span>{" "}
                                      <span>Add Insurance</span>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        {"insurance_data" in otherInvestmentData &&
                          Object.keys(otherInvestmentData.insurance_data)
                            .length > 0 && (
                            <div className="fixedHeaders">
                              <div className="table-responsive">
                                <div className="ptTableBx">
                                  <Table
                                    className={`ptTable ${style["portfolio-insurance-table"]} ${style["bold-table"]} mb-0`}
                                  >
                                    <thead>
                                      <tr>
                                        <td
                                          scope="col"
                                          // className={`${style.policyCompany}`}
                                        >
                                          Policy Name
                                        </td>
                                        <td scope="col">Policy Number</td>
                                        <td scope="col">Insurance Type</td>
                                        <td scope="col">Premium (&#x20B9;)</td>
                                        <td scope="col">
                                          Sum assured (&#x20B9;)
                                        </td>
                                        <td scope="col">Start Date</td>
                                        <td scope="col">Term Date</td>
                                        <td
                                          scope="col"
                                          style={{ width: "10%" }}
                                        >
                                          &nbsp;
                                        </td>
                                      </tr>
                                    </thead>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          )}

                        <div className="">
                          <Table
                            responsive
                            className={`ptTable fixedTable ${style.dataTable} ${style["portfolio-insurance-table"]} mb-0`}
                          >
                            <tbody>
                              {otherInvestmentData?.insurance_data
                                ?.insurance_details ? (
                                otherInvestmentData.insurance_data.insurance_details.map(
                                  (v) => (
                                    <tr
                                      className={`${style.tableRowStyle}`}
                                      style={{
                                        borderBottom:
                                          "1px solid black !important",
                                      }}
                                      key={v?.insurance_id}
                                    >
                                      <td
                                        scope="row"
                                        data-label="Policy Name"
                                        // className={`${style.policyCompany}`}
                                      >
                                        <div className="d-flex">
                                          <div className="pe-3">
                                            <img
                                              class={`accordian-img ${style.tblIcons}`}
                                              src={
                                                process.env
                                                  .REACT_APP_STATIC_URL_PYTHON +
                                                "/assets/img/insurance/insurance_insurance_form.svg"
                                              }
                                            />
                                          </div>
                                          <div>
                                            {v.insurance_company_name == null
                                              ? v.policy_name != ""
                                                ? v.policy_name
                                                : "-"
                                              : v.insurance_company_name}
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Policy Number"
                                      >
                                        {/* <strong>{v.policy_name}</strong>
                              <br />
                              <strong className={style.smallfont}>
                                Policy No.: {v.policy_no ? v.policy_no : "-"}
                              </strong> */}

                                        {v.policy_no ? v.policy_no : "-"}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Insurance Type"
                                        className=""
                                      >
                                        {v.insurance_type
                                          ? insuraceType(v.insurance_type)
                                          : "-"}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Premium"
                                        className=""
                                      >
                                        {indianRupeeFormat(v.premium_amount)}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Sum assured"
                                        className=""
                                      >
                                        {indianRupeeFormat(v.risk_coverage)}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Start Date"
                                        className=""
                                      >
                                        {v?.policy_start_date
                                          ? moment(v.policy_start_date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Term Date"
                                        className=""
                                      >
                                        {v?.maturitydate
                                          ? moment(v.maturitydate).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        <div
                                          className={style.actionBtnsContainer}
                                        >
                                          {/* <div className="pointer">
                                            <ExploreStock />
                                          </div> */}
                                          {getItemLocal("family") ? (
                                            <i
                                              className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                            ></i>
                                          ) : (
                                            <Link
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-insurance/" +
                                                btoa(v.insurance_id)
                                              }
                                            >
                                              <i
                                                className={`fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </Link>
                                          )}
                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.insurance_id,
                                                  "insurance",
                                                  v.fp_log_id != null &&
                                                    v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete your insurance?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <div>
                                  <br />
                                  <p className="text-center fw-bold mb-0">
                                    Currently it seems like we don't have your
                                    Insurance Policy data to display
                                  </p>
                                  <p className="text-center fw-bold">
                                    You can add your existing Insurance Policy
                                  </p>
                                  <div className="text-center">
                                    {getItemLocal("family") ? (
                                      <>
                                        <Link
                                          className="disabled anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-insurance"
                                          }
                                        >
                                          Add Insurance
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          className="anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-insurance"
                                          }
                                        >
                                          Add Insurance
                                        </Link>
                                      </>
                                    )}
                                  </div>
                                  <div className="text-center">
                                    <img
                                      className="pt-4"
                                      src={getPublicMediaURL(
                                        "static/media/DMF/insurance65.png"
                                      )}
                                      style={{ width: 200 }}
                                    />
                                  </div>
                                </div>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/* fd section */}
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 3 ? "" : "d-none"
                        }`}
                      >
                        {"fdbond_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.fdbond_data).length
                          ) && (
                            <div className="row pb-3">
                              <div className="col-12 col-md-9">
                                <div className="d-none d-md-block">
                                  <div className="cntResults">
                                    <div className={style.ecntRItems}>
                                      <div className={style.borderSpace}>
                                        Total FDs & Bonds
                                      </div>
                                      <div
                                        className={`borderSpace borderText pointer`}
                                      >
                                        {otherInvestmentData?.fdbond_data
                                          ?.no_of_fdbonds != undefined
                                          ? otherInvestmentData.fdbond_data
                                              .no_of_fdbonds
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className={style.ecntRItems}>
                                      <div
                                        className={`${style.extraSpace} ${style.borderSpace}`}
                                      >
                                        Invested Value
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {otherInvestmentData?.fdbond_data
                                          ?.total_amount
                                          ? indianRupeeFormat(
                                              Math.round(
                                                otherInvestmentData.fdbond_data
                                                  .total_amount
                                              )
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                    <div
                                      className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                    >
                                      <div
                                        className={`${style.extraSpace} ${style.borderSpace}`}
                                      >
                                        Current Value
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {otherInvestmentData?.fdbond_data
                                          ?.current_value
                                          ? indianRupeeFormat(
                                              Math.round(
                                                otherInvestmentData.fdbond_data
                                                  .current_value
                                              )
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {"fdbond_data" in otherInvestmentData &&
                          Object.keys(otherInvestmentData.fdbond_data).length >
                            0 && (
                            <div className="fixedHeaders">
                              <div className="table-responsive">
                                <div className="ptTableBx">
                                  <Table
                                    className={`ptTable ${style["portfolio-fd-table"]} ${style.fdTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                  >
                                    <thead>
                                      <tr>
                                        <td scope="col">Name of Asset</td>
                                        <td scope="col">Tenure (Year)</td>
                                        <td scope="col">Interest Rate</td>
                                        <td scope="col">Invested Value</td>
                                        <td scope="col">Current Value</td>
                                        <td scope="col">Maturity Value</td>
                                        <td scope="col">Start Date</td>
                                        <td scope="col">Maturity Date</td>
                                        <td scope="col">&nbsp;</td>
                                      </tr>
                                    </thead>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="  ">
                          <Table
                            responsive
                            className={`ptTable fixedTable ${style["portfolio-fd-table"]} ${style["actual-data-table"]} ${style.fdTbl} mb-0 ${style.dataTable}`}
                          >
                            <tbody>
                              {otherInvestmentData?.fdbond_data
                                ?.fdbond_details ? (
                                otherInvestmentData.fdbond_data.fdbond_details.map(
                                  (v) => (
                                    <tr key={v.id}>
                                      <td
                                        scope="row"
                                        data-label="Name of Asset"
                                      >
                                        <AssetName
                                          title={
                                            v.scheme_name != null
                                              ? v.scheme_name
                                              : v?.issuer_type != null ||
                                                v.issuer_type != ""
                                              ? v?.issuer_type
                                              : "-"
                                          }
                                          icon={
                                            process.env
                                              .REACT_APP_STATIC_URL_PYTHON +
                                            "/assets/img/insurance/insurance_insurance_form.svg"
                                          }
                                        />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Tenure (Year)"
                                        className=""
                                      >
                                        {(v.tenure / 365).toFixed(2) !== "0.00"
                                          ? (v.tenure / 365).toFixed(2)
                                          : "-"}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Interest Rate"
                                        className=""
                                      >
                                        {v.interest_rate
                                          ? v.interest_rate
                                          : "-"}
                                        %
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Invested Value"
                                        className=""
                                      >
                                        {v.amount === 0.0
                                          ? "-"
                                          : indianRupeeFormat(
                                              Math.round(v.amount)
                                            )}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Current Value"
                                        className=""
                                      >
                                        {indianRupeeFormat(
                                          Math.round(v.current_value)
                                        )}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Maturity Amount"
                                        className=""
                                      >
                                        <span className={`xrr-returns`}>
                                          {indianRupeeFormat(
                                            Math.round(
                                              v.maturity_value
                                                ? v.maturity_value * 1
                                                : "-"
                                            )
                                          )}
                                        </span>
                                      </td>
                                      {/* <td scope="row" data-label="XIRR %" className="">
                              <strong className={`xrr-returns`}>
                                {v.interest_rate}%
                              </strong>
                            </td> */}
                                      <td
                                        scope="row"
                                        data-label="Start Date"
                                        className=""
                                      >
                                        <span className={`xrr-returns`}>
                                          {v.investment_date
                                            ? moment(v.investment_date).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "-"}
                                        </span>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Maturity Date"
                                        className=""
                                      >
                                        <span className={`xrr-returns`}>
                                          {v.maturitydate
                                            ? moment(v.maturitydate).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "-"}
                                        </span>
                                      </td>

                                      <td>
                                        <div
                                          className={style.actionBtnsContainer}
                                        >
                                          {/* <div className="pointer disabled">
                                            <ExploreStock />
                                          </div>
                                          {getItemLocal("family") ? (
                                            <i
                                              className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                            ></i>
                                          ) : (
                                            <i
                                              className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                            ></i>
                                          )} */}

                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.id,
                                                  "fd_bond",
                                                  v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <div>
                                  <br />
                                  <p className="text-center fw-bold mb-0">
                                    Currently it seems like we don't have your
                                    Bonds, Fixed Deposit data to display
                                  </p>
                                  <p className="text-center fw-bold">
                                    {/* You can add your existing Debt Assets */}
                                  </p>
                                  <div className="text-center">
                                    <img
                                      className="pt-4"
                                      src={getPublicMediaURL(
                                        "static/media/DMF/FD-bond.png"
                                      )}
                                      style={{ width: 200 }}
                                    />
                                  </div>
                                </div>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/* govt scheme section */}
                      <div
                        className={`govtschemetable insideTabContent px-2 px-md-4 ${
                          selectedTab == 4 ? "" : "d-none"
                        }`}
                      >
                        {"po_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.po_data).length
                          ) && (
                            <div className="row pb-3">
                              <div className="col-12 col-md-9">
                                <div className="d-block">
                                  <div className="cntResults">
                                    <div className={style.ecntRItems}>
                                      <div className={style.borderSpace}>
                                        Total Schemes
                                      </div>
                                      <div
                                        className={`borderSpace borderText pointer`}
                                      >
                                        {otherInvestmentData?.po_data
                                          ?.no_of_po_schemes != undefined
                                          ? otherInvestmentData.po_data
                                              .no_of_po_schemes
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className={style.ecntRItems}>
                                      <div
                                        className={`${style.extraSpace} ${style.borderSpace}`}
                                      >
                                        Invested Value
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {otherInvestmentData?.po_data
                                          ?.total_amount
                                          ? indianRupeeFormat(
                                              otherInvestmentData.po_data
                                                .total_amount * 1
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                    <div
                                      className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                    >
                                      <div
                                        className={`${style.extraSpace} ${style.borderSpace}`}
                                      >
                                        Current Value
                                      </div>
                                      <div className={`borderSpace borderText`}>
                                        {otherInvestmentData?.po_data
                                          ?.total_current_value
                                          ? indianRupeeFormat(
                                              otherInvestmentData.po_data
                                                .total_current_value
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {"po_data" in otherInvestmentData &&
                          Object.keys(otherInvestmentData.po_data).length >
                            0 && (
                            <div className="fixedHeaders">
                              <div className="table-responsive">
                                <div className="ptTableBx">
                                  <Table
                                    className={`ptTable ${style.govtSchemeTbl} mb-0 ${style["bold-table"]} ${style.headerTable}`}
                                  >
                                    <thead>
                                      <tr>
                                        <td scope="col">Name Of Asset</td>
                                        <td scope="col">Interest Rate</td>
                                        <td scope="col">Frequency</td>
                                        <td scope="col">Invested Value</td>
                                        <td scope="col">Current Value</td>
                                        <td scope="col">Maturity Value</td>
                                        <td scope="col">Start Date</td>
                                        <td scope="col">Maturity Date</td>
                                        <td scope="col">&nbsp;</td>
                                      </tr>
                                    </thead>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="  ">
                          <Table
                            responsive
                            className={`ptTable fixedTable ${style.govtSchemeTbl} mb-0 ${style.dataTable}`}
                          >
                            <tbody>
                              {otherInvestmentData?.po_data?.po_details ? (
                                otherInvestmentData.po_data.po_details.map(
                                  (v) => (
                                    <tr key={v.post_id}>
                                      <td scope="row" data-label="Funds">
                                        <AssetName
                                          title={v.scheme_name}
                                          icon={
                                            process.env
                                              .REACT_APP_STATIC_URL_PYTHON +
                                            "/assets/img/insurance/insurance_insurance_form.svg"
                                          }
                                        />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Current Value"
                                        className=""
                                      >
                                        <strong>
                                          {v.interest
                                            ? parseFloat(v.interest) + "%"
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Units"
                                        className=""
                                      >
                                        <strong>
                                          {v.asset_frequency
                                            ? getFrequencyName(
                                                v.asset_frequency
                                              )
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td scope="row" data-label="Invested">
                                        <strong>
                                          {indianRupeeFormat(
                                            v.amount ? v.amount * 1 : "-"
                                          )}
                                        </strong>
                                        <br />
                                        {/* <strong className={style.smallfont}>
                                Policy No.: {v.policy_no}
                              </strong> */}
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Gain | Loss"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          <strong className={`xrr-returns`}>
                                            {v.current_value
                                              ? indianRupeeFormat(
                                                  v.current_value
                                                )
                                              : "-"}
                                          </strong>
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="XIRR %"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v.maturity_amount
                                            ? indianRupeeFormat(
                                                v.maturity_amount * 1
                                              )
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="XIRR %"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v.investment_date
                                            ? moment(v.investment_date).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="XIRR %"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v.date_maturity
                                            ? moment(v.date_maturity).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td>
                                        <div
                                          className={style.actionBtnsContainer}
                                        >
                                          {/* <div className="pointer disabled">
                                            <ExploreStock />
                                          </div> */}

                                          {/* <i
                                  className={`fa-solid fa-circle-arrow-right ${style.trash}`}
                                ></i> */}

                                          {/* {getItemLocal("family") ? (
                                            <>
                                              <i
                                                className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </>
                                          ) : (
                                            <>
                                              <i
                                                className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </>
                                          )} */}

                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.post_id,
                                                  "po",
                                                  v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <>
                                  {/* <p style={{ textAlign: "center", fontWeight: "700" }}>
                            No investment(s) found!
                          </p> */}
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems like we don't have your
                                      Government Scheme data to display
                                    </p>
                                    <p className="text-center fw-bold">
                                      {/* You can add your existing Government
                                      Schemes */}
                                    </p>
                                    <div className="text-center">
                                      <img
                                        className="pt-4"
                                        src={getPublicMediaURL(
                                          "static/media/DMF/Govt-schemes.png"
                                        )}
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 5 ? "" : "d-none"
                        }`}
                      >
                        {"real_estate_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.real_estate_data)
                              .length
                          ) && (
                            <>
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          No. Of Properties
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.real_estate_data
                                            ?.no_of_properties != undefined
                                            ? otherInvestmentData
                                                .real_estate_data
                                                .no_of_properties
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Purchased Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.real_estate_data
                                            ?.total_purchase_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .real_estate_data
                                                  .total_purchase_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.real_estate_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .real_estate_data
                                                  .total_current_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                >
                                  <div className={`${style.addBtn}`}>
                                    {getItemLocal("family") ? (
                                      <Link
                                        className={`disabled anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-real-estate"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Real Estate</span>
                                      </Link>
                                    ) : (
                                      <>
                                        <Link
                                          className={`anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-real-estate"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Real Estate</span>
                                        </Link>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable mb-0 ${style["bold-table"]} ${style.realEstateTable} ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">Name Of Property</td>
                                          <td scope="col">Type of Property</td>
                                          <td scope="col">Purchased Value</td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">Returns</td>
                                          <td scope="col">Absolute Returns</td>
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        <div className="  ">
                          <Table
                            responsive
                            className={`ptTable fixedTable ${style.realEstateTable} mb-0 ${style.dataTable}`}
                          >
                            <tbody>
                              {otherInvestmentData?.real_estate_data
                                ?.real_estate_details ? (
                                otherInvestmentData.real_estate_data.real_estate_details.map(
                                  (v) => (
                                    <tr
                                      key={v.realestate_id}
                                      className={`ptTableBx `}
                                    >
                                      <td
                                        scope="row"
                                        data-label="Type/Name Of Property"
                                      >
                                        <AssetName
                                          title={
                                            v.property_name
                                              ? v.property_name
                                              : "-"
                                          }
                                          icon={
                                            process.env
                                              .REACT_APP_STATIC_URL_PYTHON +
                                            "assets/img/assets-liabilities/assets_real_estate.svg"
                                          }
                                        />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Residential Type"
                                      >
                                        <strong>
                                          {v.property_type !== undefined
                                            ? v.property_type
                                            : v?.asset_type !== undefined
                                            ? v.asset_type
                                            : "-"}
                                        </strong>
                                        <br />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Purchased Value"
                                      >
                                        <strong className={`xrr-returns`}>
                                          {/* {v.purchase_date
                                  ? moment(v.purchase_date).format(
                                    "DD/MM/YYYY"
                                  )
                                  : "-"} */}
                                          {v.purchase_rate
                                            ? indianRupeeFormat(
                                                v.purchase_rate * 1
                                              )
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Current Value"
                                      >
                                        <strong>
                                          {v.current_rate
                                            ? indianRupeeFormat(
                                                v.current_rate * 1
                                              )
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td scope="row" data-label="Returns">
                                        <strong
                                          className={`xrr-returns ${
                                            v.current_rate - v.purchase_rate < 0
                                              ? "red"
                                              : "green"
                                          }`}
                                        >
                                          {v.current_rate - v.purchase_rate
                                            ? indianRupeeFormat(
                                                v.current_rate -
                                                  v.purchase_rate * 1
                                              )
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Absolute Returns"
                                      >
                                        <strong
                                          className={`xrr-returns ${
                                            v?.purchase_rate !== 0
                                              ? ((v?.current_rate -
                                                  v?.purchase_rate) /
                                                  v?.purchase_rate) *
                                                  100 <
                                                0
                                                ? "red"
                                                : "green"
                                              : "default-class"
                                          }`}
                                        >
                                          {v?.purchase_rate !== 0
                                            ? `${(
                                                ((v?.current_rate -
                                                  v?.purchase_rate) /
                                                  v?.purchase_rate) *
                                                100
                                              ).toFixed(2)} %`
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td>
                                        <div
                                          className={style.actionBtnsContainer}
                                        >
                                          {getItemLocal("family") ? (
                                            <>
                                              <i
                                                className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </>
                                          ) : (
                                            <>
                                              <Link
                                                to={
                                                  process.env.PUBLIC_URL +
                                                  "/commondashboard/investment/new-real-estate/" +
                                                  btoa(v.realestate_id)
                                                }
                                              >
                                                <i
                                                  className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                ></i>
                                              </Link>
                                            </>
                                          )}

                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.realestate_id,
                                                  "real_estate",
                                                  v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <>
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems like we don't have your
                                      Real Estate Data to display
                                    </p>
                                    <p className="text-center fw-bold">
                                      You can add your existing Real Estate
                                    </p>
                                    <div className="text-center">
                                      {getItemLocal("family") ? (
                                        <>
                                          <Link
                                            className="disabled anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-real-estate"
                                            }
                                          >
                                            Add Existing Real Estate
                                          </Link>
                                          <img
                                            className="pt-4"
                                            src={
                                              process.env.REACT_APP_STATIC_URL +
                                              "/media/Group-162.png"
                                            }
                                            style={{ width: 200 }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Link
                                            className="anchor-primary"
                                            to={
                                              process.env.PUBLIC_URL +
                                              "/commondashboard/investment/new-real-estate"
                                            }
                                          >
                                            Add Existing Real Estate
                                          </Link>
                                          <img
                                            className="pt-4"
                                            src={
                                              process.env.REACT_APP_STATIC_URL +
                                              "/media/Group-162.png"
                                            }
                                            style={{ width: 200 }}
                                          />
                                        </>
                                      )}
                                      <img
                                        className="pt-4"
                                        src={getPublicMediaURL(
                                          "static/media/DMF/real-estate2.png"
                                        )}
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 6 ? "" : "d-none"
                        }`}
                      >
                        {"alternate_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.alternate_data)
                              .length
                          ) && (
                            <>
                              <div className="row pb-3">
                                <div className="col-12 col-md-10">
                                  <div className="d-none d-md-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          Total Alternate Assets
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.alternate_data
                                            ?.no_of_assets != undefined
                                            ? otherInvestmentData.alternate_data
                                                .no_of_assets
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Invested Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.alternate_data
                                            ?.total_purchase_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .alternate_data
                                                  .total_purchase_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.alternate_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData
                                                  .alternate_data
                                                  .total_current_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-12">
                                  {getItemLocal("family") ? (
                                    <div className={`${style.addBtn}`}>
                                      <Link
                                        className={`disabled anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-alternate-asset"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Alternate</span>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className={`${style.addBtn}`}>
                                      <Link
                                        className={`anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-alternate-asset"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Alternate</span>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style.alternateTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">
                                            Name of Asset (Category)
                                          </td>
                                          <td scope="col">Invested Value</td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">Returns</td>
                                          <td scope="col">Absolute Return</td>
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        <div className="  ">
                          <Table
                            className={`ptTable fixedTable ${style.alternateTbl} mb-0 ${style.dataTable}`}
                          >
                            <tbody>
                              {otherInvestmentData?.alternate_data
                                ?.alternate_details ? (
                                otherInvestmentData?.alternate_data?.alternate_details.map(
                                  (v) => (
                                    <tr key={v.id}>
                                      <td scope="row" data-label="asset_name">
                                        <strong></strong>
                                        <AssetName
                                          title={
                                            v.asset_type ? v.asset_type : "-"
                                          }
                                          icon={
                                            process.env
                                              .REACT_APP_STATIC_URL_PYTHON +
                                            "/assets/img/assets-liabilities/assets_alternate.svg"
                                          }
                                        />
                                      </td>
                                      <td scope="row" data-label="invested_val">
                                        <strong>
                                          {v.inv_val != 0.0
                                            ? indianRupeeFormat(v.inv_val * 1)
                                            : "-"}
                                        </strong>
                                        <br />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="curr_val"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v.cr_val
                                            ? indianRupeeFormat(v.cr_val * 1)
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="returns"
                                        className=""
                                      >
                                        <strong
                                          className={`xrr-returns ${
                                            v.rtn * 1 < 0
                                              ? "red"
                                              : v.rtn * 1 === 0 || v.rtn === "-"
                                              ? ""
                                              : "green"
                                          }`}
                                        >
                                          {v.rtn * 1 != 0
                                            ? indianRupeeFormat(v.rtn * 1)
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="absolute_rtn"
                                        className=""
                                      >
                                        <strong
                                          className={`xrr-returns ${
                                            v.abs_rtn * 1 < 0
                                              ? "red"
                                              : v.abs_rtn * 1 === 0 ||
                                                v.abs_rtn === "-"
                                              ? ""
                                              : "green"
                                          }`}
                                        >
                                          {v.abs_rtn * 1 != 0
                                            ? v.abs_rtn + " %"
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td>
                                        <div
                                          className={style.actionBtnsContainer}
                                        >
                                          {getItemLocal("family") ? (
                                            <i
                                              className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                            ></i>
                                          ) : (
                                            <Link
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-alternate-asset/" +
                                                btoa(v.id)
                                              }
                                            >
                                              <i
                                                className={`fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </Link>
                                          )}
                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.id,
                                                  "alternate",
                                                  v.fp_log_id != null &&
                                                    v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete your asset?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>

                                        {/*  onClick={deleteAsset()}  */}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <>
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems like we don't have your
                                      Alternate Assets Data to display
                                    </p>
                                    <p className="text-center fw-bold">
                                      You can add your existing Alternate Assets
                                    </p>
                                    <div className="text-center">
                                      {getItemLocal("family") ? (
                                        <Link
                                          className="disabled anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-alternate-asset"
                                          }
                                        >
                                          Add Alternate Assets
                                        </Link>
                                      ) : (
                                        <Link
                                          className="anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-alternate-asset"
                                          }
                                        >
                                          Add Alternate Assets
                                        </Link>
                                      )}
                                      <img
                                        className="pt-4"
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "/media/Group-177.png"
                                        }
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 7 ? "" : "d-none"
                        }`}
                      >
                        {"gold_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.gold_data).length
                          ) && (
                            <>
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          Total Gold Assets
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.gold_data
                                            ?.no_of_assets != undefined
                                            ? otherInvestmentData.gold_data
                                                .no_of_assets
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Invested Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.gold_data
                                            ?.total_purchase_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData.gold_data
                                                  .total_purchase_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.gold_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData.gold_data
                                                  .total_current_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                >
                                  <div className={`${style.addBtn}`}>
                                    {getItemLocal("family") ? (
                                      <Link
                                        className={`disabled anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-gold-asset"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Gold Assets</span>
                                      </Link>
                                    ) : (
                                      <Link
                                        className={`anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-gold-asset"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Gold Assets</span>
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style.goldTbl} ${style["bold-table"]} ${style.headerTable} mb-0`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">
                                            Name of Asset (category)
                                          </td>
                                          <td scope="col">Invested Value</td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">Returns</td>
                                          <td scope="col">Absolute Return</td>
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        <div className="  ">
                          <Table
                            responsive
                            className={`ptTable fixedTable ${style.goldTbl} ${style.dataTable} mb-0`}
                          >
                            <tbody>
                              {otherInvestmentData?.gold_data?.gold_details ? (
                                otherInvestmentData.gold_data.gold_details.map(
                                  (v) => (
                                    <tr key={v.id}>
                                      <td
                                        scope="row"
                                        data-label="Name of Asset (category)"
                                      >
                                        <AssetName
                                          title={
                                            v.asset_name ? v.asset_name : "-"
                                          }
                                          icon={
                                            process.env
                                              .REACT_APP_STATIC_URL_PYTHON +
                                            "/assets/img/assets-liabilities/assets_gold.svg"
                                          }
                                        />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Invested Value"
                                      >
                                        <strong>
                                          {v.inv_val != 0
                                            ? indianRupeeFormat(v.inv_val * 1)
                                            : "-"}
                                        </strong>
                                        <br />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Current Value"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v.cr_val != 0
                                            ? indianRupeeFormat(v.cr_val * 1)
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Returns"
                                        className=""
                                      >
                                        <strong
                                          className={`xrr-returns ${
                                            v.rtn * 1 < 0
                                              ? "red"
                                              : v.rtn * 1 === 0 || v.rtn === "-"
                                              ? ""
                                              : "green"
                                          }`}
                                        >
                                          {v.rtn * 1 != 0
                                            ? indianRupeeFormat(v.rtn * 1)
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Absolute Return"
                                        className=""
                                      >
                                        <strong
                                          className={`xrr-returns ${
                                            v.abs_rtn * 1 < 0
                                              ? "red"
                                              : v.abs_rtn * 1 === 0 ||
                                                v.abs_rtn === "-"
                                              ? ""
                                              : "green"
                                          }`}
                                        >
                                          {v.abs_rtn * 1 != 0
                                            ? v.abs_rtn + " %"
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td className={""}>
                                        <div
                                          className={style.actionBtnsContainer}
                                        >
                                          <div className="pointer">
                                            {/* <ExploreStock /> */}
                                          </div>
                                          {getItemLocal("family") ? (
                                            <i
                                              className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                            />
                                          ) : (
                                            <Link
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-gold-asset/" +
                                                btoa(v.id)
                                              }
                                            >
                                              <i
                                                className={`fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </Link>
                                          )}
                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.id,
                                                  "gold",
                                                  v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                        {/*  onClick={deleteAsset()}  */}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <>
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems like we don't have your
                                      Gold Assets Data to display
                                    </p>
                                    <p className="text-center fw-bold">
                                      You can add your existing Gold Assets
                                    </p>
                                    <div className="text-center">
                                      {getItemLocal("family") ? (
                                        <Link
                                          className="disabled anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-gold-asset"
                                          }
                                        >
                                          Add Gold Assets
                                        </Link>
                                      ) : (
                                        <Link
                                          className="anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-gold-asset"
                                          }
                                        >
                                          Add Gold Assets
                                        </Link>
                                      )}
                                      <img
                                        className="pt-4"
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "/media/gold-icon.png"
                                        }
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 8 ? "" : "d-none"
                        }`}
                      >
                        {"liquid_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.liquid_data).length
                          ) && (
                            <>
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          Total Liquid Assets
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.liquid_data
                                            ?.no_of_assets != undefined
                                            ? otherInvestmentData.liquid_data
                                                .no_of_assets
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Invested Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {/* {otherInvestmentData?.liquid_data
                                    ?.total_purchase_rate
                                    ? indianRupeeFormat(
                                        otherInvestmentData.liquid_data
                                          .total_purchase_rate * 1
                                      )
                                    : "-"} */}
                                          {"-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.liquid_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData?.liquid_data
                                                  ?.total_current_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                >
                                  <div className={`${style.addBtn}`}>
                                    {getItemLocal("family") ? (
                                      <Link
                                        className={`disabled anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-liquid-asset"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Liquid Assets</span>
                                      </Link>
                                    ) : (
                                      <Link
                                        className={`anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-liquid-asset"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Liquid Assets</span>
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style["bold-table"]} ${style["liquid-table"]} mb-0 ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">
                                            Name of Asset (Category)
                                          </td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        <div className="  ">
                          <Table
                            className={`ptTable fixedTable ${style["liquid-table"]} mb-0 ${style.dataTable}`}
                          >
                            <tbody>
                              {otherInvestmentData?.liquid_data
                                ?.liquid_details ? (
                                otherInvestmentData.liquid_data.liquid_details.map(
                                  (v) => (
                                    <tr key={v.id}>
                                      <td
                                        scope="row"
                                        data-label="Type/Name Of Property"
                                      >
                                        <AssetName
                                          title={
                                            v.asset_name ? v.asset_name : "-"
                                          }
                                          icon={
                                            process.env
                                              .REACT_APP_STATIC_URL_PYTHON +
                                            "/assets/img/assets-liabilities/assets_liquid.svg"
                                          }
                                        />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="Purchased Value"
                                      >
                                        <span className={`xrr-returns`}>
                                          {v.cr_val
                                            ? indianRupeeFormat(v.cr_val * 1)
                                            : "-"}
                                        </span>
                                      </td>
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2rem",
                                          }}
                                        >
                                          <div className="pointer">
                                            {/* <ExploreStock /> */}
                                          </div>
                                          {getItemLocal("family") ? (
                                            <i
                                              className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                            />
                                          ) : (
                                            <Link
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-liquid-asset/" +
                                                btoa(v.id)
                                              }
                                            >
                                              <i
                                                className={`fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </Link>
                                          )}

                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.id,
                                                  "liquid",
                                                  v.fp_log_id != null &&
                                                    v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete your asset?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <>
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems we don't have your
                                      Liquid Assets Data to display
                                    </p>
                                    <p className="text-center fw-bold">
                                      {/* You can add your existing Liquid Assets */}
                                    </p>
                                    <div className="text-center">
                                      {getItemLocal("family") ? (
                                        <Link
                                          className="disabled anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-liquid-asset"
                                          }
                                        >
                                          Add Liquid Assets
                                        </Link>
                                      ) : (
                                        <Link
                                          className="anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-liquid-asset"
                                          }
                                        >
                                          Add Liquid Assets
                                        </Link>
                                      )}
                                      <img
                                        className="pt-4"
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "/media/liquid_image.svg"
                                        }
                                        alt="Liquid"
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 9 ? "" : "d-none"
                        }`}
                      >
                        {"other_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.other_data).length
                          ) && (
                            <>
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="cntResults">
                                      <div className={style.ecntRItems}>
                                        <div className={style.borderSpace}>
                                          Total Other Assets
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.other_data
                                            ?.no_of_assets != undefined
                                            ? otherInvestmentData.other_data
                                                .no_of_assets
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className={style.ecntRItems}>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Invested Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {/* {otherInvestmentData?.other_data
                                    ?.total_purchase_rate
                                    ? indianRupeeFormat(
                                      otherInvestmentData.other_data
                                        .total_purchase_rate * 1
                                    )
                                    : "-"} */}
                                          {"-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`mt-2 mt-md-0 ${style.ecntRItems}`}
                                      >
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.other_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData.other_data
                                                  .total_current_rate * 1
                                              )
                                            : "-"}
                                          {/* {"Cr. Val."} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 col-md-3 ${style.addBtnContainer}`}
                                >
                                  {getItemLocal("family") ? (
                                    <>
                                      <div
                                        className={`${style.addBtn}`}
                                        data-tip
                                        data-for="DisabledButtonInlineAddInsurance"
                                        data-event-off
                                        data-title=""
                                      >
                                        <Link
                                          className={`disabled anchor-primary ${style.linkStyle}`}
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-others-asset"
                                          }
                                        >
                                          <span>
                                            <i className="fa-solid fa-plus"></i>
                                          </span>{" "}
                                          <span>Add Other Assets</span>
                                        </Link>
                                        <ReactTooltip
                                          id="DisabledButtonInlineAddInsurance"
                                          place="top"
                                          effect="solid"
                                          style={{
                                            height: "200px !important",
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              marginBottom: "0",
                                            }}
                                          >
                                            {" "}
                                            To initiate this action, please
                                            choose a member.{" "}
                                          </p>
                                        </ReactTooltip>
                                      </div>
                                    </>
                                  ) : (
                                    <div className={`${style.addBtn}`}>
                                      <Link
                                        className={`anchor-primary ${style.linkStyle}`}
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/commondashboard/investment/new-others-asset"
                                        }
                                      >
                                        <span>
                                          <i className="fa-solid fa-plus"></i>
                                        </span>{" "}
                                        <span>Add Other Assets</span>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style["bold-table"]} ${style.otherInvTbl} ${style.headerTable} mb-0`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">
                                            Name of Asset (category)
                                          </td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        <div className="  ">
                          <Table
                            responsive
                            className={`ptTable fixedTable ${style.otherInvTbl} ${style.dataTable} mb-0`}
                          >
                            <tbody>
                              {otherInvestmentData?.other_data
                                ?.other_details ? (
                                otherInvestmentData.other_data.other_details.map(
                                  (v) => (
                                    <tr key={v.id}>
                                      <td scope="row" data-label="title">
                                        <AssetName
                                          title={
                                            v.asset_name ? v.asset_name : "-"
                                          }
                                        />
                                      </td>

                                      <td
                                        scope="row"
                                        data-label="current val"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v.cr_val != 0
                                            ? indianRupeeFormat(v.cr_val * 1)
                                            : "-"}
                                          {/* {"Cr. Val. "} */}
                                        </strong>
                                      </td>

                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2rem",
                                          }}
                                        >
                                          {getItemLocal("family") ? (
                                            <i
                                              className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                            />
                                          ) : (
                                            <Link
                                              to={
                                                process.env.PUBLIC_URL +
                                                "/commondashboard/investment/new-others-asset/" +
                                                btoa(v.id)
                                              }
                                            >
                                              <i
                                                className={`fa-solid fa-pen-to-square ${style.trash}`}
                                              ></i>
                                            </Link>
                                          )}
                                          {getItemLocal("family") ? (
                                            <i
                                              class={`disabled fa fa-trash ${style.trash}`}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <i
                                              class={`fa fa-trash ${style.trash}`}
                                              onClick={() =>
                                                deleteFdBond(
                                                  v.id,
                                                  "others",
                                                  v.fp_log_id != null &&
                                                    v.fp_log_id
                                                    ? v.fp_log_id
                                                    : "",
                                                  "Are you sure you want to delete your asset?"
                                                )
                                              }
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                        {/*  onClick={deleteAsset()}  */}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <>
                                  <div>
                                    <br />
                                    <p className="text-center fw-bold mb-0">
                                      Currently it seems we don't have your
                                      Others Assets Data to display
                                    </p>
                                    <p className="text-center fw-bold">
                                      You can add your existing Others Assets
                                    </p>
                                    <div className="text-center">
                                      {getItemLocal("family") ? (
                                        <Link
                                          className="disabled anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-others-asset"
                                          }
                                        >
                                          Add Others Assets
                                        </Link>
                                      ) : (
                                        <Link
                                          className="anchor-primary"
                                          to={
                                            process.env.PUBLIC_URL +
                                            "/commondashboard/investment/new-others-asset"
                                          }
                                        >
                                          Add Others Assets
                                        </Link>
                                      )}
                                      <img
                                        className="pt-4"
                                        src={
                                          process.env.REACT_APP_STATIC_URL +
                                          "/media/others-icon.png"
                                        }
                                        style={{ width: 300 }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${selectedTab == 10 ? "" : "d-none"
                          }`}
                      >
                        {
                          otherInvestmentData?.aif_equity_data?.other_details
                            ?.length > 0 ? (
                            <>
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="stocksTabInfo">
                                      <div style={{ width: "200px" }}>
                                        <div className={style.borderSpace}>
                                          Total Unlisted/AIF Equity
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.aif_equity_data
                                            ?.no_of_assets != undefined
                                            ? otherInvestmentData.aif_equity_data
                                              .no_of_assets
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Invested Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.aif_equity_data
                                            ?.total_purchase_rate
                                            ? indianRupeeFormat(
                                              otherInvestmentData.aif_equity_data
                                                .total_purchase_rate * 1
                                            )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.aif_equity_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                              otherInvestmentData.aif_equity_data
                                                .total_current_rate * 1
                                            )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Today Gain/Loss
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                          style={
                                            otherInvestmentData?.aif_equity_data
                                              ?.overall_return_val &&
                                              otherInvestmentData.aif_equity_data
                                                .overall_return_val < 0
                                              ? { color: "red" }
                                              : { color: "green" }
                                          }
                                        >
                                          {otherInvestmentData?.aif_equity_data
                                            ?.overall_return_val
                                            ? indianRupeeFormat(
                                              otherInvestmentData?.aif_equity_data
                                                ?.overall_return_val * 1
                                            )
                                            : "-"}
                                          <span
                                            className={`${style.totalGainlossval}`}
                                          >
                                            {otherInvestmentData?.aif_equity_data
                                              ?.overall_returns
                                              ? " (" +
                                              otherInvestmentData?.aif_equity_data
                                                ?.overall_returns +
                                              "%)"
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 col-md-3 ${style.addBtnContainer} ${style.wrapBtns} `}
                                >
                                  <Link
                                    className={getItemLocal("family") ? "disabled anchor-primary" : "anchor-primary"}
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/commondashboard/investment/new-unlisted-aif-equity-asset"
                                    }
                                  >
                                    + Add
                                  </Link>
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style.stockTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">Equity Name</td>
                                          <td scope="col">Invested Value</td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">No. Of Shares</td>
                                          <td scope="col">Total Returns(%)</td>
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <Table
                                  responsive
                                  className={`mb-0 ptTable fixedTable ${style.stockTbl} ${style.dataTable}`}
                                >
                                  <tbody>
                                    {
                                      otherInvestmentData.aif_equity_data.other_details.map(
                                        (v) => (
                                          <tr key={v.id}>
                                            <td scope="row" data-label="title">
                                              <AssetName
                                                title={v.asset_name}
                                              />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="invested val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.inv_val
                                                  ? v.inv_val * 1 != 0 &&
                                                    v.inv_val != undefined
                                                    ? indianRupeeFormat(v.inv_val * 1)
                                                    : "—"
                                                  : "—"}
                                              </strong>
                                              <br />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="current val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.cr_val
                                                  ? v.cr_val * 1 != 0 &&
                                                    v.cr_val != undefined
                                                    ? indianRupeeFormat(v.cr_val * 1)
                                                    : "—"
                                                  : "—"}
                                              </strong>
                                              <br />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="current val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.asset_units
                                                  ? v.asset_units != 0
                                                    ? Math.round(
                                                      (v.asset_units * 100) / 100
                                                    )
                                                    : "-"
                                                  : "-"}
                                              </strong>
                                              <br />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="current val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.cr_val
                                                  ? v.cr_val * 1 != 0 &&
                                                    v.cr_val != undefined
                                                    ? indianRupeeFormat(v.cr_val * 1)
                                                    : "—"
                                                  : "—"}
                                              </strong>
                                            </td>
                                            <td>
                                              <div className={`${style.actionBtnsContainer}`}>
                                                {getItemLocal("family") ? (
                                                  <i
                                                    className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                                  ></i>
                                                ) : (
                                                  <Link
                                                    to={
                                                      process.env.PUBLIC_URL +
                                                      "/commondashboard/investment/new-unlisted-aif-equity-asset/?id=" +
                                                      v.id
                                                    }
                                                  >
                                                    <i
                                                      className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                    ></i>
                                                  </Link>
                                                )}
                                                {getItemLocal("family") ? (
                                                  <i
                                                    class={`disabled fa fa-trash ${style.trash}`}
                                                    aria-hidden="true"
                                                  />
                                                ) : (
                                                  <i
                                                    class={`fa fa-trash ${style.trash}`}
                                                    onClick={() =>
                                                      deleteAsset(
                                                        v.id,
                                                        "unlistedAIF",
                                                        v.fp_log_id != null &&
                                                          v.fp_log_id
                                                          ? v.fp_log_id
                                                          : "",
                                                        "Are you sure you want to delete?"
                                                      )
                                                    }
                                                    aria-hidden="true"
                                                  />
                                                )}
                                              </div>

                                            </td>
                                          </tr>
                                        )
                                      )
                                    }
                                  </tbody>
                                </Table>
                              </div>
                            </>
                          ) :
                            (<>
                              <div className="table-responsive">
                                <br />
                                <p className="text-center fw-bold mb-0">
                                  Currently it seems like we don't have your
                                  Unlisted/AIF Equity data to display
                                </p>
                                <p className="text-center fw-bold mt-3">
                                  You can add your existing Unlisted/AIF Equity
                                </p>
                                <div className="text-center mt-4">
                                  {getItemLocal("family") ? (
                                    <Link
                                      className="disabled anchor-primary"
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/commondashboard/investment/new-unlisted-aif-equity-asset"
                                      }
                                    >
                                      Add Unlisted/AIF Equity
                                    </Link>
                                  ) : (
                                    <Link
                                      className="anchor-primary"
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/commondashboard/investment/new-unlisted-aif-equity-asset"
                                      }
                                    >
                                      Add Unlisted/AIF Equity
                                    </Link>
                                  )}
                                </div>
                                <div className="text-center">
                                  <img
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/DMF/investment.png"
                                    }
                                    className="pt-4"
                                    alt={"Start Investing"}
                                    style={{ width: 400 }}
                                  />
                                </div>
                              </div>
                            </>)
                        }
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${selectedTab == 11 ? "" : "d-none"
                          }`}
                      >
                        <>
                          {otherInvestmentData?.us_equity_data?.other_details
                            ?.length > 0 ? (<>
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="stocksTabInfo">
                                      <div>
                                        <div className={style.borderSpace}>
                                          Total US Equity
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.us_equity_data
                                            ?.no_of_assets != undefined
                                            ? otherInvestmentData.us_equity_data
                                              .no_of_assets
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Invested Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.us_equity_data
                                            ?.total_purchase_rate
                                            ? indianRupeeFormat(
                                              otherInvestmentData.us_equity_data
                                                .total_purchase_rate * 1
                                            )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.us_equity_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                              otherInvestmentData.us_equity_data
                                                .total_current_rate * 1
                                            )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Today Gain/Loss
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                          style={
                                            otherInvestmentData?.us_equity_data
                                              ?.overall_return_val &&
                                              otherInvestmentData.us_equity_data
                                                .overall_return_val < 0
                                              ? { color: "red" }
                                              : { color: "green" }
                                          }
                                        >
                                          {otherInvestmentData?.us_equity_data
                                            ?.overall_return_val
                                            ? indianRupeeFormat(
                                              otherInvestmentData?.us_equity_data
                                                ?.overall_return_val * 1
                                            )
                                            : "-"}
                                          <span
                                            className={`${style.totalGainlossval}`}
                                          >
                                            {otherInvestmentData?.us_equity_data
                                              ?.overall_returns
                                              ? " (" +
                                              otherInvestmentData?.us_equity_data
                                                ?.overall_returns +
                                              "%)"
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 col-md-3 ${style.addBtnContainer} ${style.wrapBtns} `}
                                >
                                  <Link
                                    className={getItemLocal("family") ? "disabled anchor-primary" : "anchor-primary"}
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/commondashboard/investment/new-us-equity-asset"
                                    }
                                  >
                                    + Add
                                  </Link>
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style.stockTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">Stock/ETF Name</td>
                                          <td scope="col">Invested Value</td>
                                          <td scope="col">Current Value</td>
                                          <td scope="col">No. Of Shares</td>
                                          <td scope="col">Total Returns(%)</td>
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <Table
                                  responsive
                                  className={`mb-0 ptTable fixedTable ${style.stockTbl} ${style.dataTable}`}
                                >
                                  <tbody>
                                    {
                                      otherInvestmentData.us_equity_data.other_details.map(
                                        (v) => (
                                          <tr key={v.id}>
                                            <td scope="row" data-label="title">
                                              <AssetName
                                                title={v.asset_name}
                                              />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="Invested val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.inv_val
                                                  ? v.inv_val * 1 != 0 &&
                                                    v.inv_val != undefined
                                                    ? indianRupeeFormat(v.inv_val * 1)
                                                    : "—"
                                                  : "—"}
                                              </strong>
                                              <br />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="current val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.cr_val
                                                  ? v.cr_val * 1 != 0 &&
                                                    v.cr_val != undefined
                                                    ? indianRupeeFormat(v.cr_val * 1)
                                                    : "—"
                                                  : "—"}
                                              </strong>
                                              <br />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="current val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.asset_units
                                                  ? v.asset_units != 0
                                                    ? Math.round(
                                                      (v.asset_units * 100) / 100
                                                    )
                                                    : "-"
                                                  : "-"}
                                              </strong>
                                              <br />
                                            </td>
                                            <td
                                              scope="row"
                                              data-label="current val"
                                              className=""
                                            >
                                              <strong className={`xrr-returns`}>
                                                {v?.cr_val
                                                  ? v.cr_val * 1 != 0 &&
                                                    v.cr_val != undefined
                                                    ? indianRupeeFormat(v.cr_val * 1)
                                                    : "—"
                                                  : "—"}
                                              </strong>
                                            </td>
                                            <td>
                                              <div className={`${style.actionBtnsContainer}`}>
                                                {getItemLocal("family") ? (
                                                  <i
                                                    className={`disabled fa-solid fa-pen-to-square ${style.trash}`}
                                                  ></i>
                                                ) : (
                                                  <Link
                                                    to={
                                                      process.env.PUBLIC_URL +
                                                      "/commondashboard/investment/new-us-equity-asset/?id=" +
                                                      v.id
                                                    }
                                                  >
                                                    <i
                                                      className={`fa-solid fa-pen-to-square ${style.trash}`}
                                                    ></i>
                                                  </Link>
                                                )}
                                                {getItemLocal("family") ? (
                                                  <i
                                                    class={`disabled fa fa-trash ${style.trash}`}
                                                    aria-hidden="true"
                                                  />
                                                ) : (
                                                  <i
                                                    class={`fa fa-trash ${style.trash}`}
                                                    onClick={() =>
                                                      deleteAsset(
                                                        v.id,
                                                        "usequity",
                                                        v.fp_log_id != null &&
                                                          v.fp_log_id
                                                          ? v.fp_log_id
                                                          : "",
                                                        "Are you sure you want to delete?"
                                                      )
                                                    }
                                                    aria-hidden="true"
                                                  />
                                                )}
                                              </div>

                                            </td>
                                          </tr>
                                        )
                                      )
                                    }
                                  </tbody>
                                </Table>
                              </div>
                            </>) : (
                            <>
                              <div className="table-responsive">
                                <br />
                                <p className="text-center fw-bold mb-0">
                                  Currently it seems like we don't have your
                                  US Equity data to display
                                </p>
                                <p className="text-center fw-bold mt-3">
                                  You can add your existing US Equity
                                </p>
                                <div className="text-center mt-4">
                                  {getItemLocal("family") ? (
                                    <Link
                                      className="disabled anchor-primary"
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/commondashboard/investment/new-us-equity-asset"
                                      }
                                    >
                                      Add US Equity
                                    </Link>
                                  ) : (
                                    <Link
                                      className="anchor-primary"
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/commondashboard/investment/new-us-equity-asset"
                                      }
                                    >
                                      Add US Equity
                                    </Link>
                                  )}
                                </div>
                                <div className="text-center">
                                  <img
                                    src={
                                      process.env.REACT_APP_STATIC_URL +
                                      "media/DMF/investment.png"
                                    }
                                    className="pt-4"
                                    alt={"Start Investing"}
                                    style={{ width: 400 }}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                        </>
                      </div>
                      <div
                        className={`insideTabContent px-2 px-md-4 ${
                          selectedTab == 12 ? "" : "d-none"
                        }`}
                      >
                        {"stocks_data" in otherInvestmentData &&
                          Boolean(
                            Object.keys(otherInvestmentData.stocks_data).length
                          ) && (
                            <>
                              <div className="row pb-3">
                                <div className="col-12 col-md-9">
                                  <div className="d-block">
                                    <div className="stocksTabInfo">
                                      <div>
                                        <div className={style.borderSpace}>
                                          Total Stocks
                                        </div>
                                        <div
                                          className={`borderSpace borderText pointer`}
                                        >
                                          {otherInvestmentData?.stocks_data
                                            ?.no_of_stocks != undefined
                                            ? otherInvestmentData.stocks_data
                                                .no_of_stocks
                                            : "-"}
                                        </div>
                                      </div>
                                      {/* <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Invested Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.stocks_data
                                            ?.total_purchase_rate
                                            ? indianRupeeFormat(
                                              otherInvestmentData.stocks_data
                                                .total_purchase_rate * 1
                                            )
                                            : "-"}
                                        </div>
                                      </div> */}
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Current Value
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                        >
                                          {otherInvestmentData?.stocks_data
                                            ?.total_current_rate
                                            ? indianRupeeFormat(
                                                otherInvestmentData.stocks_data
                                                  .total_current_rate * 1
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      {/* <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Today Gain/Loss
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                          style={
                                            otherInvestmentData?.stocks_data
                                              ?.overall_oneday_perc &&
                                              otherInvestmentData.stocks_data
                                                .overall_oneday_perc < 0
                                              ? { color: "red" }
                                              : { color: "green" }
                                          }
                                        >
                                          {otherInvestmentData?.stocks_data
                                            ?.overall_oneday_val
                                            ? indianRupeeFormat(
                                              otherInvestmentData?.stocks_data
                                                ?.overall_oneday_val * 1
                                            )
                                            : "-"}
                                          {otherInvestmentData?.stocks_data
                                            ?.overall_oneday_perc
                                            ? " (" +
                                            otherInvestmentData?.stocks_data
                                              ?.overall_oneday_perc +
                                            "%)"
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          className={`${style.extraSpace} ${style.borderSpace}`}
                                        >
                                          Total Gain/Loss
                                        </div>
                                        <div
                                          className={`borderSpace borderText`}
                                          style={
                                            otherInvestmentData?.stocks_data
                                              ?.total_purchase_rate &&
                                              otherInvestmentData?.stocks_data
                                                .total_current_rate &&
                                              otherInvestmentData.stocks_data
                                                .total_current_rate -
                                              otherInvestmentData.stocks_data
                                                .total_purchase_rate <
                                              0
                                              ? { color: "red" }
                                              : { color: "green" }
                                          }
                                        >
                                          {otherInvestmentData?.stocks_data
                                            ?.total_current_rate &&
                                            otherInvestmentData?.stocks_data
                                              .total_purchase_rate
                                            ? indianRupeeFormat(
                                              otherInvestmentData.stocks_data
                                                .total_current_rate *
                                              1 -
                                              otherInvestmentData
                                                .stocks_data
                                                .total_purchase_rate *
                                              1
                                            )
                                            : "-"}
                                          {otherInvestmentData?.stocks_data
                                            ?.overall_returns &&
                                            otherInvestmentData.stocks_data
                                              .overall_returns != ""
                                            ? " (" +
                                            otherInvestmentData.stocks_data
                                              .overall_returns +
                                            "%)"
                                            : "-"}
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`col-12 col-md-3 ${style.addBtnContainer} ${style.wrapBtns} `}
                                >
                                  <div
                                    className={`${style.addBtn}`}
                                    style={{ marginTop: "0px" }}
                                  >
                                    <div>
                                      <div
                                        style={{ marginBottom: "0.5rem" }}
                                        className="resultOptionsBtn position-relative hover-dropdown pointer"
                                        onClick={() =>
                                          setIsStocksFilterPanelActive(
                                            (prev) => !prev
                                          )
                                        }
                                      >
                                        <HiSortAscending fontSize={"1.2rem"} />
                                        <span>Sort & Filter</span>
                                      </div>
                                      {resetStocksFilterTriggerState.filtersActive && (
                                        <div
                                          className="resultOptionsBtn position-relative hover-dropdown pointer"
                                          onClick={() =>
                                            setResetStocksFilterTriggerState(
                                              () => ({
                                                triggerReset: true,
                                                showResetTriggerUi: false,
                                                filtersActive: false,
                                              })
                                            )
                                          }
                                        >
                                          <span>Reset all Filters</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="fixedHeaders">
                                <div className="table-responsive">
                                  <div className="ptTableBx">
                                    <Table
                                      className={`ptTable ${style.stockTbl} ${style["bold-table"]} mb-0 ${style.headerTable}`}
                                    >
                                      <thead>
                                        <tr>
                                          <td scope="col">Stock Name</td>
                                          <td scope="col">No. of Shares</td>
                                          <td scope="col">Current Value</td>
                                          {/* <td scope="col">Invested Value</td>
                                          <td scope="col">Current Value</td>                                          
                                          <td scope="col">Today Returns</td>
                                          <td scope="col">Total Returns</td> */}
                                          <td scope="col">&nbsp;</td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        <div className="">
                          <Table
                            responsive
                            className={`mb-0 ptTable fixedTable ${style.stockTbl} ${style.dataTable}`}
                          >
                            <tbody>
                              {(
                                otherInvestmentData?.stocks_data
                                  ?.stocks_details ?? []
                              ).length > 0 ? (
                                otherInvestmentData.stocks_data.stocks_details.map(
                                  (v) => (
                                    <tr key={v.id}>
                                      <td scope="row" data-label="title">
                                        <AssetName
                                          title={
                                            <>
                                              <p className="mb-0">
                                                <strong>
                                                  {v.asset_name
                                                    ? v.asset_name
                                                    : "-"}
                                                </strong>
                                              </p>
                                              <p className="mb-0">
                                                {v.sector_name != undefined &&
                                                v.industry_name != undefined
                                                  ? v.sector_name +
                                                    " - " +
                                                    v.industry_name +
                                                    " " +
                                                    " | "
                                                  : " — | "}

                                                {v.prev_day_val != undefined &&
                                                v.prev_day_val != 0 &&
                                                v.prev_day_val != ""
                                                  ? indianRupeeFormat(
                                                      v.prev_day_val * 1
                                                    )
                                                  : "—"}
                                              </p>
                                            </>
                                          }
                                        />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="current val"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v?.asset_units
                                            ? v.asset_units != 0
                                              ? Math.round(
                                                  (v.asset_units * 100) / 100
                                                )
                                              : "-"
                                            : "-"}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="current val"
                                        className=""
                                      >
                                        <strong className={`xrr-returns`}>
                                          {v?.cr_val
                                            ? v.cr_val * 1 != 0 &&
                                              v.cr_val != undefined
                                              ? indianRupeeFormat(v.cr_val * 1)
                                              : "—"
                                            : "—"}
                                        </strong>
                                      </td>

                                      {/* <td scope="row" data-label="invested val">
                                        <strong>
                                          {v?.inv_val
                                            ? v.inv_val != 0
                                              ? indianRupeeFormat(v.inv_val * 1)
                                              : "-"
                                            : "-"}
                                        </strong>
                                        <br />
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="returns"
                                        className=""
                                      >
                                        <strong
                                          className={`xrr-returns ${v?.day_change_perc
                                            ? v?.day_change_perc * 1 < 0
                                              ? "red"
                                              : v.day_change_perc * 1 === 0 || v.day_change_perc === undefined
                                                ? ""
                                                : "green"
                                            : ""
                                            }`}
                                        >
                                          {v?.today_rtn
                                            ? v.today_rtn != 0
                                              ? indianRupeeFormat(v.today_rtn)
                                              : "-"
                                            : "—"}
                                          {v?.day_change_perc
                                            ? " (" + v.day_change_perc + "%) "
                                            : ""}
                                        </strong>
                                      </td>
                                      <td
                                        scope="row"
                                        data-label="abs return"
                                        className=""
                                      >
                                        <strong
                                          className={`xrr-returns ${v?.asset_name.toLowerCase().includes("BEES") && v.gain_perc == -100 ||
                                            (v.cr_val == null || v.inv_val == null) ||
                                            (v.cr_val * 1 === 0)
                                            ? ""
                                            : v.cr_val * 1 - v.inv_val * 1 > 0
                                              ? "green"
                                              : v.cr_val * 1 - v.inv_val * 1 < 0
                                                ? "red"
                                                : ""
                                            }`}
                                        >
                                          {v?.cr_val && v?.inv_val
                                            ? v.cr_val != 0
                                              ? indianRupeeFormat(
                                                v.cr_val - v.inv_val
                                              )
                                              : "—"
                                            : "—"}
                                        </strong>
                                        <strong
                                          className={`xrr-returns ${v?.asset_name.toLowerCase().includes("BEES") && v.gain_perc == -100 ||
                                            (v.cr_val == null || v.inv_val == null) ||
                                            (v.cr_val * 1 === 0)
                                            ? ""
                                            : v.cr_val * 1 - v.inv_val * 1 > 0
                                              ? "green"
                                              : v.cr_val * 1 - v.inv_val * 1 < 0
                                                ? "red"
                                                : ""
                                            }`}
                                        >
                                          {v?.gain_perc != -100
                                            ? " (" + v.gain_perc + "%) "
                                            : ""}
                                        </strong>
                                      </td> */}
                                      <td>
                                        {getItemLocal("family") ? (
                                          <i
                                            class={`disabled fa fa-trash ${style.trash}`}
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <i
                                            class={`fa fa-trash ${style.trash}`}
                                            onClick={() =>
                                              deleteFdBond(
                                                v.id,
                                                "stocks",
                                                v.fp_log_id ? v.fp_log_id : "",
                                                "Are you sure you want to delete?"
                                              )
                                            }
                                            aria-hidden="true"
                                          />
                                        )}
                                        {/*  onClick={deleteAsset()}  */}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <>
                                  {
                                    <div>
                                      {resetStocksFilterTriggerState.showResetTriggerUi ? (
                                        <span>
                                          <h1 className="startInvesting-header">
                                            No funds found!
                                          </h1>
                                          <button
                                            className="startInvesting pointer mt-4 Reset"
                                            type="button"
                                            onClick={() =>
                                              setResetStocksFilterTriggerState(
                                                () => ({
                                                  triggerReset: true,
                                                  showResetTriggerUi: false,
                                                })
                                              )
                                            }
                                          >
                                            <strong>Reset all filters </strong>
                                          </button>
                                        </span>
                                      ) : (
                                        <div>
                                          <br />
                                          <p className="text-center fw-bold mb-0">
                                            Currently it seems you don't have
                                            any Holdings to display your Stocks
                                            Portfolio
                                          </p>
                                          <br />
                                        </div>
                                      )}
                                      <div className="text-center">
                                        <img
                                          src={getPublicMediaURL(
                                            "static/media/DMF/investment.png"
                                          )}
                                          className="pt-4"
                                          alt={"Start Investing"}
                                          style={{ width: 300 }}
                                        />
                                      </div>
                                    </div>
                                  }
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </>
      )}
      <MfFilterSidePanel
        isOpen={isFilterPanelActive}
        togglePanel={setIsFilterPanelActive}
        mainData={mainData}
        setMainData={setMainData}
        mfListDataCopy={mfListDataCopy}
        fetchFundsData={fetchFundsData}
        resetFilterTriggerState={resetFilterTriggerState}
        setResetFilterTriggerState={setResetFilterTriggerState}
      />
      <StocksFilterSidePanel
        isOpen={isStocksFilterPanelActive}
        togglePanel={setIsStocksFilterPanelActive}
        mainData={otherInvestmentData}
        setMainData={setOtherInvestmentData}
        stockListDataCopy={stocksListCopy}
        fetchFundsData={fetchFundsData}
        resetFilterTriggerState={resetStocksFilterTriggerState}
        setResetFilterTriggerState={setResetStocksFilterTriggerState}
      />
      <SelectMemberModal
        isOpen={isOpenPopup}
        onClose={() => {
          setIsOpenPopup(false);
        }}
      />
    </PortfolioLayout>
  );
};

const BulletPoint = ({ heading, text }) => {
  return (
    <div className="d-flex py-3">
      <img
        className={style["bullet-item-img"]}
        src={getPublicMediaURL("/static/media/icons/check_01.png")}
      />
      <div className={`ps-2`}>
        <div className={style["bullet-item-heading"]}>{heading}</div>
        <div>{text}</div>
      </div>
    </div>
  );
};

const ActionButton = ({ onClick, label, disabled = false }) => {
  return (
    <button
      className={style["bullet-item-ActionButton"]}
      disabled={disabled ? "disabled" : ""}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
export default PortfolioDashboard;
