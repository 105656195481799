import { useState } from "react";
import { useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import PieChartOutflow from "../../Assets/Datagathering/Graph/PieChartOutflow";
import PieChartInflow from "../../Assets/Datagathering/Graph/PieChartInflow";
import TotalSavings from "../../Assets/Datagathering/Graph/TotalSavings";
import TotalSavingss from "../../Assets/Datagathering/Graph/TotalSavingss";
import TotalInvestable from "../../Assets/Datagathering/Graph/TotalInvestable";
import TotalInvestablee from "../../Assets/Datagathering/Graph/TotalInvestablee";
import Scorecard from "../../Assets/Datagathering/Graph/Scorecard";
import DatagatherReportLayout from "../../components/Layout/Datagather/Reports";
import { BASE_API_URL, imagePath } from "../../constants";
import {
  CHECK_SESSION,
  ADVISORY_YOUR_PROFILE_API_URL,
  ADVISORY_RISK_APPETITE_API_URL,
  ADVISORY_GET_SCORECARD_API_URL,
  ADVISORY_GET_SURPLUS_DATA_API,
  ADVISORY_GET_RP_INOUTFLOW_DATA_API,
} from "../../constants";
import {
  getUserId,
  getItemLocal,
  apiCall,
  loginRedirectGuest,
  getParentUserId,
  rsFilter,
  makePositive,
  formatPrice,
  indianRupeeFormat,
  formatNegative,
  setBackgroundDivImage,
} from "../../common_utilities";
import commonEncode from "../../commonEncode";
import { Link } from "react-router-dom";
import PieChart from "../../Assets/Datagathering/Graph/PieChartInflow.js";
// import Spouse from "../../Assets/Datagathering/Spouse.png";
import Daughter from "../../Assets/Datagathering/FamilyIcons/daughter.svg";
import Son from "../../Assets/Datagathering/FamilyIcons/son.svg";
import Spouse from "../../Assets/Datagathering/FamilyIcons/spouse.svg";
import Mother from "../../Assets/Datagathering/FamilyIcons/mother.svg";
import Father from "../../Assets/Datagathering/FamilyIcons/father.svg";
import Dependent from "../../Assets/Datagathering/FamilyIcons/dependent.svg";
import HUF from "../../Assets/Datagathering/FamilyIcons/HUF.svg";

import FintooLoader from "../../components/FintooLoader";

const YourProfile = () => {
  const [tab, setTab] = useState("tab1");
  const [tab123, setTab123] = useState("tab5");
  const [tab1232, setTab1232] = useState("tab7");
  const [dependencyStatus, setDependencyStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [dob, setDob] = useState(null);
  const handleTrigger = () => setIsOpen(!isOpen);
  const [user_details, setUserDetails] = useState({});
  const [session, setSession] = useState("");
  const [screenMessage, setScreenMessage] = useState("");
  const [earning_members, setEarningMembers] = useState([]);
  const [dependent_members, setDependentMembers] = useState([]);
  const [riskProfile, setRiskProfile] = useState("");
  const [riskProfileDesc, setRiskProfileDesc] = useState("");
  const [riskProfileImage, setRiskProfileImage] = useState("");
  const [riskIndicator, setRiskIndicator] = useState("");
  const [memberData, setMemberData] = useState([]);

  const [riskPyramid, setRiskPyramid] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [aboutScreenMsg, setAboutScreenMsg] = useState("");
  const [scoreScreenMsg, setScoreScreenMsg] = useState("");
  const [savingMarks, setSavingMarks] = useState("");
  const [savingRatio, setSavingRatio] = useState("");
  const [savingData, setSavingData] = useState("");
  const [incomeExpenseData, setIncomeExpenseData] = useState([]);
  const [netLiquidData, setNetLiquidData] = useState([]);
  const [solvencyData, setSolvencyData] = useState([]);
  const [scoreData, setScoreData] = useState([]);
  const [scoreNameColor, setScoreNameColor] = useState("");

  const [totalSavings, setTotalSavings] = useState({});
  const [totalGrossInflowData, setTotalGrossInflowData] = useState([]);
  const [totalGrossOutflowData, setTotalGrossOutflowData] = useState([]);
  const [totalSavingData, setTotalSavingData] = useState([]);
  const [surplusShortfallData, setSurplusShortfallData] = useState([]);
  const [investmentInsuranceData, setInvestmentInsuranceData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [rpDataIncome, setRpdataIncomeData] = useState([]);
  const [interestIncome, setInterestIncome] = useState([]);
  const [rpDataIncomeTotal, setRpdataIncomeDataTotal] = useState([]);
  const [mandatoryFixedData, setMandatoryFixedData] = useState([]);
  const [mandatoryVariableData, setMandatoryVariableData] = useState([]);
  const [wishfulFixedData, setWishfulFixedData] = useState([]);
  const [wishfulVariableData, setWishfulVariableData] = useState([]);
  const [savingPercent, setSavingPercent] = useState("");
  const [grossOutflowTotal, setGrossOutflowTotal] = useState([]);
  const [grossInflowGraphData, setGrossInflowGraphData] = useState([]);
  const [grossOutflowGraphData, setGrossOutflowGraphData] = useState([]);
  const [currentTotalSavings, setCurrentTotalSavings] = useState([]);
  const [nextYearTotalSavings, setNextYearTotalSavings] = useState([]);

  const [currentTotalInvestable, setCurrentTotalInvestable] = useState([]);
  const [nextYearTotalInvestable, setNextYearTotalInvestable] = useState([]);
  const [inoutSectionData, setInoutSectionData] = useState("");
  const [outflowSectionData, setOutflowSectionData] = useState("");
  const [fintooNotes, setFintooNotes] = useState("");
  const [fintooNotes1, setFintooNotes1] = useState("");
  const [pSurplusSectionData, setPSurplusSectionData] = useState([]);

  // const check_session = async () => {
  //   let url = CHECK_SESSION;
  //   let data = { user_id: getUserId(), sky: getItemLocal("sky") };
  //   let session_data = await apiCall(url, data, true, false);
  // const check_session = async () => {
  //   let url = CHECK_SESSION;
  //   let data = { user_id: getUserId(), sky: getItemLocal("sky") };
  //   let session_data = await apiCall(url, data, true, false);

  //   setSession(session_data);

  // } ;

  useEffect(() => {
    document.body.classList.add("dg-layout");
    document.body.classList.add("rp-layout");
    document.getElementById("report-bg-profile").style.background =
      "url(" +
      imagePath +
      "https://static.fintoo.in/static/assets/img/reports/ill-your-profile.svg)" +
      " no-repeat right top";
    // check_session();
    getYourProfileDetails();

    setBackgroundDivImage();

    return () => {
      document.body.classList.remove("dg-layout");
      document.body.classList.remove("rp-layout");
    };
  }, []);

  const getYourProfileDetails = async () => {
    getProfileData();
    getRiskAppetiteData();

    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);
      if (session_data.error_code == "102") {
        loginRedirectGuest();
      } else {
        getScoreCardData(session_data);
        getsurplusdata(session_data);
        getrpinoutflow(session_data);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const formatLocPrice = (value) => {
    var isNeg = 0;

    if (value < 0) {
      isNeg = 1;
    }

    var price = "";
    var v = Math.abs(value);

    if (v) {
      price = formatPrice(v);
      price = price.replace(/.00/, "");
      let new_p = "";
      if (isNeg) {
        new_p = formatNegative(price);
      } else {
        new_p = price;
      }
      let amt = new_p.replace("₹ ", "₹");
      // let new_val = "";

      // switch (true) {
      //     case amt.indexOf("Cr.") > -1:
      //           new_val = amt.replace("Cr.", " Cr");
      //     case amt.indexOf("L") > -1:
      //           new_val = amt.replace("L", " L");
      //     case amt.indexOf("K") > -1:
      //           new_val = amt.replace("K", " K");
      //     default:
      //           new_val = amt
      // }

      // let amt = price_str.replace(/L/g, " Lac").replace(/.00/, "")
      //          .replace("K", " K").replace("Cr.", " Cr").replace("₹ ", "₹");

      let new_val = amt
        .replace("K", " K")
        .replace("Cr.", " Cr")
        .replace("L", " Lac");
      return new_val;
    } else {
      let amt = formatPrice(v).replace(/.00/, "").replace("₹ ", "₹");
      return amt;
    }

    // let price_str = formatPrice(val);
    // let new_price = price_str.replace(/L/g, " Lac").replace(/.00/, "")
    //              .replace("K", " K").replace("Cr", " Cr").replace(" ", "");
    // return new_price;
  };

  const formatNegative = (v) => {
    v = v.replace("₹ ", "₹ (");
    switch (true) {
      case v.indexOf("Cr.") > -1:
        return v.replace("Cr.", ")Cr.");
      case v.indexOf("L") > -1:
        return v.replace("L", ")L");
      case v.indexOf("K") > -1:
        return v.replace("K", ")K");
      default:
        return v + ")";
    }
  };

  const remove_tag = (text) => {
    return text.replace(/<\/?p[^>]*>/g, "");
  };

  const getProfileData = async () => {
    let url = CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
    let session_data = await apiCall(url, data, true, false);
    if (session_data.error_code == "100") {
      try {
        let api_data = {
          user_id: session_data["data"]["id"],
          fp_log_id: session_data["data"]["fp_log_id"],
        };
        let response = await apiCall(
          ADVISORY_YOUR_PROFILE_API_URL,
          api_data,
          true,
          false
        );

        if (response.error_code == "100") {
          setIsLoading(false);

          let res_user_data = session_data["data"];
          let member_data = response.data["rpdata_member"];
          setMemberData(member_data);

          var family_members_count = member_data.length;
          var dependentmember_count = 0;

          if (member_data.length) {
            member_data.forEach((member, index) => {
              if (member.isdependent == "1") {
                dependentmember_count = dependentmember_count + 1;
              }
            });
          }

          let user_data = {
            first_name: res_user_data["user_details"]["first_name"],
            last_name: res_user_data["user_details"]["last_name"],
            pan_number: res_user_data["user_details"]["PAN"],
            age: response.data["rpdata_self"][0]["age"],
            mobile_number:
              "+" +
              res_user_data.user_details["country_code"] +
              " " +
              res_user_data.user_details["mobile"],
            family_member: family_members_count,
            retirement_age: res_user_data["user_details"]["retirement_age"],
            email_address: res_user_data["user_details"]["email"],
            num_of_dependents: dependentmember_count,
            life_expectancy: res_user_data["user_details"]["life_expectancy"],
          };

          setUserDetails(user_data);

          let msg = response.data["rpdata_screendata"]["field0"];

          let new_msg = remove_tag(msg);

          setAboutScreenMsg(new_msg);

          // setTimeout(() => {
          //   document.getElementById("about-you-screen-msg").innerHTML = about_screen_msg

          // }, 1000);

          // document.getElementById("about-you-screen-msg").innerHTML =
          //   response.data["rpdata_screendata"]["field0"];

          getFamilyData(member_data);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.log("Error: ", e);
      }
    } else {
      loginRedirectGuest();
    }
  };

  const getFamilyData = (member_data) => {
    let earning_member_tmp = earning_members;
    let dependent_members_tmp = dependent_members;

    member_data.forEach((member, index) => {
      if (member.isdependent == "1") {
        dependent_members_tmp.push(member);
      } else {
        earning_member_tmp.push(member);
      }
    });

    setEarningMembers(earning_member_tmp);
    setDependentMembers(dependent_members_tmp);
  };

  const getRiskAppetiteData = async () => {
    // setTab(newTab);
    let url = CHECK_SESSION;
    let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
    let session_data = await apiCall(url, data, true, false);

    if (session_data.error_code == "100") {
      try {
        let api_data = {
          user_id: session_data["data"]["id"],
          fp_log_id: session_data["data"]["fp_log_id"],
        };

        var payload_data = commonEncode.encrypt(JSON.stringify(api_data));

        let api_res = await apiCall(
          ADVISORY_RISK_APPETITE_API_URL,
          payload_data,
          false,
          false
        );

        let decoded_res = JSON.parse(commonEncode.decrypt(api_res));

        if (decoded_res.error_code == "100") {
          let res_data = decoded_res["data"];
          let risk_profile = res_data[0]["field2"];
          setRiskProfile(risk_profile);
          let risk_indicator_tmp = {};

          let profile_desc = res_data[0]["field3"];
          let new_profile_desc = remove_tag(profile_desc);
          setRiskProfileDesc(new_profile_desc);

          if (res_data[0]["gender"] == "male") {
            setRiskProfileImage(res_data[0]["field4"]);
          } else if (res_data[0]["gender"] == "female") {
            setRiskProfileImage(res_data[0]["field5"]);
          } else if (res_data[0]["gender"] == "other") {
            setRiskProfileImage(res_data[0]["field4"]);
          }

          if (risk_profile == "Highly Conservative") {
            setRiskIndicator("7%");

            setRiskPyramid(
              "assets/img/reports/your-profile/05_pyramid_Highly_Conservative.png"
            );
          } else if (risk_profile == "Conservative") {
            setRiskIndicator("27%");

            setRiskPyramid(
              "assets/img/reports/your-profile/04_pyramid_Conservative.png"
            );
          } else if (risk_profile == "Moderate") {
            setRiskIndicator("47%");

            setRiskPyramid(
              "assets/img/reports/your-profile/03_pyramid_Moderate.png"
            );
          } else if (risk_profile == "Aggressive") {
            setRiskIndicator("67%");

            setRiskPyramid(
              "assets/img/reports/your-profile/02_pyramid_Aggressive.png"
            );
          } else if (risk_profile == "Highly Aggressive") {
            setRiskIndicator("87%");

            setRiskPyramid(
              "assets/img/reports/your-profile/01_pyramid_Highly_Aggressive.png"
            );
          }
        }
      } catch (e) {
        console.log("Error: ", e);
      }
    } else {
      loginRedirectGuest();
    }
  };
  const currentYear = new Date().getFullYear();

  const getScoreCardData = async (session_data) => {
    // setTab(newTab);

    try {
      let api_data = {
        user_id: session_data["data"]["id"],
        fp_log_id: session_data["data"]["fp_log_id"],
        fp_user_id: session_data["data"]["fp_user_id"],
      };

      var payload_data = commonEncode.encrypt(JSON.stringify(api_data));

      let api_res = await apiCall(
        ADVISORY_GET_SCORECARD_API_URL,
        payload_data,
        false,
        false
      );

      let decoded_res = JSON.parse(commonEncode.decrypt(api_res));
      if (decoded_res.error_code == "100") {
        let temp_income_expense = {
          saving_ratio: decoded_res["data"]["ratio"]["saving_ratio"],
          saving_marks: decoded_res["data"]["marks"]["saving_marks"],
          saving_data: decoded_res["data"]["marks"]["saving_data"],
          ideal_saving_ratio: decoded_res["data"]["ideal_saving_ratio"],

          expense_ratio: decoded_res["data"]["ratio"]["expense_ratio"],
          expense_marks: decoded_res["data"]["marks"]["expense_mark"],
          expense_data: decoded_res["data"]["marks"]["expense_data"],
          ideal_expense_ratio: decoded_res["data"]["ideal_expense_ratio"],

          annual_income: decoded_res["data"]["annual_income"],
          annual_expense: decoded_res["data"]["annual_expense"],
        };

        setIncomeExpenseData(temp_income_expense);

        let temp_net_liquid_data = {
          networth_mark: decoded_res["data"]["marks"]["networth_mark"],
          networth_data: decoded_res["data"]["marks"]["networth_data"],
          networth_ratio: decoded_res["data"]["ratio"]["networth_ratio"],
          ideal_networth: decoded_res["data"]["ideal_networth"],

          total_asset: decoded_res["data"]["total_asset"],
          total_liability: decoded_res["data"]["total_liability"],
          total_liquid: decoded_res["data"]["total_liquid"],
          monthly_expense: decoded_res["data"]["monthly_expense"],

          liquidity_ratio: decoded_res["data"]["ratio"]["liquidity_ratio"],
          liquidity_mark: decoded_res["data"]["marks"]["liquidity_mark"],
          liquidity_data: decoded_res["data"]["marks"]["liquidity_data"],
          ideal_liquid_months: decoded_res["data"]["ideal_liquid_months"],
        };
        setNetLiquidData(temp_net_liquid_data);

        setScoreScreenMsg(decoded_res["data"]["screen_header"]);
        // let score_data = decoded_res["data"]

        let saving_ratio = decoded_res["data"]["ratio"]["saving_ratio"];
        let saving_marks = decoded_res["data"]["marks"]["saving_marks"];
        setSavingRatio(saving_ratio);
        setSavingMarks(saving_marks);
        setSavingData(decoded_res["data"]["marks"]["saving_data"]);

        let tmp_solvency_data = {
          solvency_mark: decoded_res["data"]["marks"]["solvency_mark"],
          solvency_data: decoded_res["data"]["marks"]["solvency_data"],
          solvency_ratio: decoded_res["data"]["ratio"]["solvency_ratio"],
          ideal_solvency_ratio:
            100 - decoded_res["data"]["ratio"]["solvency_ratio"],
        };
        setSolvencyData(tmp_solvency_data);

        let tmp_score_data = {
          total_score: decoded_res["data"]["total_score"],
          total_score_name: decoded_res["data"]["total_score_name"],
          fintoo_saving_recomm: decoded_res["data"]["fintoo_saving_recomm"],
          fintoo_expense_recomm: decoded_res["data"]["fintoo_expense_recomm"],
          fintoo_liquid_recomm: decoded_res["data"]["fintoo_liquid_recomm"],
          fintoo_networth_recomm: decoded_res["data"]["fintoo_networth_recomm"],
          fintoo_solvency_recomm: decoded_res["data"]["fintoo_solvency_recomm"],
          total_score_desc: decoded_res["data"]["total_score_desc"],
        };

        let diff =
          temp_income_expense.ideal_saving_ratio -
          temp_income_expense.saving_ratio;
        let diff_exp =
          temp_income_expense.ideal_expense_ratio -
          temp_income_expense.expense_ratio;

        let income_current_ratio =
          temp_income_expense.saving_ratio >= 0
            ? temp_income_expense.saving_ratio
            : "(" +
              makePositive(
                temp_income_expense.saving_ratio
                  ? temp_income_expense.saving_ratio
                  : ""
              ) +
              ")";
        let income_diff_ratio =
          diff >= 0 ? diff : "(" + makePositive(diff) + ")";
        let expense_current_ratio =
          temp_income_expense.expense_ratio >= 0
            ? temp_income_expense.expense_ratio
            : "(" +
              makePositive(
                temp_income_expense.expense_ratio
                  ? temp_income_expense.expense_ratio
                  : ""
              ) +
              ")";
        var exp_diff_ratio =
          diff_exp >= 0 ? diff_exp : "(" + makePositive(diff_exp) + ")";

        tmp_score_data.fintoo_saving_recomm =
          tmp_score_data.fintoo_saving_recomm.replace(
            "current_ratio",
            income_current_ratio
          );
        tmp_score_data.fintoo_saving_recomm =
          tmp_score_data.fintoo_saving_recomm.replace(
            "ideal_ratio",
            temp_income_expense.ideal_saving_ratio
          );
        tmp_score_data.fintoo_saving_recomm =
          tmp_score_data.fintoo_saving_recomm.replace(
            "diff_ratio",
            income_diff_ratio
          );

        tmp_score_data.fintoo_expense_recomm =
          tmp_score_data.fintoo_expense_recomm.replace(
            "current_ratio",
            expense_current_ratio
          );
        tmp_score_data.fintoo_expense_recomm =
          tmp_score_data.fintoo_expense_recomm.replace(
            "ideal_ratio",
            temp_income_expense.ideal_expense_ratio
          );
        tmp_score_data.fintoo_expense_recomm =
          tmp_score_data.fintoo_expense_recomm.replace(
            /diff_ratio/g,
            exp_diff_ratio
          );

        tmp_score_data.fintoo_solvency_recomm =
          tmp_score_data.fintoo_solvency_recomm.replace(
            "solvency_ratio",
            tmp_solvency_data.ideal_solvency_ratio
          );

        if (decoded_res.data["total_score"] <= 20) {
          tmp_score_data.gauge_needle = "10";
        } else if (
          decoded_res.data["total_score"] > 20 &&
          decoded_res.data["total_score"] <= 40
        ) {
          tmp_score_data.gauge_needle = "30";
        } else if (
          decoded_res.data["total_score"] > 40 &&
          decoded_res.data["total_score"] <= 60
        ) {
          tmp_score_data.gauge_needle = "50";
        } else if (
          decoded_res.data["total_score"] > 60 &&
          decoded_res.data["total_score"] <= 80
        ) {
          tmp_score_data.gauge_needle = "70";
        } else if (decoded_res.data["total_score"] > 80) {
          tmp_score_data.gauge_needle = "90";
        }

        setScoreData(tmp_score_data);

        let score_color_dict = {
          "Not Good": "#f9411f",
          Average: "#f88221",
          Good: "#e1b624",
          "Very Good": "#4faa36",
          Excellent: "#588036",
        };

        setScoreNameColor(
          score_color_dict[decoded_res["data"]["total_score_name"]]
        );

        // let tmp_saving = formatPrice(temp_income_expense.annual_income-temp_income_expense.annual_expense);
        // let format_saving = "";
        // if(tmp_saving < 0){
        //     format_saving = formatNegative();
        // }
      } else {
        // if not found result
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const getsurplusdata = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
      fp_user_id: session_data["data"]["fp_user_id"],
    };
    var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
    var res = await apiCall(
      ADVISORY_GET_SURPLUS_DATA_API,
      payload_data,
      false,
      false
    );
    let decoded_res = JSON.parse(commonEncode.decrypt(res));
    if (decoded_res["error_code"] == "100") {
      setPSurplusSectionData(decoded_res.data.section_text);
      setTotalSavingData(decoded_res["data"]["total_savings"]);
      setTotalGrossInflowData(
        decoded_res["data"]["total_savings"]["total_gross_inflow"]
      );
      setTotalGrossOutflowData(
        decoded_res["data"]["total_savings"]["total_gross_outflow"]
      );
      setTotalSavingData(decoded_res["data"]["total_savings"]["total_saving"]);
      setSurplusShortfallData(decoded_res["data"]["surplus_shortfall"]);
      setInvestmentInsuranceData(
        decoded_res["data"]["investment_insurance"][0]
      );
      setInsuranceData(
        decoded_res["data"]["investment_insurance"][0]["insurance"]
      );
      setAssetData(decoded_res["data"]["investment_insurance"][0]["assets"]);

      let type;

      if (decoded_res["data"]["total_savings"]["total_saving"]["annual"] < 0) {
        type = "Deficit";
      } else {
        type = "Savings";
      }

      if (
        decoded_res["data"]["total_savings"]["total_saving"]["nextyr_saving"] <
        0
      ) {
        type = "Deficit";
      } else {
        type = "Savings";
      }

      var total_saving_current_year = [];
      var setgraphdata = [];
      if (decoded_res) {
        setgraphdata = [
          {
            name: "Gross Inflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_inflow"][
                "total_annual_income"
              ],
          },
          {
            name: "Gross Outflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_outflow"][
                "total_annual_expense"
              ],
          },
          {
            name: type,
            total:
              decoded_res["data"]["total_savings"]["total_saving"]["annual"],
          },
        ];
      } else {
        setgraphdata = [
          {
            name: "Gross Inflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_inflow"][
                "total_annual_income"
              ],
          },
          {
            name: "Gross Outflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_outflow"][
                "total_annual_expense"
              ],
          },
          {
            name: type,
            total:
              decoded_res["data"]["total_savings"]["total_saving"]["annual"],
          },
        ];
      }
      setgraphdata.map((val) => {
        if (val["total"]) {
          total_saving_current_year.push({
            name: val["name"],
            y: val["total"],
          });
        }
      });
      setCurrentTotalSavings(total_saving_current_year);

      var total_saving_next_year = [];
      var setgraphdata = [];
      if (decoded_res) {
        setgraphdata = [
          {
            name: "Gross Inflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_inflow"][
                "total_annual_next_year_income"
              ],
          },
          {
            name: "Gross Outflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_outflow"][
                "total_nextyr_expense"
              ],
          },
          {
            name: type,
            total:
              decoded_res["data"]["total_savings"]["total_saving"][
                "nextyr_saving"
              ],
          },
        ];
      } else {
        setgraphdata = [
          {
            name: "Gross Inflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_inflow"][
                "total_annual_next_year_income"
              ],
          },
          {
            name: "Gross Outflow",
            total:
              decoded_res["data"]["total_savings"]["total_gross_outflow"][
                "total_nextyr_expense"
              ],
          },
          {
            name: type,
            total:
              decoded_res["data"]["total_savings"]["total_saving"][
                "nextyr_saving"
              ],
          },
        ];
      }
      setgraphdata.map((val) => {
        if (val["total"]) {
          total_saving_next_year.push({ name: val["name"], y: val["total"] });
        }
      });
      setNextYearTotalSavings(total_saving_next_year);

      if (decoded_res["data"]["total_savings"]["total_saving"]["annual"] < 0) {
        type = "Total Investable Shortfall";
      } else {
        type = "Total Investable Surplus";
      }

      var total_investable_current_year = [];
      var setgraphdata = [];
      if (decoded_res) {
        setgraphdata = [
          {
            name: "Total Savings",
            total:
              decoded_res["data"]["total_savings"]["total_saving"][
                "nextyr_saving"
              ],
          },
          {
            name: "Existing Insurance & Investment Commitment",
            total:
              decoded_res["data"]["investment_insurance"][0]["total_nxtyearly"],
          },
          {
            name: type,
            total: decoded_res["data"]["surplus_shortfall"]["next_yearly"],
          },
        ];
      } else {
        setgraphdata = [
          {
            name: "Total Savings",
            total:
              decoded_res["data"]["total_savings"]["total_saving"][
                "nextyr_saving"
              ],
          },
          {
            name: "Existing Insurance & Investment Commitment",
            total:
              decoded_res["data"]["investment_insurance"][0]["total_nxtyearly"],
          },
          {
            name: type,
            total: decoded_res["data"]["surplus_shortfall"]["next_yearly"],
          },
        ];
      }
      setgraphdata.map((val) => {
        if (val["total"]) {
          total_investable_current_year.push({
            name: val["name"],
            y: val["total"],
          });
        }
      });
      setNextYearTotalInvestable(total_investable_current_year);

      var total_investable_next_year = [];
      var setgraphdata = [];
      if (decoded_res) {
        setgraphdata = [
          {
            name: "Total Savings",
            total:
              decoded_res["data"]["total_savings"]["total_saving"]["annual"],
          },
          {
            name: "Existing Insurance & Investment Commitment",
            total:
              decoded_res["data"]["investment_insurance"][0]["total_yearly"],
          },
          {
            name: type,
            total: decoded_res["data"]["surplus_shortfall"]["annual"],
          },
        ];
      } else {
        setgraphdata = [
          {
            name: "Total Savings",
            total:
              decoded_res["data"]["total_savings"]["total_saving"]["annual"],
          },
          {
            name: "Existing Insurance & Investment Commitment",
            total:
              decoded_res["data"]["investment_insurance"][0]["total_yearly"],
          },
          {
            name: type,
            total: decoded_res["data"]["surplus_shortfall"]["annual"],
          },
        ];
      }
      setgraphdata.map((val) => {
        if (val["total"]) {
          total_investable_next_year.push({
            name: val["name"],
            y: val["total"],
          });
        }
      });
      setCurrentTotalInvestable(total_investable_next_year);
    }
  };

  const getrpinoutflow = async (session_data) => {
    let api_data = {
      user_id: session_data["data"]["id"],
      fp_log_id: session_data["data"]["fp_log_id"],
      fp_user_id: session_data["data"]["fp_user_id"],
    };
    var payload_data = commonEncode.encrypt(JSON.stringify(api_data));
    var res = await apiCall(
      ADVISORY_GET_RP_INOUTFLOW_DATA_API,
      payload_data,
      false,
      false
    );
    let decoded_res = JSON.parse(commonEncode.decrypt(res));
    if (decoded_res["error_code"] == "100") {
      setInoutSectionData(decoded_res.data[3].section_text["field0"]);
      setOutflowSectionData(decoded_res.data[3].section_text["field1"]);
      setFintooNotes(decoded_res.data[3].section_text["field5"]);
      if (decoded_res.data[3].section_text["fintoo_recomm_text"]) {
        setFintooNotes1(
          decoded_res.data[3].section_text["fintoo_recomm_text"]["grossinout"]
        );
      } else {
        setFintooNotes1("");
      }

      const filteredArray = decoded_res["data"][0]["rpdata_income"][
        "data"
      ].filter((v) => v.annual_income + v.annual_next_year_income > 0);
      setRpdataIncomeData(filteredArray);

      setInterestIncome(
        decoded_res["data"][0]["rpdata_income"]["interest_data"]
      );
      // setRpdataIncomeData(decoded_res['data'][0]['rpdata_income']['data'])
      setRpdataIncomeDataTotal(
        decoded_res["data"][0]["rpdata_income"]["total"]
      );
      setMandatoryFixedData(
        decoded_res["data"][1]["rp_expense"]["mandatory_fixed"]["data"]
      );
      setMandatoryVariableData(
        decoded_res["data"][1]["rp_expense"]["mandatory_variable"]["data"]
      );
      setWishfulFixedData(
        decoded_res["data"][1]["rp_expense"]["wishful_fixed"]["data"]
      );
      setWishfulVariableData(
        decoded_res["data"][1]["rp_expense"]["wishful_variable"]["data"]
      );
      setGrossOutflowTotal(decoded_res["data"][1]["rp_expense"]);
      var gross_inflow_graph_data = [];
      var setgraphdata = [];
      if (decoded_res) {
        setgraphdata = [
          {
            name: "Variable Income",
            total:
              decoded_res["data"][0]["rpdata_income"]["income_type"][0][
                "graph_total"
              ],
            totoverall_piechart_income:
              decoded_res["data"][0]["rpdata_income"]["total"][
                "overall_piechart_income"
              ],
          },
          {
            name: "Fixed Income",
            total:
              decoded_res["data"][0]["rpdata_income"]["income_type"][1][
                "graph_total"
              ],
            totoverall_piechart_income:
              decoded_res["data"][0]["rpdata_income"]["total"][
                "overall_piechart_income"
              ],
          },
        ];
      } else {
        setgraphdata = [
          {
            name: "Variable Income",
            total:
              decoded_res["data"][0]["rpdata_income"]["income_type"][0][
                "graph_total"
              ],
          },
          {
            name: "Fixed Income",
            total:
              decoded_res["data"][0]["rpdata_income"]["income_type"][1][
                "graph_total"
              ],
          },
          {
            totoverall_piechart_income:
              decoded_res["data"][0]["rpdata_income"]["total"][
                "overall_piechart_income"
              ],
          },
        ];
      }
      setgraphdata.map((val) => {
        if (val["total"]) {
          gross_inflow_graph_data.push({
            name: val["name"],
            value: val["total"],
            total: val["totoverall_piechart_income"],
          });
        }
      });
      setGrossInflowGraphData(gross_inflow_graph_data);

      var gross_outflow_graph_data = [];
      var setgraphdata = [];
      if (decoded_res) {
        setgraphdata = [
          {
            name: "Mandatory Fixed",
            total:
              decoded_res["data"][1]["rp_expense"]["mandatory_fixed"]["total"][
                "avg_percent"
              ],
          },
          {
            name: "Mandatory Variable",
            total:
              decoded_res["data"][1]["rp_expense"]["mandatory_variable"][
                "total"
              ]["avg_percent"],
          },
          {
            name: "Wishful Fixed",
            total:
              decoded_res["data"][1]["rp_expense"]["wishful_fixed"]["total"][
                "avg_percent"
              ],
          },
          {
            name: "Wishful Variable",
            total:
              decoded_res["data"][1]["rp_expense"]["wishful_variable"]["total"][
                "avg_percent"
              ],
          },
        ];
      } else {
        setgraphdata = [
          {
            name: "Mandatory Fixed",
            total:
              decoded_res["data"][1]["rp_expense"]["mandatory_fixed"]["total"][
                "avg_percent"
              ],
          },
          {
            name: "Mandatory Variable",
            total:
              decoded_res["data"][1]["rp_expense"]["mandatory_variable"][
                "total"
              ]["avg_percent"],
          },
          {
            name: "Wishful Fixed",
            total:
              decoded_res["data"][1]["rp_expense"]["wishful_fixed"]["total"][
                "avg_percent"
              ],
          },
          {
            name: "Wishful Variable",
            total:
              decoded_res["data"][1]["rp_expense"]["wishful_variable"]["total"][
                "avg_percent"
              ],
          },
        ];
      }
      setgraphdata.map((val) => {
        if (val["total"]) {
          gross_outflow_graph_data.push({
            name: val["name"],
            value: val["total"],
          });
        }
      });
      setGrossOutflowGraphData(gross_outflow_graph_data);
    }
  };

  return (
    <DatagatherReportLayout>
      <FintooLoader isLoading={isLoading} />

      <div className="reports ">
        <div className="">
          <div className="background-div">
            <div class="bg active" id="report-bg-profile"></div>
          </div>
          <div className="white-box">
            <div className="d-flex justify-content-md-center tab-box">
              <div className="d-flex top-tab-menu noselect">
                <div
                  className={`tab-menu-item ${tab == "tab1" ? "active" : ""}`}
                  onClick={() => setTab("tab1")}
                >
                  <div className="tab-menu-title">ABOUT YOU</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab2" ? "active" : ""}`}
                  onClick={() => setTab("tab2")}
                >
                  <div className="tab-menu-title">RISK APPETITE</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab3" ? "active" : ""}`}
                  onClick={() => setTab("tab3")}
                >
                  <div className="tab-menu-title">CASHFLOW</div>
                </div>
                <div
                  className={`tab-menu-item ${tab == "tab4" ? "active" : ""}`}
                  onClick={() => setTab("tab4")}
                >
                  <div className="tab-menu-title">SCORECARD</div>
                </div>
              </div>
            </div>

            <div>
              <div className={tab == "tab1" ? "d-block" : "d-none"}>
                <div className="tabs innerTabs subTabWrapper">
                  <ul
                    className="nav nav-buttons nav-secoandary d-inline-flex"
                    id="intro-appendix"
                  >
                    <li
                      className={`tab-menu-item ${
                        tab123 == "tab5" ? "active" : ""
                      }`}
                    >
                      <a href onClick={() => setTab123("tab5")}>
                        About You
                      </a>
                    </li>

                    <li
                      className={`tab-menu-item ${
                        tab123 == "tab6" ? "active" : ""
                      }`}
                    >
                      <a href onClick={() => setTab123("tab6")}>
                        About Your Family
                      </a>
                    </li>
                  </ul>
                  <div>
                    <div className={tab123 == "tab5" ? "d-block" : "d-none"}>
                      {Object.keys(user_details).length > 0 && (
                        <div>
                          <h4 className="rTitle __web-inspector-hide-shortcut__">
                            <img
                              alt=""
                              src={
                                imagePath +
                                "https://static.fintoo.in/static/assets/img/reports/your-profile/about-you.svg"
                              }
                            />
                            About You
                          </h4>
                          <div className="profileHolderBox" id="step1">
                            {}
                            <div className="" id="about-you-screen-msg">
                              <p class="mb-4 text-center">{aboutScreenMsg}</p>
                            </div>
                            <div className="row justify-content-center about-info">
                              <div className="col-md-4">
                                <ul className="about-you-list">
                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/name.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">Name</div>
                                      {}
                                      <div className="">
                                        {user_details.first_name}{" "}
                                        {user_details.last_name}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/mobile-number.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">
                                        Mobile Number
                                      </div>
                                      {}
                                      <div className="">
                                        {user_details.mobile_number}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/email.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">
                                        Email Address
                                      </div>
                                      <div style={{ wordBreak: "break-all" }}>
                                        {}
                                        <a
                                          href="javascript:void(0)"
                                          className=""
                                        >
                                          {user_details.email_address}
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4">
                                <ul className="about-you-list">
                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/pan-number.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">PAN</div>
                                      {}
                                      <div className="">
                                        {user_details.pan_number}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/family.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">
                                        Family Member
                                      </div>
                                      <div className="">
                                        {user_details.family_member}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/dependent.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">
                                        No. of Dependents
                                      </div>
                                      {}
                                      <div className="">
                                        {user_details.num_of_dependents}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-4">
                                <ul className="about-you-list">
                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/dob.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">Age</div>
                                      {}
                                      <div className="">{user_details.age}</div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/retirement-age.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">
                                        Retirement Age
                                      </div>
                                      {}
                                      <div className="">
                                        {user_details.retirement_age}
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="icon-box">
                                      <img
                                        alt=""
                                        src={
                                          imagePath +
                                          "https://static.fintoo.in/static/assets/img/reports/your-profile/life-expectency.svg"
                                        }
                                      />
                                      <div className="YourDataLabel">
                                        Life Expectancy
                                      </div>
                                      {}
                                      <div className="">
                                        {user_details.life_expectancy}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {}
                            {}
                          </div>
                        </div>
                      )}

                      {Object.keys(user_details).length == 0 && (
                        <div className="no-data-found text-center">
                          <div className="container">
                            <div className="row justify-content-center align-items-center">
                              <div className="col-md-10">
                                <img
                                  src={
                                    imagePath +
                                    "https://static.fintoo.in/static/assets/img/data-not-found.svg"
                                  }
                                  alt="Data not found"
                                ></img>

                                {}
                                <p>
                                  Since you missed to fill in the required
                                  information which is needed here, we are not
                                  able to show you this section. Kindly click on
                                  below button to provide all the necessary
                                  inputs. Providing all the information as asked
                                  will ensure more accurate financial planning
                                  report. Once you fill in the data, same will
                                  be reflected here.
                                </p>

                                <a
                                  href={
                                    process.env.PUBLIC_URL +
                                    "/datagathering/about-you"
                                  }
                                  target="_blank"
                                  className="link"
                                >
                                  Complete Profile
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row py-2">
                        <div className=" text-center">
                          <div>
                            <div className="btn-container fixedBtn">
                              <div className="d-flex justify-content-center">
                                <Link
                                  to={process.env.PUBLIC_URL + "/commondashboard/"}
                                >
                                  <div className="previous-btn form-arrow d-flex align-items-center">
                                    <FaArrowLeft />
                                    <span
                                      className="hover-text"
                                      style={{ maxWidth: 100 }}
                                    >
                                      &nbsp;Previous
                                    </span>
                                  </div>
                                </Link>
                                {}
                                {}
                                <div
                                  className="next-btn form-arrow d-flex align-items-center"
                                  onClick={() => setTab123("tab6")}
                                >
                                  <span
                                    className="hover-text"
                                    style={{ maxWidth: 100 }}
                                  >
                                    Next&nbsp;
                                  </span>
                                  <FaArrowRight />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={tab123 == "tab6" ? "d-block" : "d-none"}>
                      <div className="">
                        {memberData?.length > 0 && (
                          <>
                            <h4 className="rTitle">
                              <img
                                alt=""
                                src={
                                  imagePath +
                                  "https://static.fintoo.in/static/assets/img/reports/your-profile/about-your-family.svg"
                                }
                              />
                              About Your Family
                            </h4>
                            <div className="member-container">
                              <p className="rTitle">Members</p>

                              <div className="row">
                                {earning_members.map((member) => (
                                  <>
                                    {}
                                    <div className="col-md-4 ">
                                      <div className="memeber-box  earnings-memeber-box">
                                        <span className="relation ">
                                          <img
                                            className="pe-2"
                                            src={
                                              member.relation_name == "Spouse"
                                                ? Spouse
                                                : member.relation_name ==
                                                  "Daughter"
                                                ? Daughter
                                                : member.relation_name ==
                                                  "Father"
                                                ? Father
                                                : member.relation_name ==
                                                  "Mother"
                                                ? Mother
                                                : member.relation_name == "Son"
                                                ? Son
                                                : member.relation_name ==
                                                  "Hindu Undivided Family"
                                                ? HUF
                                                : ""
                                            }
                                            style={{
                                              height: "20px",
                                              width: "auto",
                                            }}
                                          />
                                          <span>{member.relation_name}</span>
                                        </span>
                                        <div
                                          className="pt-3 FamilyLabel"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {member.first_name} {member.last_name}
                                        </div>
                                        <div
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          className="pt-2"
                                        >
                                          {member.age}, {member.gender}
                                        </div>
                                        <hr />
                                        <ul className="about-you-list row aboutmember-earnings">
                                          {/* {user_details.retirement_age > 0 &&  */}
                                          <div className="comm-pad-cls col-md-6">
                                            {/* <p>Greater than 0</p> */}
                                            <li>
                                              <div className="icon-box">
                                                <img
                                                  alt=""
                                                  src={
                                                    imagePath +
                                                    "https://static.fintoo.in/static/assets/img/reports/your-profile/retirement-age.svg"
                                                  }
                                                />
                                                <div>Retirement Age</div>
                                                <div className="">
                                                  {member.retirement_age}
                                                </div>
                                              </div>
                                            </li>
                                          </div>
                                          {/* } */}

                                          {}

                                          <div className="comm-pad-cls col-md-6">
                                            <li>
                                              <div className="icon-box">
                                                <img
                                                  alt=""
                                                  src={
                                                    imagePath +
                                                    "https://static.fintoo.in/static/assets/img/reports/your-profile/life-expectency.svg"
                                                  }
                                                />
                                                <div>Life Expectency</div>
                                                <div className="">
                                                  {member.life_expectancy}
                                                </div>
                                              </div>
                                            </li>
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                    {}
                                  </>
                                ))}

                                {dependent_members.map((member) => (
                                  <>
                                    {}
                                    <div className="col-md-4 ">
                                      <div className="memeber-box  earnings-memeber-box">
                                        <span className="relation ">
                                          <img
                                            className="pe-2"
                                            src={
                                              member.relation_name == "Spouse"
                                                ? Spouse
                                                : member.relation_name ==
                                                  "Daughter"
                                                ? Daughter
                                                : member.relation_name ==
                                                  "Father"
                                                ? Father
                                                : member.relation_name ==
                                                  "Mother"
                                                ? Mother
                                                : member.relation_name == "Son"
                                                ? Son
                                                : member.relation_name ==
                                                  "Hindu Undivided Family"
                                                ? HUF
                                                : ""
                                            }
                                            style={{
                                              height: "20px",
                                              width: "auto",
                                            }}
                                          />
                                          <span> {member.relation_name} </span>
                                        </span>
                                        <div
                                          className="pt-3 FamilyLabel"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {member.first_name} {member.last_name}
                                        </div>
                                        <div
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          className="pt-2"
                                        >
                                          {member.age}, {member.gender}
                                        </div>
                                        {/* <hr /> */}
                                        {/* <ul className="about-you-list row aboutmember-earnings">
                                      {}
                                      <div className="comm-pad-cls col-md-6">
                                        <li>
                                          <div className="icon-box">
                                            <img
                                              alt=""
                                              src="https://static.fintoo.in/static/assets/img/reports/your-profile/retirement-age.svg"
                                            />
                                            <div>Retirement Age</div>
                                            <div className="">
                                              {member.retirement_age}
                                            </div>
                                          </div>
                                        </li>
                                      </div>
                                      <div className="comm-pad-cls col-md-6">
                                        <li>
                                          <div className="icon-box">
                                            <img
                                              alt=""
                                              src="https://static.fintoo.in/static/assets/img/reports/your-profile/life-expectency.svg"
                                            />
                                            <div>Life Expectency</div>
                                            <div className="">
                                              {member.life_expectancy}
                                            </div>
                                          </div>
                                        </li>
                                      </div>
                                    </ul> */}
                                      </div>
                                    </div>
                                    {}
                                  </>
                                ))}
                              </div>

                              <div className="row">
                                {}
                                {}
                                {}

                                {}
                                {}
                                {}
                                {}
                                {}
                                {}
                                {}
                                {}
                                {}
                                {}
                                {}
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {memberData?.length == 0 && (
                        <>
                          <div className="no-data-found text-center">
                            <div className="container">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-md-10">
                                  <img
                                    src={
                                      imagePath +
                                      "https://static.fintoo.in/static/assets/img/data-not-found.svg"
                                    }
                                    alt="Data not found"
                                  ></img>

                                  {}
                                  <p>
                                    Since you missed to fill in the required
                                    information which is needed here, we are not
                                    able to show you this section. Kindly click
                                    on below button to provide all the necessary
                                    inputs. Providing all the information as
                                    asked will ensure more accurate financial
                                    planning report. Once you fill in the data,
                                    same will be reflected here.
                                  </p>

                                  <a
                                    href={
                                      process.env.PUBLIC_URL +
                                      "/datagathering/about-you"
                                    }
                                    target="_blank"
                                    className="link"
                                  >
                                    Complete Profile
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row py-2">
                        <div className=" text-center">
                          <div>
                            <div className="btn-container fixedBtn">
                              <div className="d-flex justify-content-center">
                                <div
                                  className="previous-btn form-arrow d-flex align-items-center"
                                  onClick={() => setTab123("tab5")}
                                >
                                  <FaArrowLeft />
                                  <span className="hover-text">
                                    &nbsp;Previous
                                  </span>
                                </div>
                                {}
                                <div
                                  className="next-btn form-arrow d-flex align-items-center"
                                  onClick={() => setTab("tab2")}
                                >
                                  <span
                                    className="hover-text"
                                    style={{ maxWidth: 100 }}
                                  >
                                    Next&nbsp;
                                  </span>
                                  <FaArrowRight />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={tab == "tab2" ? "d-block" : "d-none"}>
              <div className="riskAppetiteHolder ">
                {}
                <h4 className="rTitle ">
                  <img
                    alt=""
                    src={
                      imagePath +
                      "https://static.fintoo.in/static/assets/img/reports/your-profile/risk-appetite.svg"
                    }
                  />
                  Risk Appetite
                </h4>
                {}
                {}
                <div className="row ">
                  <div className="col-md-2">
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <div className="rTitle text-center pb-3">Your Avatar</div>
                      <img
                        alt="risk avatar"
                        className="risk-avatar"
                        src={
                          "https://static.fintoo.in/static/assets/img/reports/" +
                          riskProfileImage
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="riskAppetiteBox">
                      <span> Risk Profile :</span>
                      <span className="rTitle">
                        {" "}
                        {}
                        {riskProfile}
                      </span>
                      {}
                      <div className="risk-appetite-bar">
                        <ul className="risk-bar">
                          <li className="level1" />
                          <li className="level2" />
                          <li className="level3" />
                          <li className="level4" />
                          <li className="level5" />
                        </ul>
                        {/* <p>{riskIndicator}</p> */}
                        <span
                          className="risk-indicator"
                          // style={{ left: "27%" }}
                          style={{ left: riskIndicator }}
                        />
                      </div>
                      <div className="">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: riskProfileDesc ? riskProfileDesc : "",
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="col-md-12 pyramid-cls mt-5">
                    <p>Investment Philosophy</p>
                    <img
                      className="InvestmentPhilosophy"
                      style={{ height: 350 }}
                      alt=""
                      src={"https://static.fintoo.in/static/" + riskPyramid}
                    />
                  </div>
                </div>
                {}
                {}
              </div>
              <div className="row py-2">
                <div className=" text-center">
                  <div>
                    <div className="btn-container fixedBtn">
                      <div className="d-flex justify-content-center">
                        <div
                          className="previous-btn form-arrow d-flex align-items-center"
                          onClick={() => setTab("tab1")}
                        >
                          <FaArrowLeft />
                          <span className="hover-text">&nbsp;Previous</span>
                        </div>
                        {}
                        <div
                          className="next-btn form-arrow d-flex align-items-center"
                          onClick={() => setTab("tab3")}
                        >
                          <span
                            className="hover-text"
                            style={{ maxWidth: 100 }}
                          >
                            Next&nbsp;
                          </span>
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={tab == "tab3" ? "d-block" : "d-none"}>
              <div className="">
                <div className="tabs innerTabs subTabWrapper ">
                  <ul
                    className="nav nav-buttons nav-seconadary d-inline-flex"
                    id="intro-appendix"
                  >
                    <li
                      className={`tab-menu-item ${
                        tab1232 == "tab7" ? "active" : ""
                      }`}
                    >
                      <a href onClick={() => setTab1232("tab7")}>
                        Gross - Inflow &amp; Outflow
                      </a>
                    </li>
                    <li
                      className={`tab-menu-item ${
                        tab1232 == "tab8" ? "active" : ""
                      }`}
                    >
                      <a href onClick={() => setTab1232("tab8")}>
                        Saving - Deficit
                      </a>
                    </li>
                  </ul>
                  {}
                  <div className={tab1232 == "tab7" ? "d-block" : "d-none"}>
                    {rpDataIncome.length > 0 &&
                    (mandatoryFixedData?.length > 0 ||
                      mandatoryVariableData?.length > 0 ||
                      wishfulFixedData?.length > 0 ||
                      wishfulVariableData?.length > 0) ? (
                      <div className="bottom-padding">
                        <h4 className="rTitle">
                          <img
                            alt=""
                            src={
                              imagePath +
                              "https://static.fintoo.in/static/assets/img/reports/your-profile/gross-inflow.svg"
                            }
                          />
                          Gross Inflow
                        </h4>
                        <div className="rContent ">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: inoutSectionData ? inoutSectionData : "",
                            }}
                          ></p>
                        </div>
                        <div className="table-responsive rTable">
                          <table className="bgStyleTable">
                            <tbody className="borderRemove">
                              <tr>
                                <th>Category</th>
                                <th>Name of Holder</th>
                                <th>Income Name</th>
                                <th>Frequency</th>
                                <th>Monthly (₹)</th>
                                <th className="">
                                  Yearly (₹) (till 31<sup>st</sup> Dec,{" "}
                                  {currentYear})
                                </th>
                              </tr>
                              {}
                              {rpDataIncome.map((inflow) => (
                                <tr className="">
                                  <td className="">{inflow.category_name}</td>
                                  <td className="">
                                    {inflow.first_name}{" "}
                                    {inflow.last_name
                                      ? inflow.last_name
                                      : "Family"}
                                  </td>
                                  {/* <td className="">{inflow.income_name} {inflow.category_name == 'Salary & Bonus' ?
                                    (inflow.income_type == '1' ? '/ Salary' : '/ Bonus') : ''} </td> */}

                                  <td className="">
                                    {inflow.income_name || inflow.interest_name}
                                    {inflow.category_name === "Salary & Bonus"
                                      ? inflow.income_type === "1"
                                        ? "/ Salary"
                                        : "/ Bonus"
                                      : ""}
                                  </td>
                                  <td className="">
                                    {inflow.frequency
                                      ? inflow.frequency
                                      : inflow.interest_frequency}
                                  </td>
                                  <td className="">
                                    {rsFilter(inflow.monthly_income)}
                                  </td>
                                  <td className="">
                                    {rsFilter(inflow.annual_income)}
                                  </td>
                                </tr>
                              ))}

                              {interestIncome.map((inflow) => (
                                <tr className="">
                                  <td className="">{inflow.category_name}</td>
                                  <td className="">
                                    {inflow.first_name}{" "}
                                    {inflow.last_name
                                      ? inflow.last_name
                                      : "Family"}
                                  </td>
                                  {/* <td className="">{inflow.income_name} {inflow.category_name == 'Salary & Bonus' ?
                                    (inflow.income_type == '1' ? '/ Salary' : '/ Bonus') : ''} </td> */}

                                  <td className="">
                                    {inflow.income_name || inflow.interest_name}
                                    {inflow.category_name === "Salary & Bonus"
                                      ? inflow.income_type === "1"
                                        ? "/ Salary"
                                        : "/ Bonus"
                                      : ""}
                                  </td>
                                  <td className="">
                                    {inflow.frequency
                                      ? inflow.frequency
                                      : inflow.interest_frequency}
                                  </td>
                                  <td className="">
                                    {rsFilter(inflow.monthly_income)}
                                  </td>
                                  <td className="">
                                    {rsFilter(inflow.annual_income)}
                                  </td>
                                </tr>
                              ))}
                              {}
                              <tr className="bold top-line total-value">
                                <td>Gross inflow</td>
                                <td className="" />
                                <td className="" />
                                <td className="" />
                                <td className="">
                                  {rsFilter(
                                    rpDataIncomeTotal.total_monthly_income
                                  )}
                                </td>
                                <td className="">
                                  {rsFilter(
                                    rpDataIncomeTotal.total_annual_income
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {}
                        <br />
                        <br />
                        <h4 className="rTitle">
                          <img
                            alt=""
                            src={
                              imagePath +
                              "https://static.fintoo.in/static/assets/img/reports/your-profile/gross-outflow.svg"
                            }
                          />
                          Gross Outflow
                        </h4>
                        <div className="rContent">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: outflowSectionData
                                ? outflowSectionData
                                : "",
                            }}
                          ></p>
                        </div>
                        {}
                        {}
                        <div className="">
                          <div className="table-responsive rTable">
                            <table className="bgStyleTable">
                              <tbody className="borderRemove">
                                <tr>
                                  <th>Particular</th>
                                  {}
                                  <th>Name of Holder</th>
                                  <th>Expense Name</th>
                                  <th>Frequency</th>
                                  <th>Monthly (₹)</th>
                                  <th className="">
                                    YTD (₹) (till 31<sup>st</sup> Dec,{" "}
                                    {currentYear})
                                  </th>
                                </tr>

                                {mandatoryFixedData?.length > 0 && (
                                  <tr>
                                    <td>
                                      <strong> Mandatory Fixed </strong>
                                    </td>
                                    <td>
                                      {}
                                      {mandatoryFixedData.map(
                                        (mandatory_fixed) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {mandatory_fixed.first_name
                                                    ? mandatory_fixed.first_name
                                                    : "Family"}
                                                  {mandatory_fixed.last_name}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryFixedData.map(
                                        (mandatory_fixed) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {
                                                    mandatory_fixed.expenses_name
                                                  }
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryFixedData.map(
                                        (mandatory_fixed) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {mandatory_fixed.frequency}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryFixedData.map(
                                        (mandatory_fixed) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {rsFilter(
                                                    mandatory_fixed.monthly_expense
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryFixedData.map(
                                        (mandatory_fixed) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {rsFilter(
                                                    mandatory_fixed.annual_expense
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {mandatoryVariableData?.length > 0 && (
                                  <tr>
                                    <td>
                                      <strong> Mandatory Variable </strong>
                                    </td>
                                    <td>
                                      {}
                                      {mandatoryVariableData.map(
                                        (mandatory_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {mandatory_variable.first_name
                                                    ? mandatory_variable.first_name
                                                    : "Family"}
                                                  {mandatory_variable.last_name}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryVariableData.map(
                                        (mandatory_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {
                                                    mandatory_variable.expenses_name
                                                  }
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryVariableData.map(
                                        (mandatory_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {mandatory_variable.frequency}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryVariableData.map(
                                        (mandatory_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {rsFilter(
                                                    mandatory_variable.monthly_expense
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                    </td>

                                    <td>
                                      {}
                                      {mandatoryVariableData.map(
                                        (mandatory_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {rsFilter(
                                                    mandatory_variable.annual_expense
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {wishfulFixedData?.length > 0 && (
                                  <tr>
                                    <td>
                                      <strong> Wishful Fixed </strong>
                                    </td>
                                    <td>
                                      {}
                                      {wishfulFixedData.map((wishful_fixed) => (
                                        <table className="TBData">
                                          <tbody className="Boredernone">
                                            <tr>
                                              <td className="">
                                                {wishful_fixed.first_name
                                                  ? wishful_fixed.first_name
                                                  : "Family"}
                                                {wishful_fixed.last_name}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ))}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulFixedData.map((wishful_fixed) => (
                                        <table className="TBData">
                                          <tbody className="Boredernone">
                                            <tr>
                                              <td className="">
                                                {wishful_fixed.expenses_name}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ))}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulFixedData.map((wishful_fixed) => (
                                        <table className="TBData">
                                          <tbody className="Boredernone">
                                            <tr>
                                              <td className="">
                                                {wishful_fixed.frequency}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ))}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulFixedData.map((wishful_fixed) => (
                                        <table className="TBData">
                                          <tbody className="Boredernone">
                                            <tr>
                                              <td className="">
                                                {rsFilter(
                                                  wishful_fixed.monthly_expense
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ))}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulFixedData.map((wishful_fixed) => (
                                        <table className="TBData">
                                          <tbody className="Boredernone">
                                            <tr>
                                              <td className="">
                                                {rsFilter(
                                                  wishful_fixed.annual_expense
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ))}
                                    </td>
                                  </tr>
                                )}

                                {wishfulVariableData?.length > 0 && (
                                  <tr>
                                    <td>
                                      <strong> Wishful Variable </strong>
                                    </td>
                                    <td>
                                      {}
                                      {wishfulVariableData.map(
                                        (wishful_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {wishful_variable.first_name
                                                    ? wishful_variable.first_name
                                                    : "Family"}
                                                  {wishful_variable.last_name}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulVariableData.map(
                                        (wishful_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {
                                                    wishful_variable.expenses_name
                                                  }
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulVariableData.map(
                                        (wishful_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {wishful_variable.frequency}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                      {}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulVariableData.map(
                                        (wishful_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {rsFilter(
                                                    wishful_variable.monthly_expense
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                    </td>

                                    <td>
                                      {}
                                      {wishfulVariableData.map(
                                        (wishful_variable) => (
                                          <table className="TBData">
                                            <tbody className="Boredernone">
                                              <tr>
                                                <td className="">
                                                  {rsFilter(
                                                    wishful_variable.annual_expense
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}

                                <tr className="bold top-line total-value">
                                  <td colSpan={4}>Gross Outflow</td>
                                  {}
                                  <td className="">
                                    {rsFilter(
                                      grossOutflowTotal["total_monthly_expense"]
                                    )}
                                  </td>
                                  <td className="">
                                    {rsFilter(
                                      grossOutflowTotal["total_annual_expense"]
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className="rGraph row">
                          <div
                            id="grossinflow"
                            className="col-md-6"
                            data-highcharts-chart={0}
                            style={{ overflow: "hidden" }}
                          >
                            <div className="text-center">
                              <h4>Gross Inflow</h4>
                            </div>

                            <div className="">
                              <PieChartInflow
                                grossInflowGraphData={grossInflowGraphData}
                              />
                            </div>
                          </div>
                          <div
                            id="outFlowGraph"
                            className="col-md-6"
                            data-highcharts-chart={1}
                            // style={{ overflow: "hidden" }}
                          >
                            <div className="text-center">
                              <h4>Gross Outflow</h4>
                            </div>
                            <div className="d-flex justify-content-center">
                              <PieChartOutflow
                                grossOutflowGraphData={grossOutflowGraphData}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />

                        {/* <div className="container" style={{ paddingTop: 25 }}>
                          <div className="recommen_sec_div">
                              <div className="rec_head_div">
                                <i />
                                <span>Fintoo Recommends</span>
                              </div>
                              <div
                                className="inner_text_div Recommandedtext"
                                style={{ fontWeight: "bold" }}
                              >
                                <ul>
                                  <li>
                                    <p className="rContent " />
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: fintooNotes1
                                          ? fintooNotes1
                                          : "",
                                      }}
                                    ></p>
                                    <p />
                                  </li>
                                </ul>
                              </div>
                          </div>
                        </div> */}

                        <div></div>

                        <div className="container">
                          <div
                            className="notes_sec_div mt-md-5"
                            style={{ border: "none" }}
                          >
                            <div className="notes_head_div mt-md-3">
                              <i />
                              <span>Notes</span>
                            </div>
                            <div className="notes_text_div">
                              <ul>
                                <li
                                  style={{
                                    backgroundImage:
                                      window.location.hostname === "localhost"
                                        ? 'url("http://127.0.0.1:8000/image/?frontend=1&file=http://localhost:3001/web/static/media/Report/Next.svg")'
                                        : 'url("/image/?frontend=1&file=https://www.fintoo.in/web/static/media/Report/Next.svg")',
                                  }}
                                >
                                  {/* <p className="rContent " /> */}
                                  <p
                                    className="rContent"
                                    dangerouslySetInnerHTML={{
                                      __html: fintooNotes ? fintooNotes : "",
                                    }}
                                  ></p>
                                  {/* <p /> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {}
                      </div>
                    ) : (
                      <div className="no-data-found text-center">
                        <div className="container">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-10">
                              <img
                                alt="Data not found"
                                src={
                                  imagePath +
                                  "https://static.fintoo.in/static/assets/img/data-not-found.svg"
                                }
                              />
                              {}
                              <p>
                                Since you missed to fill in the required
                                information which is needed here, we are not
                                able to show you this section. Kindly click on
                                below button to provide all the necessary
                                inputs. Providing all the information as asked
                                will ensure more accurate financial planning
                                report. Once you fill in the data, same will be
                                reflected here.
                              </p>
                              <a
                                href={
                                  BASE_API_URL +
                                  "web/datagathering/income-expenses"
                                }
                                target="_blank"
                                className="link"
                              >
                                Complete Income-expenses
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div style={{ height: 50 }}></div>
                    {}
                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container fixedBtn">
                            <div className="d-flex justify-content-center">
                              <div
                                className="previous-btn form-arrow d-flex align-items-center"
                                onClick={() => setTab("tab2")}
                              >
                                <FaArrowLeft />
                                <span className="hover-text">
                                  &nbsp;Previous
                                </span>
                              </div>
                              {}
                              <div
                                className="next-btn form-arrow d-flex align-items-center"
                                onClick={() => setTab1232("tab8")}
                              >
                                <span
                                  className="hover-text"
                                  style={{ maxWidth: 100 }}
                                >
                                  Next&nbsp;
                                </span>
                                <FaArrowRight />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={tab1232 == "tab8" ? "d-block" : "d-none"}>
                    {}
                    {Object.keys(totalSavingData).length > 0 ? (
                      <>
                        <h4 className="rTitle">
                          <img
                            src={
                              imagePath +
                              "https://static.fintoo.in/static/assets/img/reports/icons/t_your_profile_total_saving.svg"
                            }
                          />
                          Total Savings / Deficit
                        </h4>
                        <div className="bottom-padding">
                          <div className="rContent ">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: pSurplusSectionData.field0
                                  ? pSurplusSectionData.field0
                                  : "",
                              }}
                            ></p>
                          </div>
                          {}
                          {}
                          <div className="table-responsive rTable">
                            <table className="bgStyleTable">
                              <tbody className="borderRemove">
                                <tr>
                                  <th>Type</th>
                                  <th>Monthly (₹)</th>
                                  <th className="">
                                    Yearly (₹) (till 31<sup>st</sup> Dec,{" "}
                                    {currentYear})
                                  </th>
                                </tr>
                                <tr>
                                  <td>Total Gross Inflow</td>
                                  <td className="">
                                    {rsFilter(
                                      totalGrossInflowData[
                                        "total_monthly_income"
                                      ]
                                    )}
                                  </td>
                                  <td className="">
                                    {rsFilter(
                                      totalGrossInflowData[
                                        "total_annual_income"
                                      ]
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Gross Outflow</td>
                                  <td className="">
                                    {rsFilter(
                                      totalGrossOutflowData[
                                        "total_monthly_expense"
                                      ]
                                    )}
                                  </td>
                                  <td className="">
                                    {rsFilter(
                                      totalGrossOutflowData[
                                        "total_annual_expense"
                                      ]
                                    )}
                                  </td>
                                </tr>
                                <tr className="bold top-line total-value">
                                  <td className="">Total Savings </td>

                                  {totalSavingData["monthly"] === 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["monthly"])}
                                    </td>
                                  )}
                                  {totalSavingData["monthly"] > 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["monthly"])}
                                    </td>
                                  )}
                                  {totalSavingData["monthly"] < 0 && (
                                    <td style={{ color: "red" }}>
                                      (
                                      {rsFilter(
                                        Math.abs(totalSavingData["monthly"])
                                      )}
                                      )
                                    </td>
                                  )}

                                  {totalSavingData["annual"] === 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["annual"])}
                                    </td>
                                  )}
                                  {totalSavingData["annual"] > 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["annual"])}
                                    </td>
                                  )}
                                  {totalSavingData["annual"] < 0 && (
                                    <td style={{ color: "red" }}>
                                      (
                                      {rsFilter(
                                        Math.abs(totalSavingData["annual"])
                                      )}
                                      )
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="rGraph mt-2 p-md-4 pt-3">
                            <div className="d-md-flex justify-content-around">
                              <div className="mt-4">
                                <TotalSavings
                                  currentTotalSavings={currentTotalSavings}
                                />
                              </div>

                              {/* <div className="mt-4">
                            <TotalSavingss
                              nextYearTotalSavings={nextYearTotalSavings}
                            />
                          </div> */}
                            </div>
                          </div>
                          {(assetData?.length > 0 ||
                            insuranceData?.length > 0) && (
                            <h4 className="rTitle">
                              <img
                                src={
                                  imagePath +
                                  "https://static.fintoo.in/static/assets/img/reports/your-profile/existing-insurance.svg"
                                }
                              />
                              Existing Insurance &amp; Investment Commitment{" "}
                            </h4>
                          )}
                          {(assetData?.length > 0 ||
                            insuranceData?.length > 0) && (
                            <div className="rContent ">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: pSurplusSectionData.field1
                                    ? pSurplusSectionData.field1
                                    : "",
                                }}
                              ></p>
                            </div>
                          )}
                          {(assetData?.length > 0 ||
                            insuranceData?.length > 0) && (
                            <div className="table-responsive rTable">
                              <table
                                className="bgStyleTable"
                                style={{ fontSize: 13 }}
                              >
                                <tbody className="borderRemove">
                                  <tr>
                                    <th>Category</th>
                                    <th>Scheme</th>
                                    <th>Name of the Holder</th>
                                    <th>Frequency</th>
                                    <th>Committed Investment/Insurance (₹)</th>
                                    <th>
                                      YTD (₹)
                                      {}
                                      <span
                                        style={{ fontSize: 12, paddingLeft: 5 }}
                                        className=""
                                      >
                                        (till 31<sup>st</sup> Dec, {currentYear}
                                        )
                                      </span>
                                    </th>
                                  </tr>
                                  {assetData.map((asset) => (
                                    <tr className="">
                                      <td className="">
                                        {asset.category} - {asset.scheme}
                                      </td>
                                      <td className="">{asset.name}</td>
                                      <td className="">{asset.fullname}</td>
                                      <td className="">{asset.frequency}</td>
                                      <td className="">{asset.asset_amount}</td>
                                      <td className="">{asset.annualy}</td>
                                    </tr>
                                  ))}
                                  {insuranceData.map((insurance) => (
                                    <tr className="">
                                      <td className="">
                                        {insurance.category} -{" "}
                                        {insurance.scheme}
                                      </td>
                                      <td className="">
                                        {insurance.insurance_name}
                                      </td>
                                      <td className="">{insurance.fullname}</td>
                                      <td className="">
                                        {insurance.frequency}
                                      </td>
                                      <td className="">
                                        {insurance.asset_amount}
                                      </td>
                                      <td className="">{insurance.annualy}</td>
                                    </tr>
                                  ))}
                                  <tr className="bold top-line total-value">
                                    <td>Gross total</td>
                                    <td />
                                    <td />
                                    <td />
                                    <td className="">
                                      {rsFilter(
                                        investmentInsuranceData["total_monthly"]
                                      )}
                                    </td>
                                    <td className="">
                                      {rsFilter(
                                        investmentInsuranceData["total_yearly"]
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                          <h4 className="rTitle mt-5">
                            <img
                              src={
                                imagePath +
                                "https://static.fintoo.in/static/assets/img/reports/your-profile/shortfall.svg"
                              }
                            />
                            Investable Surplus / Shortfall
                          </h4>
                          <div className="rContent ">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: pSurplusSectionData.field2
                                  ? pSurplusSectionData.field2
                                  : "",
                              }}
                            ></p>
                          </div>
                          <div className="table-responsive rTable">
                            <table className="bgStyleTable">
                              <tbody className="borderRemove">
                                <tr>
                                  <th>Type</th>
                                  <th>Monthly (₹)</th>
                                  <th className="">
                                    Yearly (₹) (till 31<sup>st</sup> Dec,{" "}
                                    {currentYear})
                                  </th>
                                </tr>
                                {}
                                <tr className="">
                                  {}
                                  {totalSavingData["annual"] > 0 && (
                                    <td>
                                      <span className=" plainclass">
                                        Saving ( A )
                                      </span>
                                    </td>
                                  )}
                                  {totalSavingData["annual"] < 0 && (
                                    <td>
                                      <span className=" plainclass">
                                        Deficit ( A )
                                      </span>
                                    </td>
                                  )}

                                  {totalSavingData["monthly"] === 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["monthly"])}
                                    </td>
                                  )}
                                  {totalSavingData["monthly"] > 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["monthly"])}
                                    </td>
                                  )}
                                  {totalSavingData["monthly"] < 0 && (
                                    <td style={{ color: "red" }}>
                                      (
                                      {rsFilter(
                                        Math.abs(totalSavingData["monthly"])
                                      )}
                                      )
                                    </td>
                                  )}

                                  {totalSavingData["annual"] === 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["annual"])}
                                    </td>
                                  )}
                                  {totalSavingData["annual"] > 0 && (
                                    <td>
                                      {rsFilter(totalSavingData["annual"])}
                                    </td>
                                  )}
                                  {totalSavingData["annual"] < 0 && (
                                    <td style={{ color: "red" }}>
                                      (
                                      {rsFilter(
                                        Math.abs(totalSavingData["annual"])
                                      )}
                                      )
                                    </td>
                                  )}
                                </tr>
                                {}
                                <tr className="">
                                  {}
                                  <td>
                                    <span className=" plainclass">
                                      Existing Insurance &amp; investment
                                      Commitment ( B )
                                    </span>
                                  </td>
                                  <td className="">
                                    <span className=" plainclass">
                                      {rsFilter(
                                        investmentInsuranceData["total_monthly"]
                                      )}
                                    </span>
                                  </td>
                                  {}
                                  <td className="">
                                    <span className=" plainclass">
                                      {rsFilter(
                                        investmentInsuranceData["total_yearly"]
                                      )}
                                    </span>
                                  </td>
                                </tr>
                                {}
                                <tr className="">
                                  {}
                                  <td>
                                    <span className=" boldclass">
                                      <b>Net Investable Surplus ( A - B )</b>
                                    </span>
                                  </td>

                                  {surplusShortfallData["monthly"] === 0 && (
                                    <td>
                                      {rsFilter(
                                        surplusShortfallData["monthly"]
                                      )}
                                    </td>
                                  )}
                                  {surplusShortfallData["monthly"] > 0 && (
                                    <td>
                                      {rsFilter(
                                        surplusShortfallData["monthly"]
                                      )}
                                    </td>
                                  )}
                                  {surplusShortfallData["monthly"] < 0 && (
                                    <td style={{ color: "red" }}>
                                      (
                                      {rsFilter(
                                        Math.abs(
                                          surplusShortfallData["monthly"]
                                        )
                                      )}
                                      )
                                    </td>
                                  )}

                                  {surplusShortfallData["annual"] === 0 && (
                                    <td>
                                      {rsFilter(surplusShortfallData["annual"])}
                                    </td>
                                  )}
                                  {surplusShortfallData["annual"] > 0 && (
                                    <td>
                                      {rsFilter(surplusShortfallData["annual"])}
                                    </td>
                                  )}
                                  {surplusShortfallData["annual"] < 0 && (
                                    <td style={{ color: "red" }}>
                                      (
                                      {rsFilter(
                                        Math.abs(surplusShortfallData["annual"])
                                      )}
                                      )
                                    </td>
                                  )}
                                </tr>
                                {}
                              </tbody>
                            </table>
                          </div>
                          <div className="rGraph mt-1 p-md-4 pt-3">
                            <div className="d-md-flex justify-content-around">
                              <div className="mt-4">
                                <TotalInvestable
                                  currentTotalInvestable={
                                    currentTotalInvestable
                                  }
                                />
                              </div>

                              <div className="mt-4">
                                <TotalInvestablee
                                  nextYearTotalInvestable={
                                    nextYearTotalInvestable
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div class="rContent" ng-bind-html="p_surplus_section_data['field3']"></div> */}
                        </div>
                      </>
                    ) : (
                      <div className="no-data-found text-center">
                        <div className="container">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-10">
                              <img
                                alt="Data not found"
                                src={
                                  imagePath +
                                  "https://static.fintoo.in/static/assets/img/data-not-found.svg"
                                }
                              />
                              {}
                              <p>
                                Since you missed to fill in the required
                                information which is needed here, we are not
                                able to show you this section. Kindly click on
                                below button to provide all the necessary
                                inputs. Providing all the information as asked
                                will ensure more accurate financial planning
                                report. Once you fill in the data, same will be
                                reflected here.
                              </p>
                              <a
                                href={
                                  BASE_API_URL +
                                  "web/datagathering/income-expenses"
                                }
                                target="_blank"
                                className="link"
                              >
                                Complete Income Expenses
                              </a>
                            </div>
                          </div>
                        </div>
                        <div style={{ height: 50 }}></div>
                      </div>
                    )}

                    <div className="row py-2">
                      <div className=" text-center">
                        <div>
                          <div className="btn-container fixedBtn">
                            <div className="d-flex justify-content-center">
                              <div
                                className="previous-btn form-arrow d-flex align-items-center"
                                onClick={() => setTab1232("tab7")}
                              >
                                <FaArrowLeft />
                                <span className="hover-text">
                                  &nbsp;Previous
                                </span>
                              </div>
                              {}
                              <div
                                className="next-btn form-arrow d-flex align-items-center"
                                onClick={() => setTab("tab4")}
                              >
                                <span
                                  className="hover-text"
                                  style={{ maxWidth: 100 }}
                                >
                                  Next&nbsp;
                                </span>
                                <FaArrowRight />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={tab == "tab4" ? "d-block" : "d-none"}>
              <div className="ProfileIncomeExpensesHolder profileScoreCard ">
                <div>
                  <h4 className="rTitle">
                    <img
                      alt=""
                      src={
                        imagePath +
                        "https://static.fintoo.in/static/assets/img/reports/icons/t_your_profile_gross_inflow.svg"
                      }
                    />
                    Your Scorecard
                  </h4>
                  <div style={{ fontSize: 14 }} className="">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: scoreScreenMsg ? scoreScreenMsg : "",
                      }}
                    ></p>
                  </div>
                  <div className="gridCalculation">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="gridCalBox">
                          <h4 className="rTitle">Saving ratio</h4>
                          <div className="scorebard-box">
                            <img src="https://static.fintoo.in/static/assets/img/scorecard.svg" />
                            {/* <span className="">20/20</span> */}
                            <span className="">
                              {incomeExpenseData.saving_marks}/20
                            </span>
                          </div>
                          <div className="colorBgBand">
                            <div className="col-md-5">
                              <p className="fontsizeclass">
                                Savings / Total income{" "}
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-5">
                              {/* {" "} {incomeExpenseData?.annual_expense}
                              {incomeExpenseData?.annual_income} */}

                              <p className="fontsizeclass ">
                                {/* ₹14.48 Lac / ₹29.48 Lac */}
                                {formatLocPrice(
                                  incomeExpenseData?.annual_income -
                                    incomeExpenseData?.annual_expense
                                )}{" "}
                                /{" "}
                                {formatLocPrice(
                                  incomeExpenseData?.annual_income ?? 0
                                )}
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-2">
                              {}
                              {}
                              <p class="fontsizeclass" className=" ">
                                {/* 49% */}
                                {incomeExpenseData.saving_ratio < 0 && (
                                  <>
                                    (
                                    {makePositive(
                                      incomeExpenseData.saving_ratio
                                    )}
                                    )%
                                  </>
                                )}
                                {incomeExpenseData.saving_ratio >= 0 && (
                                  <>{incomeExpenseData.saving_ratio}%</>
                                )}
                              </p>
                              {}
                            </div>
                          </div>
                          <div className="scoreCardLabel">
                            Your Ideal Savings Should Be atleast{" "}
                            {incomeExpenseData.ideal_saving_ratio}% Of Your
                            Income.
                          </div>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: incomeExpenseData.saving_data
                                ? incomeExpenseData.saving_data
                                : "",
                            }}
                          ></p>
                          <p />
                          {}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="gridCalBox">
                          <h4 className="rTitle">Expense ratio</h4>
                          <div className="scorebard-box">
                            <img src="https://static.fintoo.in/static/assets/img/scorecard.svg" />
                            <span className="">
                              {incomeExpenseData.expense_marks}/20
                            </span>
                          </div>
                          <div className="colorBgBand">
                            <div className="col-md-5">
                              <p className="fontsizeclass">
                                Expense / Total income{" "}
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-5">
                              <p className="fontsizeclass ">
                                {" "}
                                {/* ₹15 Lac / ₹29.48 Lac */}
                                {formatLocPrice(
                                  incomeExpenseData?.annual_expense ?? 0
                                )}{" "}
                                /{" "}
                                {formatLocPrice(
                                  incomeExpenseData?.annual_income ?? 0
                                )}
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-2">
                              {}
                              {}
                              <p className="fontsizeclass  ">
                                {/* 51% */}
                                {incomeExpenseData.expense_ratio < 0 && (
                                  <>
                                    (
                                    {makePositive(
                                      incomeExpenseData.expense_ratio
                                    )}
                                    )%
                                  </>
                                )}
                                {incomeExpenseData.expense_ratio >= 0 && (
                                  <>{incomeExpenseData.expense_ratio}%</>
                                )}
                              </p>
                              {}
                            </div>
                          </div>
                          <div className="scoreCardLabel">
                            Your Ideal Expenses Should not Be More Than{" "}
                            {incomeExpenseData.ideal_expense_ratio}% Of Your
                            Income.
                          </div>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: incomeExpenseData.expense_data
                                ? incomeExpenseData.expense_data
                                : "",
                            }}
                          ></p>

                          <p />
                          {}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="gridCalBox">
                          <h4 className="rTitle">Networth ratio</h4>
                          <div className="scorebard-box">
                            <img src="https://static.fintoo.in/static/assets/img/scorecard.svg" />
                            <span className="">
                              {netLiquidData.networth_mark}/20
                            </span>
                          </div>
                          <div className="colorBgBand">
                            <div className="col-md-4">
                              <p className="fontsizeclass">
                                Total assets - Total liabilities
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-4">
                              <p className="fontsizeclass ">
                                {" "}
                                {/* ₹6.01 Cr - ₹85 Lac{" "} */}
                                {formatLocPrice(
                                  netLiquidData?.total_asset ?? 0
                                )}{" "}
                                -{" "}
                                {formatLocPrice(
                                  netLiquidData.total_liability ?? 0
                                )}
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-4">
                              {/* <p className="fontsizeclass ">₹5.16 Cr</p> */}
                              <p className="fontsizeclass ">
                                {formatLocPrice(
                                  netLiquidData?.networth_ratio ?? 0
                                )}
                              </p>
                            </div>
                          </div>
                          {}
                          <div className="scoreCardLabel">
                            {/* Your Ideal Networth should be ₹3.98 Cr */}
                            Your Ideal net worth should be atleast{" "}
                            {formatLocPrice(netLiquidData?.ideal_networth ?? 0)}
                          </div>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: netLiquidData.networth_data
                                ? netLiquidData.networth_data
                                : "",
                            }}
                          ></p>
                          <p>
                            <p>
                              Your current networth is {""}
                              {formatLocPrice(
                                netLiquidData?.networth_ratio ?? 0
                              )}
                            </p>
                          </p>
                          <p />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="gridCalBox">
                          <h4 className="rTitle">Liquidity ratio</h4>
                          <div className="scorebard-box">
                            <img src="https://static.fintoo.in/static/assets/img/scorecard.svg" />
                            <span className="">
                              {netLiquidData.liquidity_mark}/20
                            </span>
                          </div>
                          <div className="colorBgBand">
                            <div className="col-md-5">
                              <p className="fontsizeclass">
                                Assets / Expenses required
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-4">
                              <p className="fontsizeclass ">
                                {" "}
                                {/* ₹7 Lac / ₹3 Lac{" "} */}
                                {formatLocPrice(
                                  netLiquidData?.total_liquid ?? 0
                                )}{" "}
                                /{" "}
                                {formatLocPrice(
                                  netLiquidData?.monthly_expense ?? 0
                                )}
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-3">
                              {netLiquidData.liquidity_ratio === undefined ? (
                                <p className="fontsizeclass ">
                                  {netLiquidData.liquidity_ratio}
                                </p>
                              ) : (
                                <p className="fontsizeclass ">
                                  {netLiquidData.liquidity_ratio} mth
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="scoreCardLabel">
                            Your ideal liquid fund should be equal to{" "}
                            {netLiquidData.ideal_liquid_months} months of
                            expenses.
                          </div>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: netLiquidData.liquidity_data
                                ? netLiquidData.liquidity_data
                                : "",
                            }}
                          ></p>

                          <p />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="gridCalBox">
                          <h4 className="rTitle">Solvency ratio</h4>
                          <div className="scorebard-box">
                            <img src="https://static.fintoo.in/static/assets/img/scorecard.svg" />
                            <span className="">
                              {solvencyData.solvency_mark}/20
                            </span>
                          </div>
                          <div className="colorBgBand sizefont">
                            <div className="col-md-4">
                              <p className="fontsizeclass">
                                NetWorth / Total assets
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-4">
                              <p className="fontsizeclass ">
                                {/* {" "} */}
                                {/* ₹5.16 Cr / ₹6.01 Cr{" "} */}
                                {formatLocPrice(
                                  netLiquidData?.networth_ratio ?? 0
                                )}{" "}
                                /{" "}
                                {formatLocPrice(
                                  netLiquidData?.total_asset ?? 0
                                )}
                              </p>
                            </div>
                            <div>=</div>
                            <div className="col-md-4">
                              {solvencyData.solvency_ratio < 0 ? (
                                <p className="fontsizeclass ">
                                  ({makePositive(solvencyData.solvency_ratio)})%
                                </p>
                              ) : (
                                <p className="fontsizeclass ">
                                  {solvencyData.solvency_ratio}%
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="scoreCardLabel">
                            Your Ideal Solvency Ratio should not be less than
                            50%
                          </div>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: solvencyData.solvency_data
                                ? solvencyData.solvency_data
                                : "",
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      className="scorecard_title rectangle"
                      style={{ textAlign: "center !important" }}
                    >
                      <b>Overall Score</b>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-md-7">
                      {}
                      <h4
                        style={{ fontSize: 17, fontWeight: "700" }}
                        className="mt-5"
                      >
                        Your Scorecard is {scoreData.total_score} out of 100
                        which is{" "}
                        <b
                          // className="score_name_color "
                          style={{ color: scoreNameColor }}
                        >
                          {scoreData.total_score_name}
                        </b>
                        .
                      </h4>
                      <p
                        style={{ fontSize: 14 }}
                        dangerouslySetInnerHTML={{
                          __html: scoreData.total_score_desc
                            ? scoreData.total_score_desc
                            : "",
                        }}
                      ></p>
                    </div>
                    <div className="col-md-5">
                      <div className="row align-items-center">
                        <Scorecard
                          needle_pos={
                            scoreData.gauge_needle ? scoreData.gauge_needle : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 50, width: 100, clear: "both" }}>
                    &nbsp;
                  </div>
                  <div className="container" style={{ paddingTop: 25 }}>
                    <div className="recommen_sec_div">
                      <div className="rec_head_div">
                        <i />
                        <span>Fintoo Recommends</span>
                      </div>
                      <div
                        className="inner_text_div Recommandedtext"
                        style={{ fontWeight: "bold" }}
                      >
                        <ul>
                          <p>Saving Ratio</p>
                          <li>
                            <p className="rContent " />
                            {/* <p>
                                Your current saving ratio is 49% of your total
                                income. Your ideal saving ratio is 40%. As you
                                are saving more than the basic requirement, you
                                can either continue to save more or invest the
                                surplus for your future goals.
                              </p> */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: scoreData.fintoo_saving_recomm
                                  ? scoreData.fintoo_saving_recomm
                                  : "",
                              }}
                            ></p>
                            <p />
                          </li>
                        </ul>
                        <ul>
                          <p>Expense Ratio</p>
                          <li>
                            <p className="rContent " />
                            {/* <p>
                                Your expense ratio is 51% of your total income.
                                The ideal expense ratio is 60%. As your expenses
                                are lesser than the base limit, we recommend you
                                to maintain your lower expense ratio in the
                                future too. If you wish, you can invest the
                                surplus amount for your future goals.
                              </p> */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: scoreData.fintoo_expense_recomm
                                  ? scoreData.fintoo_expense_recomm
                                  : "",
                              }}
                            ></p>
                            <p />
                          </li>
                        </ul>
                        <ul>
                          <p>Net Worth Ratio</p>
                          <li>
                            <p className="rContent " />
                            {/* <p>
                                As your current net worth is more than the base
                                limit (baseline, ideal requirement) we recommend
                                you to maintain your current net worth in the
                                future too. As your net worth score is
                                excellent, it means that you are building your
                                assets through disciplined savings and smart
                                investments.
                              </p> */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: scoreData.fintoo_networth_recomm
                                  ? scoreData.fintoo_networth_recomm
                                  : "",
                              }}
                            ></p>
                            <p />
                          </li>
                        </ul>
                        <ul>
                          <p>Liquidity Ratio</p>
                          <li>
                            <p className="rContent " />
                            {/* <p>
                                Therefore, we recommend that you must build your
                                liquid assets to accommodate emergency
                                expenses.If you are two people earning in your
                                family, you must maintain an emergency corpus
                                worth at least 4 months of your expenses.
                              </p> */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: scoreData.fintoo_liquid_recomm
                                  ? scoreData.fintoo_liquid_recomm
                                  : "",
                              }}
                            ></p>
                            <p />
                          </li>
                        </ul>
                        <ul>
                          <p>Solvency Ratio</p>
                          <li>
                            <p className="rContent " />
                            {/* <p>
                                Way to go! The amount of your liabilities is 14%
                                which is fairly lower than the amount of your
                                assets. We are happy that you are maintaining a
                                healthy solvency ratio and we recommend you keep
                                maintaining it to achieve a financially stable
                                future. However, we would also recommend you to
                                go a step further and reduce your liabilities
                                even more to achieve an excellent solvency
                                ratio.
                              </p> */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: scoreData.fintoo_solvency_recomm
                                  ? scoreData.fintoo_solvency_recomm
                                  : "",
                              }}
                            ></p>
                            <p />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-2">
                  <div className=" text-center">
                    <div>
                      <div className="btn-container fixedBtn">
                        <div className="d-flex justify-content-center">
                          <div
                            className="previous-btn form-arrow d-flex align-items-center"
                            onClick={() => setTab("tab3")}
                          >
                            <FaArrowLeft />
                            <span className="hover-text">&nbsp;Previous</span>
                          </div>
                          {}
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              "/report/assets-liabilities"
                            }
                          >
                            <div className="next-btn form-arrow d-flex align-items-center">
                              <span
                                className="hover-text"
                                style={{ maxWidth: 100 }}
                              >
                                Next&nbsp;
                              </span>
                              <FaArrowRight />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DatagatherReportLayout>
  );
};
export default YourProfile;
