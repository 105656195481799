import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Link, useSearchParams } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import FormRangeSlider from "../CommonDashboardComponents/FormRangeSlider";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import FormSwitch from "../CommonDashboardComponents/formSwitch";
import SimpleReactValidator from "simple-react-validator";
import {
  DMF_ADDFDBOND_API_URL,
  DMF_GETBANKLIST_API_URL,
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_GET_ASSETS_API,
  ADVISORY_UPDATE_ASSETS_API,
  ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT,
  IS_DIRECT,
  CHECK_SESSION,
  exchange_rate,
  ADVISORY_GET_US_EQUITY_API_URL,
} from "../../../../constants";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import {
  fetchEncryptData,
  getUserId,
  apiCall,
  getFpUserDetailsId,
  getParentUserId,
  getItemLocal,
  fv,
  fetchData,
} from "../../../../common_utilities";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { MdHomeMax } from "react-icons/md";
import Form from "react-bootstrap/Form";
import Switch from "react-switch";
const numericRegex = new RegExp(/^\d*\.?\d*$/);

const initialState = {
  usequityname: "",
  assetcurrency: true,
  noofShares: "",
  purchaseDate: "",
  avgbuyPrice: 0,
  totalinvestedValue: 0,
  investedValue: "",
  currentPrice: "",
  currentValue: "",
  sipenddate: "",
  sipamount: "",
};

const defaultAssetDetails = {
  Created_By: 0,
  Updated_By: 0,
  asset_amount: "",
  asset_abreturn: "0",
  annual_growth_rate: "10",
  asset_broker_id: 0,
  asset_category_id: 29,
  asset_citytype: "0",
  asset_current_unit_price: "",
  asset_currency: false,
  asset_ecas_type: "manual",
  asset_epf_ismanual: "1",
  asset_folio_number: null,
  asset_footnote: null,
  asset_frequency: "1",
  asset_goal_link_id: 0,
  asset_goalname: null,
  asset_gold_karat: 0,
  asset_isActive: "1",
  asset_ismortgage: "0",
  asset_isperpetual: "3",
  asset_isallocation: false,
  asset_iselss: "1",
  asset_islinkable: true,
  asset_isrecurring: false,
  asset_isrented: "1",
  asset_maturity_amt: 0,
  asset_maturity_date: null,
  asset_member_id: 0,
  asset_mf_end_date: null,
  asset_name: "US Equity",
  asset_pan: null,
  asset_payout_type: "1",
  asset_pin_code: "",
  asset_purchase_amount: "",
  asset_purchase_date: null,
  asset_rental_amount: "",
  asset_rental_income: null,
  asset_ror: "0",
  asset_sub_category_id: 123,
  asset_unique_code: "",
  asset_units: "",
  categorydetail: "Equity",
  created_datetime: moment().format("DD/MM/YYYY"),
  employee_contribution: "",
  employer_contribution: "",
  installment_ispaid: 1,
  membername1: "",
  stock_mf: null,
  stock_name: null,
  subcategorydetail: "",
  totalinvestedvalue: "",
  totalpurchasevalue: "",
  totalmaturtiyamount: "",
  updated_datetime: moment().format("DD/MM/YYYY"),
  user_id: 0,
  scheme_equityshare: {},
  linked_goals_id: [],
};

const NewUsEquityFormView = () => {
  const [, forceUpdate] = useState(1);
  const [formData, setFormData] = useState(initialState);
  const [assetsDetails, setAssetsDetails] = useState(defaultAssetDetails);
  const [addForm, setAddForm] = useState(true);
  const [updateForm, setUpdateForm] = useState(false);
  const [assetEditId, setAssetEditId] = useState("");

  const [activeIndex, setActiveIndex] = useState(1);
  const [sliderValue, setSliderValue] = useState("Yearly");
  const [maturityCalculation, setMaturityCalculation] = useState("");

  const [activeIndex2, setActiveIndex2] = useState(4);
  const [isRecurring, setIsRecurring] = useState(false);

  const [interestRate, setInterestRate] = useState(0);
  const [rateOfReturn, setRateOfReturn] = useState(8.1);
  const [growthRate, setGrowthRate] = useState(10);
  const [yearsOfService, setYearsOfService] = useState("5");
  const [allBank, setAllBank] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [session, setSession] = useState([]);
  const [USEquity, setUSEquity] = useState([]);

  // ---------------------------------------------------- EPF states ----------------------------------------------------
  const [showEPFForm, setShowEPFForm] = useState(false);
  const [showUANModal, setShowUANModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [isManual, setIsManual] = useState(false);

  // --------------------------------------------------------------------------------------------------------------------

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    checksession();
    // console.log(activeIndex,"kkkk")
  }, []);

  const checksession = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);

      if (session_data.error_code == "100") {
        setSession(session_data);
        getUSEquityData();
      } else {
        loginRedirectGuest();
      }

      const urlParams = new URLSearchParams(window.location.search);
      let asset_id = urlParams.get("id");
      if (asset_id) {
        setUpdateForm(true);
        setAddForm(false);
        setAssetEditId(asset_id);
        editUSData(asset_id);
      }
    } catch (error) {
      console.log(error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something Went Wrong1");
    }
  };

  const getUSEquityData = async () => {
    try {
      const payload = {
        url: ADVISORY_GET_US_EQUITY_API_URL,
        data: "",
        method: "get",
      };
      let resp = await fetchData(payload);
      if (resp["error_code"] == "100") {
        setUSEquity(resp.data);
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const usEquityData = USEquity.map((index, value) => {
    return {
      label: index.name,
      value: index.price,
    };
  });

  const formatDatefun = (date) => {
    // return moment(date).format("YYYY/MM/DD");
    console.log("uuuu: ", moment(date).toDate());

    return moment(date).toDate();
  };

  function editMaturityAmount({
    asset_ror,
    asset_purchase_date,
    asset_purchase_amount,
    asset_maturity_date,
  }) {
    // Convert dates to Date objects
    const purchaseDate = new Date(asset_purchase_date);
    const maturityDate = new Date(asset_maturity_date);

    const years =
      (maturityDate - purchaseDate) / (1000 * 60 * 60 * 24 * 365.25);

    const rate = asset_ror / 100;

    const purchaseAmount = parseFloat(asset_purchase_amount);

    const maturityAmount = purchaseAmount * Math.pow(1 + rate, years);

    return maturityAmount.toFixed(2);
  }

  const editUSData = async (id) => {
    try {
      let url = ADVISORY_GET_ASSETS_API;
      let payload = {
        id: id,
        user_id: getParentUserId(),
        asset_type: "none",
      };
      let editUSEquityData = await apiCall(url, payload, true, false);

      if (editUSEquityData["error_code"] == "100") {
        var msg = editUSEquityData["data"][0]["subcategorydetail"]
          ? " - " + editUSEquityData["data"][0]["subcategorydetail"]
          : "";
        var editUSData = editUSEquityData["data"][0];
        let temp_form_data = {};
        temp_form_data["asset_sub_category_id"] =
          editUSData["asset_sub_category_id"];
        temp_form_data["subcategorydetail"] = editUSData["subcategorydetail"];
        temp_form_data["usequityname"] = editUSData["asset_name"];
        temp_form_data["purchaseDate"] = editUSData["asset_purchase_date"]
          ? moment(editUSData["asset_purchase_date"], "DD/MM/YYYY").toDate()
          : "";
        temp_form_data["sipenddate"] =
          editUSData["asset_mf_end_date"] == null
            ? ""
            : moment(editUSData["asset_mf_end_date"], "DD/MM/YYYY").toDate();
        temp_form_data["noofShares"] = editUSData["asset_units"];
        temp_form_data["avgbuyPrice"] = editUSData["asset_purchase_amount"];
        temp_form_data["currentPrice"] = editUSData["asset_current_unit_price"];
        temp_form_data["investedValue"] = editUSData["totalinvestedvalue"];
        temp_form_data["sipamount"] = editUSData["asset_amount"];

        if (editUSData["asset_currency"] == "1") {
          temp_form_data["assetcurrency"] = true;
        } else {
          temp_form_data["assetcurrency"] = false;
        }
        if (editUSData["asset_isrecurring"] == true) {
          setIsRecurring(true);
          temp_form_data["currentValue"] = editUSData["totalinvestedvalue"];
          temp_form_data["totalinvestedValue"] = editUSData["asset_purchase_amount"];
        } else {
          setIsRecurring(false);
          temp_form_data["currentValue"] = editUSData["totalinvestedvalue"];
          temp_form_data["avgbuyPrice"] = editUSData["asset_purchase_amount"];
        }
        temp_form_data["categorydetail"] = "Equity";

        setFormData({
          ...formData,
          ...temp_form_data,
        });
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const options = [
    { value: "Fixed Deposit", label: "Fixed Deposit" },
    { value: "Recurring Deposit", label: "Recurring Deposit" },
    { value: "Bonds", label: "Bonds" },
    { value: "Debentures", label: "Debentures" },
    { value: "Gratuity", label: "Gratuity" },
    { value: "EPF", label: "EPF" },
    { value: "Others", label: "Others" },
  ];

  const optionSubCategory = {
    "Fixed Deposit": 75,
    Bonds: 79,
    Debentures: 80,
    Others: 87,
    Gratuity: 82,
    "Recurring Deposit": 120,
    EPF: 117,
  };
  const numericRegex = /^[0-9]*\.?[0-9]*$/;;
  const onInputChange = (e, isNumeric) => {
    const name = e.target.name;
    let value = e.target.value;

    if (isNumeric && !numericRegex.test(value) && value !== "") {
      return;
    }
    if (
      (name === "sipamount" || 
        name === "avgbuyPrice" ||
        name === "noofShares") &&
      value.length > 0 &&
      value[0] === "0"
    ) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const onPurchaseInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
  };

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleUSEquitySelection = (selectedOption) => {
    setFormData({
      ...formData,
      usequityname: selectedOption.label,
      currentPrice: parseFloat(selectedOption.value).toFixed(2),
    });
  };

  const validateForm = () => {
    if (simpleValidator.current.allValid()) {
      if (formData.avgbuyPrice === "0" || formData.avgbuyPrice === "") {
        toastr.error("Avg Buy Price must be greater than 0");
        return;
      }
      addAssets();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate((v) => ++v);
    }
  };

  const updateUSEquity = () => {
    if (simpleValidator.current.allValid()) {
      if (formData.avgbuyPrice === "0" || formData.avgbuyPrice === "") {
        toastr.error("Avg Buy Price must be greater than 0");
        return;
      }
      updateUSEquityData();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate((v) => ++v);
    }
  };

  console.log(formData, "formdata");

  const addAssets = async () => {
    // e.preventDefault();

    try {
      let url = ADVISORY_ADD_ASSETS_API;
      let usequityData = assetsDetails;

      usequityData["user_id"] = getParentUserId();
      let member_id = await getFpUserDetailsId();
      usequityData["asset_member_id"] = member_id;
      console.log("Fp member:", member_id);
      usequityData["subcategorydetail"] = "US Equity";
      usequityData["asset_name"] = formData.usequityname;
      usequityData["asset_purchase_date"] = moment(
        formData.purchaseDate
      ).format("DD/MM/YYYY");
      usequityData["asset_units"] = formData.noofShares;
      usequityData["asset_purchase_amount"] = isRecurring ? formData.totalinvestedValue : formData.avgbuyPrice;
      usequityData["asset_current_unit_price"] = formData.currentPrice;
      usequityData["totalinvestedvalue"] = formData.investedValue;
      usequityData["totalpurchasevalue"] = formData.currentValue;
      usequityData["asset_isrecurring"] = isRecurring;
      usequityData["asset_amount"] = formData.sipamount;
      usequityData["asset_mf_end_date"] = formData.sipenddate
        ? moment(formData.sipenddate).format("DD/MM/YYYY")
        : "";
      usequityData["categorydetail"] = "Equity";
      usequityData["asset_sub_category_id"] = 123;

      if (formData.assetcurrency == true) {
        usequityData["asset_currency"] = 1;
      } else {
        usequityData["asset_currency"] = 0;
      }

      let addassetData = await apiCall(url, usequityData, true, false);

      if (addassetData["error_code"] == "100") {
        navigate(
          process.env.PUBLIC_URL +
          "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=11"
        );

        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `US Equity added Successfully!`,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `US Equity not added, Something went wrong!`,
            type: "error",
          },
        });
      }
    } catch (err) {
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const updateUSEquityData = async () => {
    try {
      let url = ADVISORY_UPDATE_ASSETS_API;
      let usequityData = assetsDetails;

      usequityData["id"] = assetEditId;
      usequityData["user_id"] = getParentUserId();
      let member_id = await getFpUserDetailsId();
      usequityData["asset_member_id"] = member_id;
      console.log("Fp member:", member_id);
      usequityData["subcategorydetail"] = "Unlisted / AIF Equity";

      usequityData["asset_name"] = formData.usequityname;
      usequityData["asset_purchase_date"] = moment(
        formData.purchaseDate
      ).format("DD/MM/YYYY");
      usequityData["asset_units"] = formData.noofShares;
      usequityData["asset_purchase_amount"] = isRecurring ? formData.totalinvestedValue : formData.avgbuyPrice;
      usequityData["asset_current_unit_price"] = formData.currentPrice;
      usequityData["totalinvestedvalue"] = formData.investedValue;
      usequityData["totalpurchasevalue"] = formData.currentValue;
      usequityData["asset_amount"] = formData.sipamount;
      usequityData["asset_mf_end_date"] = formData.sipenddate
        ? moment(formData.sipenddate).format("DD/MM/YYYY")
        : "";
      usequityData["asset_isrecurring"] = isRecurring;
      usequityData["categorydetail"] = "Equity";
      usequityData["asset_sub_category_id"] = 123;

      if (formData.assetcurrency == true) {
        usequityData["asset_currency"] = 1;
      } else {
        usequityData["asset_currency"] = 0;
      }
      let addassetData = await apiCall(url, usequityData, true, false);
      if (addassetData["error_code"] == "100") {
        navigate(
          process.env.PUBLIC_URL +
          "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=11"
        );

        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `US Equity updated Successfully!`,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `US Equity not updated, Something went wrong!`,
            type: "error",
          },
        });
      }
    } catch (err) {
      // setIsLoading(false);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const showSubmit = () => {
    if (formData.typeOfAsset === "EPF") {
      return showEPFForm;
    } else {
      return true;
    }
  };

  const handleCurrenySelection = (selectedOption) => {
    const avgBuyPrice = parseFloat(formData.avgbuyPrice) || 0;
    const currentPrice = parseFloat(formData.currentPrice) || 0;
    if (selectedOption == true) {
      setFormData({
        ...formData,
        assetcurrency: selectedOption,
        avgbuyPrice: avgBuyPrice ? (avgBuyPrice * exchange_rate) : '',
        currentPrice: currentPrice ? (currentPrice * exchange_rate) : '',
      });
    } else {
      setFormData({
        ...formData,
        assetcurrency: selectedOption,
        avgbuyPrice: avgBuyPrice ? (avgBuyPrice / exchange_rate) : '',
        currentPrice: currentPrice ? (currentPrice / exchange_rate) : '',
      });
    }
  };

  useEffect(() => {
    if (isRecurring) {
      const cur_val = formData.noofShares * formData.currentPrice;
      setFormData({ ...formData, investedValue: 0, currentValue: cur_val, avgbuyPrice: 0 });
    }
    else {
      formData.avgbuyPrice = formData.avgbuyPrice != "NaN" ? formData.avgbuyPrice : 0
      const inv_val = formData.noofShares * formData.avgbuyPrice;
      const cur_val = formData.noofShares * formData.currentPrice;
      setFormData({ ...formData, investedValue: inv_val, currentValue: cur_val, sipamount: 0, totalinvestedValue: 0 });
    }
  }, [
    formData.noofShares,
    formData.avgbuyPrice,
    formData.currentPrice,
    isRecurring,
  ]);

  simpleValidator.current.purgeFields();

  // Select Options Styles
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "#ffff",
        color: isFocused ? "#042b62" : isSelected ? "#042b62" : "gray",
        cursor: "pointer",
      };
    },
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      scrollBehavior: "smooth",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#042b62",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  console.log("us equity", USEquity);

  useEffect(() => {
    if (formData.recurring && (formData.avgbuyPrice === "0" || formData.avgbuyPrice === "")) {
      toastr.error("Avg Buy Price must be greater than 0 when Recurring is selected");
    }
  }, [formData.recurring, formData.avgbuyPrice]);

  return (
    <>
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          <div className="d-flex">
            <Link
              to={
                process.env.PUBLIC_URL +
                "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=11"
              }
            >
              {" "}
              <img
                style={{
                  transform: "rotate(180deg)",
                }}
                width={20}
                height={20}
                src={process.env.PUBLIC_URL + "/static/media/icons/chevron.png"}
              />
            </Link>

            {addForm && (
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Add Your US Equity
              </h3>
            )}

            {updateForm && (
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Edit Your US Equity
              </h3>
            )}
          </div>
          <hr style={{ color: "#afafaf" }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              {addForm && (
                <p className="text-center">
                  Enter Your Details To Add Existing Assets
                </p>
              )}

              {updateForm && (
                <p className="text-center">
                  Enter Your Details To Edit Existing Assets
                </p>
              )}

              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div className="">
                    <span className="lbl-newbond">
                      Select Your US Equity Shares Name *
                    </span>
                    <br />
                    <Select
                      className={`${updateForm === true ? "disabled" : ""
                        } fnto-dropdown-react`}
                      classNamePrefix="sortSelect"
                      isSearchable={true}
                      styles={customStyles}
                      options={usEquityData}
                      name="usEquityName"
                      onChange={handleUSEquitySelection}
                      value={usEquityData.filter(
                        (v) => v.label == formData.usequityname
                      )}
                    />
                    {simpleValidator.current.message(
                      "usEquityName",
                      formData.usequityname,
                      "required"
                    )}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div className="row py-md-2">
                    <div className="col-md-6">
                      <div className="d-grid">
                        <Form.Label className=" ">Select Currency:</Form.Label>
                        <div className="d-flex mt-md-3">
                          <div>$</div>
                          <Switch
                            onChange={(v) => {
                              handleCurrenySelection(v);
                            }}
                            checked={formData.assetcurrency}
                            className="react-switch px-2"
                            activeBoxShadow="0 0 2px 3px #042b62"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#042b62"
                            offColor="#042b62"
                          />
                          <div>₹</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 mt-md-0 mt-3">
                      <div className="">
                        <div className="">
                          <span className="lbl-newbond">
                            Is The Equity One Time Or Recurring ? *
                          </span>
                          <br />
                          <div className="bonds-datepicker">
                            <div className="insurance-switch-container">
                              <span>One Time&nbsp;&nbsp;</span>{" "}
                              <FormSwitch
                                switchValue={isRecurring}
                                onSwitchToggle={() =>
                                  setIsRecurring((previous) => !previous)
                                }
                              />{" "}
                              <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Recurring
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12  mt-3 ">
                    <div className="my-md-4">
                      <div className="">
                        {isRecurring == false ? (
                          <>
                            <span className="lbl-newbond">Purchase Date *</span>
                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  formData.purchaseDate === ""
                                    ? ""
                                    : formData.purchaseDate
                                  // : formData.purchaseDate
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="purchaseDate"
                                customClass="datePickerDMF"
                                maxDate={new Date()}
                                onChange={(e) =>
                                  onDateAndSelectInputChange(
                                    "purchaseDate",
                                    formatDatefun(e)
                                  )
                                }
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                            {simpleValidator.current.message(
                              "purchaseDate",
                              formData.purchaseDate,
                              "required"
                            )}
                          </>
                        ) : (
                          <>
                            <span className="lbl-newbond">
                              SIP Start Date *
                            </span>
                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  formData.purchaseDate === ""
                                    ? ""
                                    : formData.purchaseDate
                                  // : formData.purchaseDate
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="purchaseDate"
                                customClass="datePickerDMF"
                                maxDate={new Date()}
                                onChange={(e) =>
                                  onDateAndSelectInputChange(
                                    "purchaseDate",
                                    formatDatefun(e)
                                  )
                                }
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                            {simpleValidator.current.message(
                              "purchaseDate",
                              formData.purchaseDate,
                              "required"
                            )}
                          </>
                        )}
                        <br />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">No. Of Shares *</span>
                        <br />
                        <input
                          placeholder="Enter No. Of Shares"
                          className={` w-100 fntoo-textbox-react inputPlaceholder`}
                          type="text"
                          value={formData.noofShares}
                          maxLength={9}
                          name="noofShares"
                          onChange={(e) => onInputChange(e, true)}
                        />
                        {simpleValidator.current.message(
                          "no. of Shares",
                          formData.noofShares,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  {isRecurring == false ? (
                    <>
                      <div className="col-md-6 col-12  mt-3">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">Avg Buy Price *</span>
                            <br />
                            <input
                              placeholder="Enter Avg Buy Price"
                              className={`w-100 fntoo-textbox-react ${formData.assetcurrency
                                  ? "Rupee-icon"
                                  : "Doller-icon"
                                } inputPlaceholder`}
                              type="text"
                              value={formData.avgbuyPrice}
                              maxLength={9}
                              name="avgbuyPrice"
                              onChange={(e) => onInputChange(e, true)}
                              onBlur={() => setFormData({
                                ...formData,
                                avgbuyPrice: parseFloat(formData.avgbuyPrice).toFixed(2),
                              })}
                            />
                            {simpleValidator.current.message(
                              "Avg buy Price",
                              formData.avgbuyPrice,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-md-6 col-12  mt-3">
                      <div className="my-md-4">
                        <div className="">
                          <span className="lbl-newbond">SIP Amount *</span>
                          <br />
                          <input
                            placeholder="Enter SIP Amount"
                            className={`w-100 fntoo-textbox-react ${formData.assetcurrency
                                ? "Rupee-icon"
                                : "Doller-icon"
                              } inputPlaceholder`}
                            type="text"
                            value={formData.sipamount}
                            maxLength={9}
                            name="sipamount"
                            onChange={(e) => onInputChange(e, true)}
                          />
                          {simpleValidator.current.message(
                            "SIP Amount",
                            formData.sipamount,
                            "required|numeric|min:1,num"
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {isRecurring == false ? (
                    <>
                      {" "}
                      <div className="col-md-6 col-12  mt-3">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">Invested Value</span>
                            <br />
                            <input
                              placeholder="Enter Invested Value"
                              className={`w-100 fntoo-textbox-react ${formData.assetcurrency
                                  ? "Rupee-icon"
                                  : "Doller-icon"
                                } inputPlaceholder disabled`}
                              type="text"
                              maxLength={9}
                              value={formData.investedValue}
                              name="investedValue"
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message(
                              "investedValue",
                              formData.investedValue,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6 col-12  mt-3">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Total Invested Value
                            </span>
                            <br />
                            <input
                              placeholder="Enter Total Invested Value"
                              className={`w-100 fntoo-textbox-react ${formData.assetcurrency
                                  ? "Rupee-icon"
                                  : "Doller-icon"
                                }  inputPlaceholder`}
                              type="text"
                              maxLength={9}
                              value={formData.totalinvestedValue}
                              name="totalinvestedValue"
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message(
                              "totalInvestedValue",
                              formData.totalinvestedValue,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">Current Price *</span>
                        <br />
                        <input
                          placeholder="Enter Current Price"
                          className={`w-100 fntoo-textbox-react ${formData.assetcurrency
                              ? "Rupee-icon"
                              : "Doller-icon"
                            } inputPlaceholder`}
                          type="text"
                          value={formData.currentPrice}
                          name="currentPrice"
                          maxLength={9}
                          onChange={(e) => onInputChange(e, true)}
                        />
                        {simpleValidator.current.message(
                          "currentPrice",
                          formData.currentPrice,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">Current Value *</span>
                        <br />
                        <input
                          placeholder="Enter Current Value"
                          className={`w-100 fntoo-textbox-react ${formData.assetcurrency
                              ? "Rupee-icon"
                              : "Doller-icon"
                            } inputPlaceholder disabled`}
                          type="text"
                          value={formData.currentValue}
                          maxLength={9}
                          name="currentValue"
                          onChange={(e) => onInputChange(e, true)}
                        />
                        {simpleValidator.current.message(
                          "currentValue",
                          formData.currentValue,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  {isRecurring == true ? (
                    <div className="col-md-6 col-12  mt-3 ">
                      <div className="my-md-4">
                        <div className="">
                          <span className="lbl-newbond">SIP End Date *</span>
                          <br />
                          <div className="bonds-datepicker">
                            <FintooDatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={
                                formData.sipenddate === ""
                                  ? ""
                                  : formData.sipenddate
                              }
                              minDate={moment().toDate()}
                              maxDate={""}
                              // minDate={formData?.purchaseDate != "" ? moment(formData?.purchaseDate).add(1, 'years').toDate() : moment().add(1,'days').toDate()}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              name="sipenddate"
                              customClass="datePickerDMF"
                              onChange={(e) =>
                                onDateAndSelectInputChange(
                                  "sipenddate",
                                  formatDatefun(e)
                                )
                              }
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                          {simpleValidator.current.message(
                            "SIP End Date",
                            formData.sipenddate,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {showSubmit() && addForm && (
                  <div className="my-md-4">
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => validateForm()}
                    >
                      Save
                    </button>
                  </div>
                )}

                {showSubmit() && updateForm && (
                  <div>
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => updateUSEquity()}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewUsEquityFormView;
